import React from 'react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BackButton.scss';

const BackButton = ({ onClick }) => (
    <IconButton
        className="core--back-button"
        onClick={onClick}
    >
        <FontAwesomeIcon icon={faArrowLeft} />
    </IconButton>
);

export default BackButton;
