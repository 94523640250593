const ASCENDING = 'ascending';
const DESCENDING = 'descending';

export const ORDER_TYPE = Object.freeze({
    [ASCENDING]: 0,
    [DESCENDING]: 1,
});

export const ORDER_TYPE_NAMES = Object.freeze({
    0: ASCENDING,
    1: DESCENDING,
});
