import { useTranslation } from 'react-i18next';
import { StatusChip } from '../index';
import './BidStatusChip.scss';

/** @param {{ statusName: string; className?: string; }} param0 */
const BidStatusChip = ({ statusName, className }) => {
    const { t } = useTranslation('status');
    const lowerCaseStatus = statusName?.toLowerCase() || '';
    const translatedStatus = t(lowerCaseStatus);
    return (
        <StatusChip
            label={translatedStatus}
            statusName={lowerCaseStatus}
            className={className}
        />
    );
};
export default BidStatusChip;
