import { toastr } from 'react-redux-toastr';
import api from '../api';
import i18n from '../../translations/i18n';

const AUTH_ROUTE = 'api/v1/authentication';
const API_URL = process.env.REACT_APP_BACKEND_API || `https://${window.location.host}`;
const ONE_MINUTE = 1000 * 60;
const FIFTEEN_MINUTES = ONE_MINUTE * 15;
const TOKEN_REFRESH_INTERVAL = Number(process.env.REACT_APP_JWT_REFRESH_RATE) || FIFTEEN_MINUTES;
const LAST_TOKEN_REFRESH_KEY = 'lastTokenRefresh';
let timeout;

class AuthService {
    /**
     * @param {string} username
     * @param {string} password
     */
    async login(username, password) {
        /** @type {import('axios').AxiosResponse<{ success: boolean, token: string }>} */
        const response = await api.post(`${API_URL}/${AUTH_ROUTE}/Login`, { username, password }, { headers: true, withCredentials: true });
        if (!response.data || !response.data.success || !response.data.token) {
            throw new Error();
        }

        this.setAuthToken(response.data.token);
        sessionStorage.removeItem(LAST_TOKEN_REFRESH_KEY);
        clearTimeout(timeout);
        timeout = setTimeout(this.refreshToken.bind(this), 0);
        return response.data;
    }

    /**
     * @param {string} ssoToken
     */
    async ssoLogin(ssoToken) {
        /** @type {import('axios').AxiosResponse<{ success: boolean, token: string }>} */
        const response = await api.post(`${API_URL}/${AUTH_ROUTE}/Sso`, { ssoToken }, { withCredentials: true });

        if (!response.data || !response.data.success || !response.data.token) {
            const error = i18n.t('common:Login.genericError');
            toastr.error('Error', error);
            return { success: false, token: '' };
        }
        this.setAuthToken(response.data.token);
        sessionStorage.removeItem(LAST_TOKEN_REFRESH_KEY);
        clearTimeout(timeout);
        timeout = setTimeout(this.refreshToken.bind(this), 0);
        return response.data;
    }

    /**
     * Every minute a check is made to see if the token
     * needs to be refreshed based on the delay and last refresh date
     */
    async refreshToken() {
        clearTimeout(timeout);
        timeout = setTimeout(this.refreshToken.bind(this), ONE_MINUTE);
        const lastRefresh = sessionStorage.getItem(LAST_TOKEN_REFRESH_KEY);
        const nextTokenRefreshDate = new Date().toISOString();

        if (!lastRefresh) {
            // eslint-disable-next-line no-console
            console.debug('Setting the lastTokenRefresh Date', nextTokenRefreshDate);
            sessionStorage.setItem(LAST_TOKEN_REFRESH_KEY, nextTokenRefreshDate);
            return;
        }

        const lastRefreshDate = new Date(lastRefresh).getTime();
        const now = new Date().getTime();
        const isTimeToRefresh = now - lastRefreshDate >= TOKEN_REFRESH_INTERVAL;

        if (!isTimeToRefresh) {
            return;
        }

        const jwt = sessionStorage.getItem('authToken');

        /** @type {import('axios').AxiosResponse<{ success: boolean, token: string }>} */
        const response = await api.post(`${API_URL}/${AUTH_ROUTE}/Refresh`, { token: jwt });

        if (!response.data || !response.data.success || !response.data.token) {
            // eslint-disable-next-line no-console
            console.error('Failed to refresh token.');
            return;
        }

        // eslint-disable-next-line no-console
        console.debug('Token refreshed successfully.');
        this.setAuthToken(response.data.token);
        sessionStorage.setItem(LAST_TOKEN_REFRESH_KEY, nextTokenRefreshDate);
    }

    startTokenRefreshInterval() {
        sessionStorage.removeItem(LAST_TOKEN_REFRESH_KEY);
        timeout = setTimeout(this.refreshToken.bind(this), ONE_MINUTE);
    }

    /**
     * @param {CommonClient2.JwtLicenses} license
     * @returns {boolean}
     */
    validateLicense = (license) => {
        const jwtUserInfo = sessionStorage.getItem('jwtUserInfo');
        if (jwtUserInfo) {
            /** @type {CommonClient2.IUserInfo} */
            const userInfo = JSON.parse(jwtUserInfo);
            return userInfo.Licenses.includes(license);
        }
        return false;
    }

    /**
     * @param {string} authToken
     */
    setAuthToken(authToken) {
        const tokenSections = authToken.split('.');
        const tokenContent = atob(tokenSections[1]);
        const content = JSON.parse(tokenContent);

        const tokenInfo = {
            IssuedAt: content.iat,
            NotBefore: content.nbf,
            Expiration: content.exp,
        };

        sessionStorage.setItem('authToken', authToken);
        sessionStorage.setItem('jwtUserInfo', content.actort);
        sessionStorage.setItem('jwtLifespan', JSON.stringify(tokenInfo));
    }

    /** @param {string=} redirectUrl */
    logout(redirectUrl) {
        sessionStorage.removeItem('authToken');
        sessionStorage.removeItem('jwtUserInfo');
        sessionStorage.removeItem('jwtLifespan');
        sessionStorage.removeItem('userInitials');
        window.location.href = redirectUrl || '';
    }

    isLoggedIn() {
        const authToken = sessionStorage.getItem('jwtUserInfo'); // TODO: is jwtUserInfo the right thing to verify?
        const authTokenExists = !!authToken;
        // TODO: check expiration here
        return authTokenExists;
    }
}

export default (new AuthService());

export const getAuthHeader = () => {
    const authToken = sessionStorage.getItem('authToken');
    return {
        AppKey: process.env.REACT_APP_APP_KEY,
        Authorization: authToken ? `Bearer ${authToken}` : '',
    };
};

/**
 *  Get currently logged in User's information
 * @param {string[]?=} attributes *optionnal* Array of attributes wanted, default = all properties
 * @returns {*} object with selected params
 */
export const getCurrentUserInfoParams = (attributes = null) => {
    const data = JSON.parse(sessionStorage.getItem('jwtUserInfo') || '');

    if (!data) {
        return {};
    }

    if (attributes == null) {
        return data;
    }

    const info = {};
    attributes.forEach((a) => {
        if (!data[a]) {
            return;
        }
        info[a] = data[a];
    });
    return info;
};
