import {
    add,
    setISODay,
    isAfter,
    isBefore,
    isEqual,
    format,
} from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { enCA, fr } from 'date-fns/locale';

const locales = { enCA, fr };
let timezoneInfo;
let dateTimeFormat;
let locale = enCA;

// Temporary fix.
// TODO Seb - 2021-11-16 find a better way to fix the dates being printed in browser timezone.
/** @param {Date} date */
export const correctBrowserTimezone = (date) => new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
);

export const updateLocale = (updatedLocale) => {
    locale = locales[updatedLocale];
};

const lazySetUserInfo = () => {
    if (timezoneInfo) { return; }
    const jwtUserInfo = sessionStorage.getItem('jwtUserInfo');
    if (!jwtUserInfo) {
        throw new Error('Missing User info in session');
    }
    const { TimezoneInfo, DateTimeFormat } = JSON.parse(jwtUserInfo);
    timezoneInfo = TimezoneInfo;
    dateTimeFormat = DateTimeFormat;

    const selectedLocale = sessionStorage.getItem('selectedLocale');
    if (!locale) {
        return;
    }
    locale = locales[selectedLocale];
};

/**
 * @param {Date} dateTime
 * @param {number} offSet
 * @param {boolean} isUtc
 */
const adjustTimeWithDst = (dateTime, offSet, isUtc) => {
    const hoursOffset = isUtc ? offSet * -1 : offSet;
    return add(dateTime, { hours: hoursOffset });
};

/**
 * @param {number} year
 * @param {number} month
 * @param {number} weekNumber
 * @param {number} dayOfWeek
 * @param {number} time
 * @returns
 */
const getDstDateTime = (year, month, weekNumber, dayOfWeek, time) => {
    const dstDateTime = add(new Date(year, month, 1), { weeks: weekNumber, seconds: time / 1000 });
    return setISODay(dstDateTime, dayOfWeek - 1);
};

/**
 * @param {Date} dateTime
 * @param {number} year
 * @param {boolean} isUtc
 * @returns
 */
const getDstOffset = (dateTime, year, isUtc) => {
    lazySetUserInfo();
    const {
        StartMonth, StartDay, StartDayOfWeek, StartTime,
        EndMonth, EndDay, EndDayOfWeek, EndTime,
    } = timezoneInfo.TimeZoneDetails.Timezone;

    let dstStart = getDstDateTime(year, StartMonth, StartDay, StartDayOfWeek, StartTime);
    let dstEnd = getDstDateTime(year, EndMonth, EndDay, EndDayOfWeek, EndTime);
    let offSet = timezoneInfo.BaseOffsetMinutes / 60;
    if (isUtc) {
        dstStart = zonedTimeToUtc(dstStart, 'UTC');
        dstEnd = zonedTimeToUtc(dstEnd, 'UTC');
    }
    const isAfterOrEqual = isAfter(dateTime, dstStart) || isEqual(dateTime, dstStart);
    const isBeforeOrEqual = isBefore(dateTime, dstEnd) || isEqual(dateTime, dstEnd);
    if (isAfterOrEqual && isBeforeOrEqual) {
        offSet += 1;
    }
    return offSet;
};

export const getDateTimeFormats = () => {
    if (dateTimeFormat) { return dateTimeFormat; }
    lazySetUserInfo();
    return dateTimeFormat;
};

/** @param {Date|string} dateTime */
export const applyUkgUserTimeZoneTransformation = (dateTime) => {
    dateTime = new Date(dateTime);
    lazySetUserInfo();

    if (!timezoneInfo) {
        return dateTime;
    }

    const offSet = getDstOffset(dateTime, dateTime.getFullYear(), false);

    return correctBrowserTimezone(adjustTimeWithDst(dateTime, offSet, false));
};

/** @param {Date|string|null|undefined} dateTime */
export const convertNullableDateTimeToUkgUserDateTime = (dateTime) => {
    if (!dateTime) {
        return null;
    }
    return applyUkgUserTimeZoneTransformation(dateTime);
};

/** @param {Date|string} ukgUserDateTime */
export const removeUkgUserTimeZoneTransformation = (ukgUserDateTime) => {
    ukgUserDateTime = new Date(ukgUserDateTime);
    lazySetUserInfo();
    ukgUserDateTime = zonedTimeToUtc(ukgUserDateTime, 'UTC');
    if (!timezoneInfo) { return ukgUserDateTime; }
    const offSet = getDstOffset(ukgUserDateTime, ukgUserDateTime.getFullYear(), true);
    return adjustTimeWithDst(ukgUserDateTime, offSet, true);
};

/** @param {Date|string} dateTime */
export const getCurrentUkgUserDateTime = (dateTime) => {
    dateTime = new Date(dateTime);
    dateTime = zonedTimeToUtc(dateTime, 'UTC');
    return applyUkgUserTimeZoneTransformation(dateTime);
};

/**
 * @param {Date|string|undefined} dateTime
 * @returns {string} Ex.: `Tue 5/10`
*/
export const getAsShortDate = (dateTime) => {
    if (!dateTime) {
        return '';
    }
    lazySetUserInfo();
    return format(new Date(dateTime), dateTimeFormat.ShortDateFormat, { locale });
};

/** @param {Date|string} dateTime */
export const convertAsShortDate = (dateTime) => {
    const converted = applyUkgUserTimeZoneTransformation(dateTime);
    return getAsShortDate(converted);
};

/** @param {Date|string|undefined} dateTime */
export const getAsShortDateTime = (dateTime) => {
    if (!dateTime) {
        return '';
    }
    lazySetUserInfo();
    return format(new Date(dateTime), `${dateTimeFormat.ShortDateFormat} ${dateTimeFormat.TimeFormat}`, { locale });
};

/** @param {Date|string} dateTime */
export const convertAsShortDateTime = (dateTime) => {
    const converted = applyUkgUserTimeZoneTransformation(dateTime);
    return getAsShortDateTime(converted);
};

/** @param {Date|string|undefined} dateTime */
export const getAsLongDate = (dateTime) => {
    if (!dateTime) {
        return '';
    }

    lazySetUserInfo();

    return format(new Date(dateTime), dateTimeFormat.LongDateFormat, { locale });
};

/** @param {Date|string} dateTime */
export const convertAsLongDate = (dateTime) => {
    const converted = applyUkgUserTimeZoneTransformation(dateTime);
    return getAsLongDate(converted);
};

/**
 * @param {Date|string|undefined} dateTime
 * @returns {string} Ex.: `5/13/2022 12:00 AM`
*/
export const getAsLongDateTime = (dateTime) => {
    if (!dateTime) {
        return '';
    }
    lazySetUserInfo();

    return format(new Date(dateTime), `${dateTimeFormat.LongDateFormat} ${dateTimeFormat.TimeFormat}`, { locale });
};

/** @param {Date|string} dateTime */
export const convertAsLongDateTime = (dateTime) => {
    const converted = applyUkgUserTimeZoneTransformation(dateTime);
    return getAsLongDateTime(converted);
};

/**
 * @param {Date|string|undefined} dateTime
 * @returns {string} Ex.: `5/13/2022 12:00 AM`
*/
export const getAsTime = (dateTime) => {
    if (!dateTime) {
        return '';
    }
    lazySetUserInfo();

    return format(new Date(dateTime), dateTimeFormat.TimeFormat, { locale });
};

/**
 * @param {Date|string} date
 * @param {string} dateFormat
 */
export const formatAs = (date, dateFormat) => format(new Date(date), dateFormat, { locale });

const extractDateWithoutTimeZoneFromString = (date) => {
    const timezoneSign = date.charAt(date.length - 6);
    const hourTimeSeparator = date.charAt(date.length - 3);
    if ((timezoneSign === '+' && hourTimeSeparator === ':') || (timezoneSign === '-' && hourTimeSeparator === ':')) {
        return date.substring(0, date.length - 6);
    }
    return date;
};

export const removeBrowserTimezoneConversion = (date) => {
    if (typeof date === 'string' || date instanceof String) {
        date = extractDateWithoutTimeZoneFromString(date);
    }
    date = new Date(date);
    const utc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utc;
};

/**
 * @param {string} dateTimeString Complete date time string with time zone where only date part is relevant
 * @returns {Date} Exactly same date but in the user's browser time zone
 */
export const getDateAsDateOnly = (dateTimeString) => {
    const dateTimeStringWithoutTimeZone = extractDateWithoutTimeZoneFromString(dateTimeString);
    const dateTimeInBrowserTimeZone = new Date(dateTimeStringWithoutTimeZone);
    const dateOnlyInBrowserTimeZone = new Date(
        dateTimeInBrowserTimeZone.getFullYear(),
        dateTimeInBrowserTimeZone.getMonth(),
        dateTimeInBrowserTimeZone.getDate(),
    );
    return dateOnlyInBrowserTimeZone;
};

/**
 *  @template T
 *  Takes in parameter names and returns a function that will take an object and
 *  convert those DateTime params UKG User time zone
 * @param  {string[]} propNames Properties for convert on the model
 * @returns {(object: T) => T}  DateTime converted to User Time
 */
export const applyUkgTimeZoneTransformationToProps = (propNames) => (model) => {
    const clone = { ...model };
    propNames.forEach((propName) => {
        if (!clone[propName]) {
            return;
        }
        clone[propName] = applyUkgUserTimeZoneTransformation(clone[propName]);
    });
    return clone;
};
/**
 * @template T
 *  Takes in parameter names and returns a function that will take an object and
 *  convert those DateTime params into UTC time zone
 * @param  {string[]} propNames
 * @returns {(object: T) => T}(object) => object.propNames DateTime converted to UTC
 */
export const removeUkgTimeZoneTransformationToProps = (propNames) => (model) => {
    const clone = { ...model };
    propNames.forEach((propName) => {
        if (!clone[propName]) {
            return;
        }
        clone[propName] = removeUkgUserTimeZoneTransformation(clone[propName]);
    });
    return clone;
};
/**
 * @template T
 *  Takes in parameter names and returns a function that will take an object and
 *  convert those DateTime params into UTC time zone
 * @param  {string[]} propNames
 * @returns {(object: T) => T}(object) => object.propNames DateTime converted to UTC
 */
export const removeBrowserTimezoneConversionToProps = (propNames) => (model) => {
    const clone = { ...model };
    propNames.forEach((propName) => {
        if (!clone[propName]) {
            return;
        }
        clone[propName] = removeBrowserTimezoneConversion(clone[propName]);
    });
    return clone;
};

export const appliedUkgUserOffSetDateTimeNow = () => applyUkgUserTimeZoneTransformation(new Date());

export const getAsLongDateStartAndEnd = (start, end) => `${getAsLongDate(start)} - ${getAsLongDate(end)}`;

export const localDateAsUTC = (date) => {
    if (date == null) {
        return null;
    }
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

/**
 * @param {Date} dateTime
 * @return {{
 *  hour: string; minute: string; second: string; period: string;
 * }} Object with the complete time related props
 */
export const getCompleteTimeObject = (dateTime) => {
    const date = new Date(dateTime);
    return {
        hour: format(date, 'hh', { locale }),
        minute: format(date, 'mm', { locale }),
        second: format(date, 'ss', { locale }),
        period: format(date, 'a', { locale }),
    };
};

/**
 * @param {Date|number} date1
 * @param {Date|number} date2
 */
export const getHarmonizedDateWithDateTime = (date1, date2) => {
    const adjustedDate1 = new Date(date1);
    const convertedDate2 = new Date(date2);
    adjustedDate1.setHours(convertedDate2.getHours());
    adjustedDate1.setMinutes(convertedDate2.getMinutes());
    adjustedDate1.setSeconds(convertedDate2.getSeconds());
    adjustedDate1.setMilliseconds(convertedDate2.getMilliseconds());
    return adjustedDate1;
};

/**
 * @param {Date|number} date
 */
export const convertUtcDateToDateInUserTimezone = (date) => utcToZonedTime(date, 'UTC');

/**
 * @param {Date|number} date
 * @param {string} dateFormat
 */
export const formatUTCDateAsIs = (date, dateFormat) => format(convertUtcDateToDateInUserTimezone(date), dateFormat);
