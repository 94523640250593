const columnWidthMap = {
    STRING: 175,
    DATE: 100,
    DATETIME: 130,
    TIME: 70,
    BOOLEAN: 50,
    NUMBER: 50,
    ENUM: 100,
};

export default columnWidthMap;
