import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3366FF',
        },
        secondary: {
            main: '#D7D7D7',
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#FFFFFFF',
                color: '#000000',
            },
        },
        MuiAccordion: {
            root: {
                minWidth: '75%',
                maxWidth: '75%',
            },
        },
        MuiAccordionSummary: {
            content: {
                alignItems: 'center',
            },
        },
        MuiAvatar: {
            root: {
                marginRight: '10px',
            },
        },
        MuiTextField: {
            root: {
                width: '100%',
            },
        },
        MuiFormControl: {
            root: {
                backgroundColor: 'white',
            },
        },
        MuiButton: {
            contained: {
                minWidth: '100px',
                color: '#FFFFFF',
                backgroundColor: '#3366FF',
                boxShadow: '0px 0px 12px #3366FF',
                borderRadius: '4px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                padding: '6px 16px',
                opacity: '1',
                '&:hover': {
                    backgroundColor: '#2265AB',
                },
                margin: '10px 3px 10px 5px',
                height: '46px',
            },
        },
    },
});

export default theme;
