import { useEffect } from 'react';
import api from '@apis/api';
import axios from 'axios';
import AuthService from '@commonClient/services/auth';
import { useHrSystem } from '@hooks/index';
import authService from '@commonClient/services/auth/auth.service';

const nextCookieRefreshDate = new Date().toISOString();
const LAST_COOKIE_REFRESH_KEY = 'lastCookieRefresh';

function isCookieNeedRefresh() {
    const ONE_MINUTE = 1000 * 60;
    const FIVE_MINUTES = ONE_MINUTE * 5;
    const TOKEN_REFRESH_INTERVAL = FIVE_MINUTES;
    const lastRefresh = sessionStorage.getItem(LAST_COOKIE_REFRESH_KEY);

    if (!lastRefresh) {
        // eslint-disable-next-line no-console
        console.debug('Setting the lastTokenRefresh Date', nextCookieRefreshDate);
        sessionStorage.setItem(LAST_COOKIE_REFRESH_KEY, nextCookieRefreshDate);
        return false;
    }

    const lastRefreshDate = new Date(lastRefresh).getTime();
    const now = new Date().getTime();
    const isTimeToRefresh = now - lastRefreshDate >= TOKEN_REFRESH_INTERVAL;

    if (!isTimeToRefresh) {
        return false;
    }
    return true;
}
/**
 *
 * @returns
 */
const useBlazorAuth = () => {
    const { data: configs } = api.useGetPublicConfigsQuery();
    const { isUkgDimensions } = useHrSystem();

    useEffect(() => {
        const cookieNeedToRefresh = isCookieNeedRefresh();
        const userLoggedIn = authService.isLoggedIn();
        if (isUkgDimensions && (cookieNeedToRefresh || !userLoggedIn) && !!configs?.blazorRootUrl) {
            (async () => {
                try {
                    const { data } = await axios({
                        method: 'get',
                        url: `${configs?.blazorRootUrl}Authentication/KeepSessionAlive`,
                        withCredentials: true,
                    });
                    if (data?.success) {
                        AuthService.setAuthToken(data.token);
                        window.location.reload();
                        sessionStorage.setItem(LAST_COOKIE_REFRESH_KEY, nextCookieRefreshDate);
                    }
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }, [isUkgDimensions, configs?.blazorRootUrl]);
};

export default useBlazorAuth;
