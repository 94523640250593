import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

/**
 * @typedef { import('@material-ui/core').ButtonProps
 *      & { label: string } } buttonsProps
 * @param {{
 *  open: boolean
 *  title: string
 *  description: string
 *  buttons: buttonsProps[]
 * }} param0
 */
const AlertDialog = ({
    open,
    title,
    description,
    buttons = [],
}) => (
    <div className="core--alert-dialog">
        <Dialog open={open}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {buttons.map((button, index) => (
                    <Button
                        key={button.key || `alert-dialog-button-${index}`}
                        variant="outlined"
                        {...button}
                    >
                        {button.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    </div>
);

export default AlertDialog;
