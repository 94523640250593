import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';
import { applyUkgTimeZoneTransformationToProps } from '@commonClient/services/ukgDateTime';

const BASE_PATH = 'api/v1/manager/Utilities';
const appendToApiPath = appendPath(BASE_PATH);

export const TEAM_DEFINITIONS_TAG = 'TeamDefinitions';
export const TEAM_DEFINITION_BY_SAVED_ID_TAG = 'TeamDefinitionsBySavedId';
export const TEAM_DEFINITION_BY_ID_TAG = 'TeamDefinitionsById';
export const TEAM_DEFINITIONS_TAGS = [TEAM_DEFINITIONS_TAG, TEAM_DEFINITION_BY_SAVED_ID_TAG, TEAM_DEFINITION_BY_ID_TAG];
const applyUserTimeZoneConversionMapping = applyUkgTimeZoneTransformationToProps(['startDate', 'endDate']);
/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, VBD.ITeamDefinition[]>}
 */
const getTeamDefinitions = (builder) =>
    builder.query({
        query: () => ({ url: appendToApiPath('TeamDefinitions'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: (td) => td.map(applyUserTimeZoneConversionMapping),
        providesTags: (result) => (result ? result.map(({ id }) => ({ type: TEAM_DEFINITIONS_TAG, id })) : [TEAM_DEFINITIONS_TAG]),
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, VBD.ITeamDefinitionById>}
 */
const getTeamDefinition = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath('TeamDefinitions', id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: applyUserTimeZoneConversionMapping,
        providesTags: (result, error, id) => [{ type: TEAM_DEFINITION_BY_ID_TAG, id }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string|undefined, any, any, VBD.ITeamDefinition[]>}
 */
const getTeamDefinitionsBySavedId = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(`TeamDefinitionBySavedIdFilter?teamDefinitionId=${id ?? 0}`), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: (td) => td.map(applyUserTimeZoneConversionMapping),
        providesTags: (result, error, id) => [{ type: TEAM_DEFINITION_BY_SAVED_ID_TAG, id }],
    });

export const endpointsTeamDefinitions = (builder) => ({
    getTeamDefinitions: getTeamDefinitions(builder),
    getTeamDefinition: getTeamDefinition(builder),
    getTeamDefinitionsBySavedId: getTeamDefinitionsBySavedId(builder),
});
