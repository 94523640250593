const UKGWorkForceDimensions = 'UKGWorkForceDimensions';
const UKGWorkForceCentral = 'UKGWorkForceCentral';
const ADPWorkForceNow = 'ADPWorkForceNow';
const ShiftBoard = 'ShiftBoard';

export default Object.freeze({
    [UKGWorkForceDimensions]: 0,
    [ADPWorkForceNow]: 1,
    [ShiftBoard]: 2,
    [UKGWorkForceCentral]: 3,
});
