import { Radio, RadioGroup } from 'rsuite';

/**
 * @param {import('rsuite').RadioGroupProps & {
 * value: any;
 * onChange?: (e)=>{};
 * radioButtons: {value: any, label: string}[]
 * }} param0
 * @returns
 */
const RadioButtonGroup = ({
    value,
    onChange,
    radioButtons,
    ...props
}) => (
    <RadioGroup
        value={value}
        onChange={onChange}
        {...props}
    >
        {radioButtons.map((radio) => (
            <Radio
                key={radio.value}
                value={radio.value}
            >
                {radio.label}
            </Radio>
        ))}
    </RadioGroup>
);

export default RadioButtonGroup;
