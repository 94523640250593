import api from '@apis/api';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';

/**
 * @param {number} bidProfileId
 * @param {() => void} handleModalClose
 */
const useRefuseEmployees = (bidProfileId, handleModalClose) => {
    const [refuseEmployees] = api.useRefuseEmployeesMutation();
    const { t: tError } = useTranslation('bid', { keyPrefix: 'errors' });

    return async () => {
        const result = await refuseEmployees(`${bidProfileId}`).unwrap().catch(() => null);
        if (!result) {
            toastr.error(tError('errors'), tError('refuseEmployees'));
            return;
        }

        handleModalClose();
    };
};

export default useRefuseEmployees;
