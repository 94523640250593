import { useEffect } from 'react';
import api from '@apis/api';
import useCookies from '@commonClient/hooks/useCookies';
import i18n, { setLocale, getLocale } from '@translations/i18n';

/**
 *
 * @returns
 */
const useAppLanguage = () => {
    const { data: configs = { domainName: '', isMultiLanguage: false } } = api.useGetPublicConfigsQuery();
    const { domainName, isMultiLanguage } = configs;
    const { cookies = {}, setCookie } = useCookies(domainName);
    const { language } = cookies;

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
            return;
        }

        if (isMultiLanguage) {
            i18n.changeLanguage(getLocale().code);
            const lang = i18n.language.includes('en') ? 'en' : 'fr';
            setCookie('language', lang);

            return;
        }

        i18n.changeLanguage('en');
        setCookie('language', 'en');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language, isMultiLanguage]);

    const isEnglish = i18n.language.includes('en');
    const currentLanguage = i18n.language;
    const nextLanguage = isEnglish ? 'fr' : 'en';

    const changeLanguage = (toLanguage) => {
        if (currentLanguage === toLanguage) { return; }
        i18n.changeLanguage(toLanguage);
        setLocale(toLanguage);
        setCookie('language', toLanguage);
    };

    return {
        isMultiLanguage, changeLanguage, currentLanguage, nextLanguage,
    };
};

export default useAppLanguage;
