const JANUARY = 'January';
const FEBRUARY = 'February';
const MARCH = 'March';
const APRIL = 'April';
const MAY = 'May';
const JUNE = 'June';
const JULY = 'July';
const AUGUST = 'August';
const SEPTEMBER = 'September';
const OCTOBER = 'October';
const NOVEMBER = 'November';
const DECEMBER = 'December';

export default Object.freeze({
    [JANUARY]: 0,
    [FEBRUARY]: 1,
    [MARCH]: 2,
    [APRIL]: 3,
    [MAY]: 4,
    [JUNE]: 5,
    [JULY]: 6,
    [AUGUST]: 7,
    [SEPTEMBER]: 8,
    [OCTOBER]: 9,
    [NOVEMBER]: 10,
    [DECEMBER]: 11,
});

export const MonthNames = Object.freeze({
    0: JANUARY,
    1: FEBRUARY,
    2: MARCH,
    3: APRIL,
    4: MAY,
    5: JUNE,
    6: JULY,
    7: AUGUST,
    8: SEPTEMBER,
    9: OCTOBER,
    10: NOVEMBER,
    11: DECEMBER,
});
