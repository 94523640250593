import { useEffect, useState } from 'react';

export const LIMIT_OPTION = 'limit';
export const TOTAL_OPTION = 'total';
export const PAGER_OPTION = 'pager';
export const SKIP_OPTION = 'skip';

export const DEFAULT_PAGINATION_SIZE = 20;
export const DEFAULT_PAGINATION_PAGE = 1;
export const DEFAULT_PAGE_SIZE_OPTIONS = [DEFAULT_PAGINATION_SIZE, 50, 100];
export const DEFAULT_LAYOUT = [TOTAL_OPTION, '-', LIMIT_OPTION, '|', PAGER_OPTION, SKIP_OPTION];

/**
 * @param {number=} defaultPageSize
 * @param {number=} defaultPage
 * @param {number[]=} defaultpageSizeOptions
 * @param {any[]=} defaultlayout
 */
const usePagination = (
    defaultPageSize = DEFAULT_PAGINATION_SIZE,
    defaultPage = DEFAULT_PAGINATION_PAGE,
    defaultpageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    defaultlayout = DEFAULT_LAYOUT,
) => {
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [page, setPage] = useState(defaultPage);
    const [pageSizeOptions, setPageSizeOptions] = useState(defaultpageSizeOptions);
    const [layout, setLayout] = useState(defaultlayout);

    useEffect(() => {
        // reload data
    }, [page, pageSize]);

    return {
        pageSize,
        setPageSize,
        page,
        setPage,
        pageSizeOptions,
        setPageSizeOptions,
        layout,
        setLayout,
    };
};

export default usePagination;
