const extractCookiesFromBrowser = () => {
    const rawCookiesArray = document.cookie.split(';').map((x) => x.trim());
    const arrayOfCookiesToObject = rawCookiesArray.reduce((acc, val) => {
        const [key, value] = val.split('=');
        return {
            ...acc,
            [decodeURIComponent(key)]: decodeURIComponent(value),
        };
    }, {});

    return arrayOfCookiesToObject;
};

const useCookies = (domainName) => {
    const cookies = extractCookiesFromBrowser();

    const setCookie = (key, value) => {
        const restrictedChars = ['=', ';'];
        const doesKeyOrValueHaveRestrictedChars = restrictedChars.some((char) => key.includes(char) || value.includes(char));

        if (doesKeyOrValueHaveRestrictedChars) {
            return;
        }
        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; domain=${domainName}; SameSite=None; Secure; path=/`;
    };

    const removeCookie = (key) => {
        const value = cookies[key];

        if (!value) {
            return;
        }

        document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(value)}; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/`;
    };

    return { cookies, setCookie, removeCookie };
};

export default useCookies;
