import ActionIconButton from '../ActionIconButton';
import './SidebarMenuItem.scss';

const SidebarMenuItem = ({
    icon,
    isActive,
    label,
    onClick,
}) => (
    <ActionIconButton
        className={`sidebar-menu-item menu-item ${isActive ? 'active' : ''}`}
        onClick={onClick}
    >
        <>
            {!!icon && icon}
            <label>{label}</label>
        </>
    </ActionIconButton>
);

export default SidebarMenuItem;
