import ActionIconButton from '../ActionIconButton';
import i18n from '../../translations/i18n';

import './SidebarMenu.scss';

const tFixed = i18n.getFixedT(null, 'menu');
const noop = () => { };

const SidebarMenu = ({
    icon,
    title,
    onIconTitleClick,
    children,
}) => (
    <div className="core--sidebar-menu">
        <div className="sidebar-title-section">
            <ActionIconButton
                onClick={onIconTitleClick || noop}
                title={tFixed('closeSidebar')}
            >
                {!!icon && icon}
            </ActionIconButton>
            <div className="title">
                <label>{tFixed('menu')}</label>
                <label>{title}</label>
            </div>
        </div>
        <div className="sidebar-content-section">
            {children}
        </div>
    </div>
);

export default SidebarMenu;
