import React from 'react';
import './LabeledControl.scss';

/**
 * @param {{
 *  children: JSX.Element|React.ReactElement;
 *  className?: string;
 *  disabled?: boolean;
 *  name: string;
 * }} param0
 */
const LabeledControl = ({
    className,
    children,
    disabled,
    name,
}) => (
    <div className={`core--labeled-control ${disabled ? 'disabled' : ''} ${className || ''}`}>
        <label aria-disabled={disabled}>
            {name}
        </label>
        {children}
    </div>
);

export default LabeledControl;
