import { Controller } from 'react-hook-form';
import ObsoleteDateRangePicker from '../ObsoleteRangePicker';

/** @param {CommonClient2.IObsoleteRangePicker & {name: string; control: any; rules?: any; hasError?: boolean;}} param0 */
const FormObsoleteDateTimeRangePicker = ({
    name,
    control,
    rules,
    hasError,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, onBlur, value, ref,
            },
        }) => (
            <ObsoleteDateRangePicker
                onBlur={onBlur}
                value={value}
                ref={ref}
                // @ts-ignore
                onChange={onChange}
                hasError={hasError}
                {...props}
            />
        )}
    />
);

export default FormObsoleteDateTimeRangePicker;
