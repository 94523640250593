import {
    Accordion, AccordionSummary, Avatar, Typography, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './AccordionSetup.scss';

/**
 * @param {{
 *  stepIndex: string;
 *  summary: string;
 *  headerTitle: string;
 *  children: any;
 *  className?: string;
 * }} param0
 */
const AccordionSetup = ({
    stepIndex,
    summary,
    headerTitle,
    children,
    className,
}) => (
    <Accordion
        className={`core--accordion-setup ${className || ''}`}
        defaultExpanded
    >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className="accordion-setup--header-main">
                <Avatar className="accordion-setup--header-avatar">{stepIndex}</Avatar>
                <Typography variant="h6">{headerTitle}</Typography>
            </div>
            <Typography className="accordion-setup--header-summary">{summary}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {children}
        </AccordionDetails>
    </Accordion>
);
export default AccordionSetup;
