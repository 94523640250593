/* eslint-disable no-console */
import createLiveUpdateConnection, { realTimeActions } from '@services/liveUpdate';

/**
 * @param {string} hubName
 * @param {import("@reduxjs/toolkit/dist/query/endpointDefinitions").QueryCacheLifecycleApi<any, ({ url, method, data }: {
 *     url: string;
 *     method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
 *     data?: any;
 * }) => Promise<{
 *     data: any;
 *     error?: undefined;
 * } | {
 *     error: {
 *      message: any;
 *     status: any;
 *     data: any;
 * };
 * data?: undefined;
 * }>, any, string>} param1
 */
const addRealTimeListener = async (hubName, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
    const connection = createLiveUpdateConnection(hubName);
    await cacheDataLoaded;

    connection.on(realTimeActions.Created, (/** @type {VBD.LiveUpdate.IBidSetupMessage} */ { message }) => {
        console.debug(`/** ## SignalR ## Message received: ${realTimeActions.Created}`, message);
        updateCachedData((collection) => collection.push(message));
    });

    connection.on(realTimeActions.Updated, (/** @type {VBD.LiveUpdate.IBidSetupMessage} */ { message }) => {
        console.debug(`/** ## SignalR ## Message received: ${realTimeActions.Created}`, message);
        updateCachedData((/** @type {*[]} */ collection) => {
            const index = collection.indexOf((c) => c.id === message.id);
            if (index === -1) {
                return;
            }
            collection[index] = message;
        });
    });

    connection.on(realTimeActions.Deleted, (/** @type {VBD.LiveUpdate.IBidSetupMessage} */ { message }) => {
        console.debug(`/** ## SignalR ## Message received: ${realTimeActions.Created}`, message);
        updateCachedData((/** @type {*[]} */ collection) => {
            const index = collection.indexOf((c) => c.id === message.id);
            if (index === -1) {
                return;
            }
            collection.splice(index, 1);
        });
    });

    // TODO:  connection.start(); to test

    await cacheEntryRemoved;

    // TODO:  temp try/catch for until we finilize real-time to prevent crashing the app
    try {
        connection.stop();
    } catch (error) {
        console.error('Failed to stop the live-update', error);
    }
};

export default addRealTimeListener;
