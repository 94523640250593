import { useTranslation } from 'react-i18next';
import { getAsLongDateTime } from '../../services/ukgDateTime';

const isValidDate = (value) => !Number.isNaN(new Date(value).getTime());

/**
 * @param {CommonClient2.DataviewColumnType} dataValueType
 * @param {*} value
 * @param {import('react-i18next').TFunction<"common", "boolean">} t
 */
const convertDataviewValue = (dataValueType, value, t) => {
    switch (dataValueType?.toLocaleLowerCase()) {
        case 'boolean':
            return value?.toString().length ? t(value) : value;
        case 'date':
            if (!value) {
                return '';
            }
            // return isValidDate(value) ? getAsLongDate(value) : value;
            // The return is modify to return the exact value of the dataview
            return value;
        case 'datetime':
            if (!value) {
                return '';
            }
            return isValidDate(value) ? getAsLongDateTime(value) : value;
        case 'number':
            return Number.isNaN(Number(value)) ? '' : Number(value);
        default:
            return value || '';
    }
};

const DataviewValue = ({ dataValueType, value }) => {
    const { t } = useTranslation('common', { keyPrefix: 'boolean' });
    return <span className="core--dataview-value">{convertDataviewValue(dataValueType, value, t)}</span>;
};

export default DataviewValue;
