import { Button, ButtonGroup } from '@material-ui/core';
import {
    ArrowBack,
    ArrowForward,
    ArrowDownward,
    ArrowUpward,
} from '@material-ui/icons';
import './BidirectionalArrows.scss';

/**
 * FirstButton represent the top/left button
 * SecondButton represent the bottom/right button
 * @param {{
 *  direction: ('leftRight'|'upDown')
 *  buttonGroupProps?: (import('@material-ui/core').ButtonGroupProps|undefined);
 *  firstButtonProps?: (import('@material-ui/core').ButtonProps|undefined);
 *  secondButtonProps?: (import('@material-ui/core').ButtonProps|undefined);
 * }} param0
 */
const BidirectionalArrows = ({
    direction = 'leftRight',
    buttonGroupProps,
    firstButtonProps,
    secondButtonProps,
}) => {
    const buttonClassName = 'bidirectional-arrows--button';
    const iconClassName = 'bidirectional-arrows--arrow-icon';

    let firstIcon = <ArrowBack className={iconClassName} />;
    let secondIcon = <ArrowForward className={iconClassName} />;

    if (direction === 'upDown') {
        firstIcon = <ArrowUpward className={iconClassName} />;
        secondIcon = <ArrowDownward className={iconClassName} />;
    }

    return (
        <div className="core--bidirectional-arrows">
            <ButtonGroup
                className="bidirectional-arrows--button-group"
                {...buttonGroupProps}
            >
                <Button
                    className={buttonClassName}
                    {...firstButtonProps}
                >
                    {firstIcon}
                </Button>
                <Button
                    className={buttonClassName}
                    {...secondButtonProps}
                >
                    {secondIcon}
                </Button>
            </ButtonGroup>
        </div>
    );
};

export default BidirectionalArrows;
