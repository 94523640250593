const WEEK = 'week';
const WEEK_ROLLING = 'weekRolling';
const WEEKDAYS = 'weekdays';
const SCHEDULED_DAYS = 'scheduledDays';

export const WeeklyRuleType = Object.freeze({
    [WEEK]: 1,
    [WEEK_ROLLING]: 3,
    [WEEKDAYS]: 2,
    [SCHEDULED_DAYS]: 0,
});

export const WeeklyRuleTypeNames = Object.freeze({
    1: WEEK,
    3: WEEK_ROLLING,
    2: WEEKDAYS,
    0: SCHEDULED_DAYS,
});
