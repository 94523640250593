import { Checkbox, CheckboxGroup, Dropdown } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns } from '@fortawesome/pro-light-svg-icons';
import './ColumnFilter.scss';

const { Item } = Dropdown;

/**
 * @param {{
 *  options: (import('rsuite').CheckboxProps & { label: string; })[];
 *  onOptionSelected: *;
 *  checkboxGroupProps?: (import('rsuite').CheckboxGroupProps|undefined);
 *  dropdownProps?: (import('rsuite').DropdownProps|undefined);
 * }} param0
 */
const ColumnFilter = ({
    options = [],
    onOptionSelected,
    checkboxGroupProps,
    dropdownProps,
}) => (
    <div className="core--column-filter">
        <Dropdown
            placement="bottomEnd"
            icon={<FontAwesomeIcon icon={faColumns} />}
            {...dropdownProps}
        >
            <CheckboxGroup
                // @ts-ignore
                value={options?.filter((option) => option.checked).map((option) => option.value) || []}
                onChange={onOptionSelected}
                {...checkboxGroupProps}
            >
                {
                    options.map((option) => (
                        <Item key={option.value}>
                            <Checkbox {...option}>{option.label}</Checkbox>
                        </Item>
                    ))
                }
            </CheckboxGroup>
        </Dropdown>
    </div>
);

export default ColumnFilter;
