import { Controller } from 'react-hook-form';
import { DataviewTable } from '..';
/**
 * @param {CommonClient2.DataviewTableProps & import('rsuite-table').TableProps & {
 *  name: string;
 *  control: any;
 *  rules?: any;
 *  onChange?: any;
 *  showAlertColumn?: boolean;
 * }} param0
 * @returns
 */
const FormDataviewTable = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, onBlur, value,
            },
        }) => (
            <DataviewTable
                {...props}
                // @ts-ignore
                onChange={(event) => {
                    onChange(event);
                    onBlur();

                    if (valueChanged) {
                        valueChanged(event);
                    }
                }}
                selectedItems={value}
            />
        )}
    />
);

export default FormDataviewTable;
