import { useLayoutEffect, useState } from 'react';

/**
 * @param {import("react").MutableRefObject<any>} parentRef
 * @param {boolean?} fillAvailableHeight
 */
const useAvailableHeight = (parentRef, fillAvailableHeight = true) => {
    const [autoHeightValue, setAutoHeightValue] = useState(undefined);

    useLayoutEffect(() => {
        // @ts-ignore
        const { clientHeight } = parentRef.current;
        if (!fillAvailableHeight || clientHeight < 100) {
            return;
        }
        setAutoHeightValue(clientHeight);
    }, [fillAvailableHeight, parentRef]);

    return autoHeightValue;
};

export default useAvailableHeight;
