import { Tab } from '@material-ui/core';
import './tab.scss';

/** @param {import('@material-ui/core').TabProps} param0 */
const CoreTab = ({ className, ...props }) => (
    <Tab
        className={`${className || ''} core--tab`}
        {...props}
    />
);

export default CoreTab;
