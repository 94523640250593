import React from 'react'; // NEEDED FOR JEST
import { Typography, Toolbar } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import UserProfileMenu from '../UserProfileMenu';
import './AppBarContent.scss';

const defaultJwtUserInfo = JSON.stringify({
    FirstName: '',
    LastName: '',
    FullName: '',
});

/**
 * @param {{
 *  name: string,
 *  pageName?: string,
 *  onNameClick?: () => void,
 *  onSidebarIconClick?: () => void,
 *  appLanguage?: any;
 *  configs?: CommonClient2.IPublicConfig
 * }} param0
 */
const AppBarContent = ({
    name,
    pageName = '',
    onNameClick,
    onSidebarIconClick,
    appLanguage = {},
    configs,
}) => {
    /** @type {CommonClient2.IUserInfo} */
    const jwtUserInfo = JSON.parse(sessionStorage.getItem('jwtUserInfo') || defaultJwtUserInfo);
    const { FirstName, LastName, FullName } = jwtUserInfo;
    const initials = `${FirstName?.charAt(0) ?? ''}${LastName?.charAt(0) ?? ''}`;
    const pointerClass = typeof (onNameClick) === 'function' ? 'pointing' : '';

    const { changeLanguage, currentLanguage } = appLanguage;

    return (
        <Toolbar className="core--app-bar-content">
            {!!onSidebarIconClick && (
                <div
                    className="menu-icon-wrapper"
                    onClick={onSidebarIconClick}
                >
                    <MenuIcon
                        className="menu-icon"
                        fontSize="medium"
                    />
                </div>
            )}
            <div
                className={`core--app-bar-left-content ${pointerClass}`}
                onClick={onNameClick}
            >
                <div>
                    {pageName && <span className="headline-h-500">{pageName}</span>}
                    {name && <span className="text-sm-regular">{name}</span>}
                </div>
            </div>
            <div className="core--app-bar-right-content">
                <Typography variant="subtitle2">{process.env.REACT_APP_VERSION}</Typography>
                <UserProfileMenu
                    fullName={FullName}
                    initials={initials}
                    onChangeLanguage={changeLanguage}
                    currentLanguage={currentLanguage}
                    configs={configs}
                />
            </div>
        </Toolbar>
    );
};

export default AppBarContent;
