import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    AppPicker, ErrorDisplay, Login, NotFound, PrivateRoute, RequireAuth, Splash as SplashScreen, SsoLogin,
} from '@commonClient/components';

import api from '@apis/api';
import { useTranslation } from 'react-i18next';
import { GetHrSystemRedirectUrl } from '@commonClient/services/ssoPathBuilder';
import { useHrSystem } from '@hooks/index';
import useBlazorAuth from '@hooks/Authentification/index';
import { JwtLicenses } from './enums';

const AppManager = lazy(() => import('./AppManager/App'));
const AppEmployee = lazy(() => import('./AppEmployee/App'));
const AppAdmin = lazy(() => import('./AppAdmin/App'));
const AppAdp = lazy(() => import('./AppAdmin/Adp'));

const CoreRoutes = () => {
    const { data: configs = {}, error, isLoading } = api.useGetPublicConfigsQuery();
    const { t } = useTranslation('common');
    useBlazorAuth();
    const { isUkgCentral } = useHrSystem();

    if (isLoading) {
        return <SplashScreen />;
    }

    if (error) {
        return <ErrorDisplay message={t('errors.genericError')} />;
    }

    const employeeRedirectUrl = GetHrSystemRedirectUrl(configs, 'employee');
    const managerRedirectUrl = GetHrSystemRedirectUrl(configs, 'manager');
    const adminRedirectUrl = GetHrSystemRedirectUrl(configs, 'admin');

    return (
        <Routes>
            <Route
                path="/login/:target"
                element={(
                    <RequireAuth forceRequireLogin={isUkgCentral}>
                        <Login />
                    </RequireAuth>
                )}
            />
            <Route
                path="/sso/:target"
                element={<SsoLogin />}
            />
            <Route
                path="/"
                element={<AppPicker />}
            />
            <Route
                path="/employee/*"
                element={(
                    <PrivateRoute
                        redirectUrl={employeeRedirectUrl}
                        configs={configs}
                        requiredLicense={JwtLicenses.Employee}
                    >
                        <AppEmployee />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/manager/*"
                element={(
                    <PrivateRoute
                        redirectUrl={managerRedirectUrl}
                        configs={configs}
                        requiredLicense={JwtLicenses.Manager}
                    >
                        <AppManager />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/admin/*"
                element={(
                    <PrivateRoute
                        redirectUrl={adminRedirectUrl}
                        configs={configs}
                        requiredLicense={JwtLicenses.Admin}
                    >
                        <AppAdmin />
                    </PrivateRoute>
                )}
            />
            <Route
                path="/adp"
                element={(
                    <PrivateRoute
                        redirectUrl={adminRedirectUrl}
                        configs={configs}
                    >
                        <AppAdp />
                    </PrivateRoute>
                )}
            />
            <Route
                path="*"
                element={<NotFound />}
            />
        </Routes>
    );
};

export default CoreRoutes;
