import React from 'react';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LightTooltip } from '..';
import './ActionIconButton.scss';

/**
 * @typedef {{
 *  icon?: *;
 *  title?: string;
 *  onClick: *;
 *  disabled?: boolean;
 *  children?: JSX.Element,
 *  className?: string,
 * }} ActionIconButtonProps
 * @param {ActionIconButtonProps} param0
 */
const ActionIconButton = ({
    icon,
    title = '',
    onClick,
    disabled = false,
    children,
    className = '',
}) => (
    <div className={`core--action-icon-button ${className}`}>
        <LightTooltip
            title={title}
            arrow
            placement="bottom"
        >
            <IconButton
                href=""
                className="action-icon-button"
                onClick={onClick}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={icon} />
                {children}
            </IconButton>
        </LightTooltip>
    </div>
);

export default ActionIconButton;
