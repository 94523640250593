import React from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';

/**
 @param {
     import('@material-ui/core').SwitchProps & {
        name: string;
        control: any;
        rules?: any;
        onChange?: any;
        label?: string;
    }
} param0
 */
const FormSwitch = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    label,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onBlur, onChange, value, ref,
            },
        }) => (
            <FormControlLabel
                label={label || ''}
                control={(
                    <Switch
                        {...props}
                        inputRef={ref}
                        onBlur={onBlur}
                        checked={value}
                        onChange={(event, checked) => {
                            onChange(event);
                            if (valueChanged) {
                                valueChanged(event, checked);
                            }
                        }}
                    />
                )}
            />
        )}
    />
);

export default FormSwitch;
