/**
     * @param {string} value
     * @returns {JSX.Element | string}
     */
export const stringWithLineBreakToHtmlTooltip = (value) => {
    const arr = value.split('\n').filter((x) => x !== '');
    let id = 0;

    if (!arr.length) {
        return '';
    }

    return (
        <div>
            {arr.map((x) => <div key={`$${id++}-${x}`}>{x}</div>)}
        </div>
    );
};

export default stringWithLineBreakToHtmlTooltip;
