import employeeBidEditSlice from './employeeBidEdit';

export {
    isSelectedBidsRemovedSelector,
} from './employeeBidEdit';

const { actions } = employeeBidEditSlice;

export const {
    reset,
    setIsSelectedBidsRemoved,
} = actions;

export default employeeBidEditSlice;
