import {
    Select, FormControl, MenuItem, InputLabel,
} from '@material-ui/core';

/**
 * @typedef {{value: string | number; label: string;}} MenuItemProps
 *
 * @typedef {Object} FormSelectProps
 * @property {string} name
 * @property {MenuItemProps[]} options
 * @property {Function} [onChange]
 * @property {boolean} [disabled]
 * @property {import('@material-ui/core').MenuProps} [MenuProps]
 * @property {import('react-hook-form').RegisterOptions} [rules]
 * @property {*} control
 * @property {string} [variant]
 * @property {string} [inputLabelText]
 */

/** @param { import('@material-ui/core').SelectProps & FormSelectProps } param0 */
const SelectCustom = ({
    name,
    disabled: isDisabled = false,
    MenuProps: CustomMenuProps,
    options,
    variant = 'outlined',
    inputLabelText,
    ...props
}) => (
    <FormControl disabled={isDisabled}>
        {!!inputLabelText && (<InputLabel>{inputLabelText}</InputLabel>)}
        <Select
            MenuProps={CustomMenuProps || {
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                getContentAnchorEl: null,
            }}
            inputProps={{ readOnly: isDisabled }}
            variant={variant}
            {...props}
        >
            {
                options?.map((/** @type MenuItemProps */option) => (
                    <MenuItem
                        key={option.toString()}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))
            }
        </Select>
    </FormControl>
);

export default SelectCustom;
