import { Popover, Whisper } from 'rsuite';
import InfoIcon from '@material-ui/icons/Info';
import './AdditionalInfo.scss';

/**
 * @param {{
 *  children:*;
 *  visible?: boolean;
 *  className?: string;
 *  placement?: string;
 * } & Omit<import('rsuite').WhisperProps, 'speaker'>
 * } param0
 */
const AdditionalInfo = ({
    children,
    className,
    visible,
    placement,
    ...other
}) => (
    <div className={`core--additional-info ${typeof (visible) === 'undefined' || visible ? 'visible' : ''} ${className || ''}`}>
        <Whisper
            placement={placement || 'bottomEnd'}
            speaker={(
                <Popover className="core--additional-info--popover">
                    {children}
                </Popover>
            )}
            {...other}
        >
            <InfoIcon />
        </Whisper>
    </div>
);

export default AdditionalInfo;
