/* eslint-disable no-console */
import api from '../api';

/**
 * @param {{ url: string, method: ('GET'|'POST'|'PUT'|'PATCH'|'DELETE'), data?:* }} param0
 * @returns
 */
const axiosBaseQuery = async ({ url, method, data }) => {
    try {
        const result = await api({ url, method, data });
        console.debug(`/** QUERY ${method} to url ${url}`, result);
        return { data: result.data, meta: { response: result } };
    } catch (/** @type {*} */ err) {
        console.error(`/** QUERY ${method} to url ${url}`, {
            err: err?.message || err,
            url,
            method,
            data,
        });
        return {
            error: {
                message: err.response?.message || err.message || 'Unknown',
                status: err.response?.status,
                data: err.response?.data,
            },
        };
    }
};

export default axiosBaseQuery;
