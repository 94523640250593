import { useRedirectUrl } from '@commonClient/hooks';
import authService from '@commonClient/services/auth';
import api from '@apis/api';

/** @param {'admin'|'manager'|'employee'} role  */
const useLogout = (role) => {
    const { data: configs } = api.useGetPublicConfigsQuery();
    const redirectUrl = useRedirectUrl(role, configs);

    const logOut = () => {
        authService.logout(redirectUrl);
    };

    return logOut;
};

export default useLogout;
