import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/employee/Utilities';
const appendToApiPath = appendPath(BASE_PATH);

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Dto.IEmployeeSeniorityReportQueryModel, any, any, string>}
 */
const generateCsvforSeniorityReport = (builder) => builder.query({
    query: (body) => ({ url: appendToApiPath('generateSeniorityReportCsv'), method: 'POST', data: body }),
    async onQueryStarted(id, api) { await showSpinner(api); },
});

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Dto.IEmployeeBiddingResultReportQueryModel, any, any, string>}
 */
const generateCsvforBiddingResultReport = (builder) => builder.query({
    query: (body) => ({ url: appendToApiPath('generateBiddingResultReportCsv'), method: 'POST', data: body }),
    async onQueryStarted(id, api) { await showSpinner(api); },
});

const endpointsEmployeeUtilities = (builder) => ({
    generateCsvforSeniorityReport: generateCsvforSeniorityReport(builder),
    generateCsvforBiddingResultReport: generateCsvforBiddingResultReport(builder),
});

export default endpointsEmployeeUtilities;
