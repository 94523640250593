import awardingRuleSetStepsSlice from './awardingRuleSetsSteps';

export {
    default as getStepsCompletion,
    recomputeInformationStatus,
    recomputeSortingRulesStatus,
} from './awardingRuleSetsStepsCompletion';

export {
    informationStepStatusSelector, sortingRulesStepStatusSelector, exceptionRulesStepStatusSelector,
} from './awardingRuleSetsSteps';

const { actions } = awardingRuleSetStepsSlice;

export const {
    createAwardingRuleSetSteps, editAwardingRuleSetSteps, setSortingRulesStep, setInformationStep, setExceptionRulesStep,
} = actions;

export default awardingRuleSetStepsSlice;
