const DAY = 'day';
const WEEK = 'week';

export const BidSelectionDateRangeType = Object.freeze({
    [DAY]: 0,
    [WEEK]: 1,
});

export const BidSelectionDateRangeTypeNames = Object.freeze({
    0: DAY,
    1: WEEK,
});
