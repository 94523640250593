import showSpinner from '@services/addSpinnerOnQueryStarted';
import addRealTimeListener from '@services/addRealTimeListener';
import { hubs } from '@services/liveUpdate';
import appendPath, { toQueryString } from '@services/path';
import { localDateAsUTC } from '@commonClient/services/ukgDateTime';
import { REMOVED_EMPLOYEE_FROM_GROUP_TAG } from '@apis/EmployeeGroups';
import { SCHEDULE_TAG } from '@apis/schedule';
import { dynamicSort } from '@commonClient/services/sort';
import {
    EMPLOYEE_BID_PROFILES_TAG,
    MANAGER_BID_PROFILE_MANAGEMENT_TAG,
    MANAGER_BID_PROFILES_TAG,
    MANAGER_BID_PROFILE_NAME_VALIDATION_TAG,
    MANAGER_ARCHIVED_BID_PROFILES_TAG,
} from './bidProfiles.tags';
import {
    applyUserTimeZoneConversionMapping,
    removeUserTimeZoneConversionMapping,
    removeBrowserTimeZoneConversionMapping,
    applyManagementTimeZoneConversionMapping,
} from './bidProfiles.service';

const BASE_PATH = 'api/v1/manager/bidProfiles';
const ARCHIVED_PATH = 'archived';
const VALIDATE_NAME_PATH = 'nameValidation';
const appendToApiPath = appendPath(BASE_PATH);

const updateMapping = (profile) => ({
    ...removeUserTimeZoneConversionMapping(profile),
    requestPeriodStartDate: localDateAsUTC(profile.requestPeriodStartDate),
    requestPeriodEndDate: localDateAsUTC(profile.requestPeriodEndDate),
});

/** @typedef {{ page: number; pageSize: number; search: string; selectedStatuses: string }} ManagerBidProfilesQueryString */

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, VBD.IManagerBidProfile[]>}
 * >}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener(hubs.BidProfile, api); },
        transformResponse: (bids) => bids
            .sort(dynamicSort('-lastUpdate'))
            .map((bid) => {
                bid = applyUserTimeZoneConversionMapping(bid);
                return removeBrowserTimeZoneConversionMapping(bid);
            }),
        providesTags: [MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<
*  ManagerBidProfilesQueryString|void,
*  any,
*  any,
*  { bids: VBD.IManagerBidProfile[]; totalCount: number }
* >}
*/
const getAllArchived = (builder) =>
    builder.query({
        query: (queryParams) => ({ url: `${appendToApiPath(ARCHIVED_PATH)}${toQueryString(queryParams)}`, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener(hubs.BidProfile, api); },
        transformResponse: (bids, meta) => ({
            bids: bids.map((bid) => {
                bid = applyUserTimeZoneConversionMapping(bid);
                return removeBrowserTimeZoneConversionMapping(bid);
            }),
            totalCount: Number(meta?.response?.headers['x-total-count']),
        }),
        providesTags: [MANAGER_ARCHIVED_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, VBD.IManagerBidProfile>}
 */
const get = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: ((bid) => {
            bid = applyUserTimeZoneConversionMapping(bid);
            return removeBrowserTimeZoneConversionMapping(bid);
        }),
        providesTags: [MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<{ id: number, name: string }, any, any, { id: number, name: string}[]>}
 */
const validateName = (builder) =>
    builder.query({
        query: (queryParams) => ({ url: `${appendToApiPath(VALIDATE_NAME_PATH)}${toQueryString(queryParams)}`, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [MANAGER_BID_PROFILE_NAME_VALIDATION_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IManagerManagementInfo>}
 */
const getManagementInfos = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id, 'managementInfos'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: (applyManagementTimeZoneConversionMapping),
        providesTags: (result, error, id) => [{ type: MANAGER_BID_PROFILE_MANAGEMENT_TAG, id }, MANAGER_BID_PROFILE_MANAGEMENT_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<VBD.IManagerBidProfile, any, any, VBD.IManagerBidProfile>}
 */
const create = (builder) =>
    builder.mutation({
        query: (profile) => ({ url: BASE_PATH, method: 'POST', data: removeUserTimeZoneConversionMapping(profile) }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: applyUserTimeZoneConversionMapping,
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, MANAGER_BID_PROFILE_NAME_VALIDATION_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<VBD.IManagerBidProfile, any, any, VBD.IManagerBidProfile>}
 */
const update = (builder) =>
    builder.mutation({
        query: (profile) => ({ url: appendToApiPath(profile.id), method: 'PUT', data: updateMapping(profile) }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: applyUserTimeZoneConversionMapping,
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, MANAGER_BID_PROFILE_NAME_VALIDATION_TAG],
    });

/**
 * @typedef {{
 *  bidProfileId: number;
 *  bidProcessType: number;
 *  employeePeriodStartDate: Date | undefined;
 *  employeePeriodEndDate: Date | undefined;
 *  employeesIds: string[];
 *  employeeGroupIdentifier: string | null;
 * }} ISaveEmployeesSelection
 */

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<ISaveEmployeesSelection, any, any, void>}
 */
const saveEmployeesSelection = (builder) =>
    builder.mutation({
        query: ({
            bidProfileId,
            bidProcessType,
            employeePeriodStartDate,
            employeePeriodEndDate,
            employeesIds,
            employeeGroupIdentifier,
        }) => ({
            url: appendToApiPath(bidProfileId, 'employeesSelection'),
            method: 'PUT',
            data: {
                employeePeriodStartDate: localDateAsUTC(employeePeriodStartDate),
                employeePeriodEndDate: localDateAsUTC(employeePeriodEndDate),
                employeesIds,
                employeeGroupIdentifier,
                bidProcessType,
            },
        }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: applyUserTimeZoneConversionMapping,
        invalidatesTags: [MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<string|number, any, any, void>}
 */
const deleteBidProfile = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id), method: 'DELETE' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, MANAGER_BID_PROFILE_NAME_VALIDATION_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<string|number, any, any, void>}
 */
const startBid = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'scheduleBid'), method: 'POST' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, MANAGER_BID_PROFILE_MANAGEMENT_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<number|string, any, any, void>}
 */
const publishBid = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'publishBid'), method: 'POST' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, MANAGER_BID_PROFILE_MANAGEMENT_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<number|string, any, any, any>}
 */
const refreshAwards = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'refreshAwards'), method: 'POST' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, SCHEDULE_TAG, EMPLOYEE_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<number|string, any, any, void>}
 */
const cancelBid = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'cancelBid'), method: 'POST' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<number|string, any, any, void>}
 */
const addRound = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'addRound'), method: 'POST' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<string, any, any, void>}
 */
const refuseEmployees = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id, 'AutoRefuseRemovedEmployees'), method: 'PUT' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILES_TAG, REMOVED_EMPLOYEE_FROM_GROUP_TAG],
    });

const endpointsManagerBidProfiles = (builder) => ({
    addRound: addRound(builder),
    cancelBid: cancelBid(builder),
    createManagerBidProfile: create(builder),
    deleteManagerBidProfile: deleteBidProfile(builder),
    getManagementInfos: getManagementInfos(builder),
    validateBidProfileName: validateName(builder),
    getManagerBidProfile: get(builder),
    getManagerBidProfiles: getAll(builder),
    getManagerArchivedBidProfiles: getAllArchived(builder),
    publishBid: publishBid(builder),
    refreshAwards: refreshAwards(builder),
    startBid: startBid(builder),
    updateManagerBidProfile: update(builder),
    saveEmployeesSelection: saveEmployeesSelection(builder),
    refuseEmployees: refuseEmployees(builder),
});

export default endpointsManagerBidProfiles;
