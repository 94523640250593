import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from '@material-ui/core';
import TimePickerColumn from './TimePickerColumn';
import './TimePicker.scss';

const AM = 'AM';
const PM = 'PM';

const Meridiem = Object.freeze({
    [AM]: AM,
    [PM]: PM,
});

const meridiemAdjustHours = 12;

/**
 * @param {{
 *  value?: Date;
 *  onSubmit?: any;
 *  isMeridiem?: boolean;
 *  minuteStep?: number;
 *  disabled?: boolean;
 * }} param0
 */
const TimePicker = ({
    value,
    onSubmit,
    isMeridiem = true,
    minuteStep = 5,
    disabled = false,
}) => {
    const { t } = useTranslation('common');

    const initialTimeValue = isMeridiem ? 'HH:mm A' : 'HH:mm';

    const [anchorEl, setAnchorEl] = useState(null);
    /** @type {[number|null, Function]} */
    const [selectedHours, setSelectedHours] = useState(null);
    /** @type {[number|null, Function]} */
    const [selectedMinutes, setSelectedMinutes] = useState(null);
    /** @type {[string|null, Function]} */
    const [selectedMeridiem, setSelectedMeridiem] = useState(null);
    const [timeValue, setTimeValue] = useState(initialTimeValue);

    const open = !!anchorEl;
    const id = open ? 'time-picker-popover' : undefined;

    const meridiemOptions = [
        { key: 'meridiem-AM', value: Meridiem.AM },
        { key: 'meridiem-PM', value: Meridiem.PM },
    ];

    const maxHour = isMeridiem ? 12 : 23;
    const minHour = isMeridiem ? 1 : 0;
    const hourOptions = [];
    for (let hour = minHour; hour <= maxHour; hour++) {
        hourOptions.push({ key: `hour-${hour}`, value: hour });
    }

    const maxMinutes = 60;
    const minuteOptions = [];
    let minuteIncrement = 0;
    while (minuteIncrement < maxMinutes) {
        minuteOptions.push({ key: `minute-${minuteIncrement}`, value: minuteIncrement });
        minuteIncrement += minuteStep;
    }

    const setDisplayTimeValue = (hours, minutes, meridiem) => {
        const displayHours = String(hours).padStart(2, '0');
        const displayMinutes = String(minutes).padStart(2, '0');
        const displayMeridiem = meridiem ?? '';

        const displayValue = isMeridiem
            ? `${displayHours}:${displayMinutes} ${displayMeridiem}`
            : `${displayHours}:${displayMinutes}`;

        setTimeValue(displayValue);
    };

    const handleClick = (event) => {
        if (disabled) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHoursClick = (option) => {
        setSelectedHours(option.value);
    };

    const handleMinutesClick = (option) => {
        setSelectedMinutes(option.value);
    };

    const handleMeridiemClick = (option) => {
        setSelectedMeridiem(option.value);
    };

    const handleSubmitClick = (event) => {
        const defaultHours = isMeridiem ? meridiemAdjustHours : 0;
        const defaultMinutes = 0;
        const defaultMeridiem = Meridiem.AM;

        /** @type {number|null} */
        let hours = selectedHours;
        /** @type {number|null} */
        let minutes = selectedMinutes;
        /** @type {string|null} */
        let meridiem = selectedMeridiem;

        if (!hours) {
            setSelectedHours(defaultHours);
            hours = defaultHours;
        }
        if (!minutes) {
            setSelectedMinutes(defaultMinutes);
            minutes = defaultMinutes;
        }
        if (isMeridiem && !meridiem) {
            setSelectedMeridiem(defaultMeridiem);
            meridiem = defaultMeridiem;
        }

        const date = new Date();
        const adjustedHours = isMeridiem
            && ((meridiem === Meridiem.PM && hours < meridiemAdjustHours)
                || (meridiem === Meridiem.AM && hours === meridiemAdjustHours))
            ? hours + meridiemAdjustHours
            : hours;

        date.setHours(adjustedHours, minutes, 0);

        setDisplayTimeValue(hours, minutes, meridiem);

        if (onSubmit) {
            onSubmit(date, event);
        }

        handleClose();
    };

    useEffect(() => {
        const isValueDate = value instanceof Date;
        if (value && isValueDate) {
            const valueHours = value.getHours();
            const valueSelectedHours = isMeridiem && valueHours > meridiemAdjustHours
                ? valueHours - meridiemAdjustHours
                : valueHours;
            setSelectedHours(valueSelectedHours);

            const valueSelectedMinutes = value.getMinutes();
            setSelectedMinutes(valueSelectedMinutes);

            let valueSelectedMeridiem = null;
            if (isMeridiem) {
                valueSelectedMeridiem = valueHours >= meridiemAdjustHours ? Meridiem.PM : Meridiem.AM;
                setSelectedMeridiem(valueSelectedMeridiem);
            }

            setDisplayTimeValue(valueSelectedHours, valueSelectedMinutes, valueSelectedMeridiem);
        }
    }, [JSON.stringify(value)]);

    return (
        <div className="core--time-picker">
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="time-picker--popover"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="time-picker--popover-content">
                    <TimePickerColumn
                        title={t('hours')}
                        options={hourOptions}
                        selectedOption={selectedHours}
                        onClick={handleHoursClick}
                    />
                    <TimePickerColumn
                        title={t('minutes')}
                        options={minuteOptions}
                        selectedOption={selectedMinutes}
                        onClick={handleMinutesClick}
                    />
                    {
                        isMeridiem
                        && (
                            <TimePickerColumn
                                title="AM/PM"
                                options={meridiemOptions}
                                selectedOption={selectedMeridiem}
                                onClick={handleMeridiemClick}
                            />
                        )
                    }
                </div>
                <div className="time-picker--popover-toolbar">
                    <div className="toolbar-right">
                        <Button
                            className="toolbar-right--submit-button"
                            onClick={handleSubmitClick}
                        >
                            <span className="text-md-semibold">OK</span>
                        </Button>
                    </div>
                </div>
            </Popover>
            <div
                className={`time-picker--toggle-wrapper${disabled ? ' disabled' : ''}`}
                aria-describedby={id}
                onClick={handleClick}
            >
                <span className={`${timeValue === initialTimeValue ? 'toggle--placeholder ' : ''}text-md-medium`}>
                    {timeValue}
                </span>
            </div>
        </div>
    );
};

export default TimePicker;
