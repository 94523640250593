import React from 'react';
import { Controller } from 'react-hook-form';
import SelectCustom from '../Select';

/** @param { import('@material-ui/core').SelectProps & import('../Select/Select').FormSelectProps } param0 */
const FormSelect = ({
    name,
    onChange: valueChanged,
    rules,
    control,
    multiple,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onBlur, onChange, value = multiple ? [] : undefined, ref,
            },
        }) => (
            // @ts-ignore
            <SelectCustom
                {...props}
                onChange={(event, checked) => {
                    onChange(event);
                    if (valueChanged) {
                        valueChanged(event, checked);
                    }
                }}
                ref={ref}
                onBlur={onBlur}
                value={value}
                multiple={multiple}
            />
        )}
    />
);

export default FormSelect;
