import Chip from '@material-ui/core/Chip';
import './BidRoundChip.scss';

/** @param {import('@material-ui/core').ChipProps} param0 */
const BidRoundChip = ({ className, label, ...props }) => (
    <Chip
        {...props}
        className={`core--bid-round-chip ${className || ''}`}
        label={label}
    />
);

export default BidRoundChip;
