import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Select, OutlinedInput, Box, Chip, MenuItem, Avatar,
    FormControl, InputLabel, Checkbox, ListItemText,
    makeStyles,
} from '@material-ui/core';
import './StatusMultiSelect.scss';
import statusColorMap from './statusColorMap.json';

const useStyles = makeStyles(() => ({
    formControl: {
        margin: 1,
        minWidth: 135,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // marginTop: 61,
            top: 17,
            width: 250,
        },
    },
    MenuListProps: {
        style: {
            paddingTop: 2,
        },
    },
    PopoverClasses: {
        paper: 'fixed-top-position-popover',
    },
};

/**
 * @param {{
 *  onStatusChange: (selectedStatuses: string[]) => void;
 *  allStatuses: string[]; useSimpleDropdown?: boolean;
 *  placeholder?: string;
 * }} param0
 * */
const StatusMultiSelect = ({
    allStatuses, onStatusChange, useSimpleDropdown, placeholder = '',
}) => {
    allStatuses = allStatuses || [];
    const [status, setStatus] = useState(allStatuses);
    const { t } = useTranslation('status');
    const classes = useStyles();

    useEffect(() => {
        setStatus(allStatuses);
        onStatusChange(allStatuses);
    }, [JSON.stringify(allStatuses)]);

    /** @type {(event: import('react').ChangeEvent<{ name?: string | undefined; value: string|[]; }>) => void}} */
    const handleChange = (event) => {
        const { target: { value } } = event;
        const updatedStatus = typeof value === 'string' ? value.split(',') : value;
        setStatus(updatedStatus);
        onStatusChange(updatedStatus);
    };

    return (
        <div className="core--select-multi-status">
            {
                !useSimpleDropdown
                    ? (
                        <div className="complex-dropdown">
                            <Select
                                multiple
                                input={<OutlinedInput label="Chip" />}
                                // @ts-ignore
                                renderValue={(/** @type {string[]} */selected) => (
                                    <Box className="core--chip-container">
                                        {
                                            selected.map((value) => (
                                                <Chip
                                                    key={value}
                                                    avatar={<Avatar style={{ backgroundColor: statusColorMap[value] }}> </Avatar>}
                                                    label={t(value)}
                                                />
                                            ))
                                        }
                                    </Box>
                                )}
                                value={status}
                                // @ts-ignore
                                onChange={handleChange}
                            >
                                {
                                    allStatuses.map((s) => (
                                        <MenuItem
                                            key={s}
                                            value={s}
                                            style={{
                                                fontWeight: status.indexOf(s) === -1 ? 400 : 600,
                                            }}
                                        >
                                            <div className="core--status-menu-item-container">
                                                <Avatar style={{ backgroundColor: statusColorMap[s] }}> </Avatar>
                                                {t(s)}
                                            </div>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    )
                    : (
                        <FormControl
                            className={classes.formControl}
                            variant="outlined"
                        >
                            <InputLabel>{placeholder}</InputLabel>
                            <Select
                                multiple
                                value={status}
                                // @ts-ignore
                                onChange={handleChange}
                                // @ts-ignore
                                renderValue={(/** @type {string[]} */selected) => selected.map((s) => t(s)).join(', ')}
                                MenuProps={MenuProps}
                            >
                                {allStatuses.map((s) => (
                                    <MenuItem
                                        key={s}
                                        value={s}
                                    >
                                        <Checkbox color="primary" checked={status.indexOf(s) > -1} />
                                        <ListItemText primary={t(s)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
            }

        </div>
    );
};

export default StatusMultiSelect;
