import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AuthService from '../../services/auth';

function SsoLogin() {
    /** @type {{ target?: string }} */
    const { target } = useParams(); // Only accessible if route is /login/:target
    const navigate = useNavigate();
    const ssoToken = new URLSearchParams(useLocation().search).get('ssoToken'); // Only accessible if route contains ?ssoToken=

    const executeLogin = async () => {
        if (ssoToken) {
            const success = await AuthService.ssoLogin(ssoToken);
            if (success) {
                navigate(`/${target}`);
            } else {
                window.location.href = process.env.REACT_APP_UKG_URL || '';
            }
        }
    };

    useEffect(() => { executeLogin(); }, []);
    return <></>;
}

export default SsoLogin;
