import showSpinner from '@services/addSpinnerOnQueryStarted';

const BASE_PATH = 'api/v1/admin/Utilities/requestSubtypes';
export const REQUEST_SUBTYPES_TAG = 'RequestSubtypes';
export const REQUEST_SUBTYPES_TAGS = [REQUEST_SUBTYPES_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IRequestSubtype[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [REQUEST_SUBTYPES_TAG],
    });

export const endpointsRequestSubtypes = (builder) => ({
    getRequestSubtypes: getAll(builder),
});
