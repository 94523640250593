import { Tabs } from '@material-ui/core';
import './tabs.scss';

/** @param {import('@material-ui/core').TabsProps} param0 */
const CoreTabs = ({ className, ...props }) => (
    <Tabs
        className={`${className || ''} core--tabs`}
        {...props}
    />
);

export default CoreTabs;
