import {
    useEffect, useState,
} from 'react';
import { keyBy, merge, values } from 'lodash';
import { addSearchIndex, removeSearchIndex } from '../../services/searchIndexer';
import TableHeader from './DataviewTableHeader';
import DataviewTableContent from './DataviewTableContent';
import './DataviewTable.scss';

/** @type {CommonClient2.DataviewTableColumn} */
let baseColumn = {
    key: '', tKey: '', uniqueKey: '', sortable: true, flexGrow: 1, visible: false, dataValueType: 'STRING',
};

/** @type {(dvc: CommonClient2.DataviewColumn & { tKey?: string }) => CommonClient2.DataviewTableColumn } */
const mapDataviewColumnsToTableColumns = (dvc) => ({
    ...baseColumn,
    uniqueKey: dvc.uniqueKey,
    key: dvc.key,
    tKey: dvc.label || dvc.tKey || '',
    visible: dvc.visible || baseColumn.visible,
    dataValueType: dvc.dataValueType,
    flexGrow: dvc.flexGrow || baseColumn.flexGrow,
});

/** @type {(dataviewMappedColumns: string[]) => Object.<string, boolean>} */
const toKeyVisibilityDictionary = (dataviewMappedColumns) =>
    dataviewMappedColumns.reduce((acc, val) => ({ ...acc, [val]: true }), {});

const appendNameColumnVisibility = (nameColumn, dictionary) => ({ ...dictionary, [nameColumn]: true });

/**
 * @param {CommonClient2.DataviewTableProps & import('rsuite-table').TableProps} param0
 */
const DataviewTable = ({
    defaultBaseColumn = baseColumn,
    data,
    dataview,
    isDataviewFixed = false,
    dataviewColumns = [],
    columns = [],
    isRowsSelectable = false,
    isRowSelectionDisabled,
    selectedItems = [],
    disableSearch = false,
    rowFilterProps,
    downloadCSVProps = undefined,
    onChange,
    nameColumn = 'employeeName',
    uniqueKeyColumn = 'uniqueKey',
    fillAvailableHeight = false,
    height,
    ignoredKeys = [],
    ...others
}) => {
    baseColumn = defaultBaseColumn || baseColumn;
    const dataviewColumnsWithName = [{
        ...baseColumn, key: nameColumn, uniqueKey: uniqueKeyColumn, tKey: nameColumn, visible: true,
    }, ...dataviewColumns];

    // @ts-ignore
    const mappedDataView = dataview?.map((d) => d.attributes.reduce((acc, val) => ({
        ...acc,
        [val.uniqueKey]: val.value,
        id: d.id?.toString() || d.employeeId?.toString(),
        // @ts-ignore
        employeeName: d.employeeName,
        alert: d.alert || {},
    }), {})) || [];

    const mappedData = data?.map((d) => ({
        ...d,
        id: d.id?.toString() || d.employeeId?.toString(),
    }));

    // merge arrays by id
    const fullData = values(merge(keyBy(mappedDataView, 'id'), keyBy(mappedData, 'id')));
    const [filterTerm, setFilterTerm] = useState('');

    // @ts-ignore
    const allDataviewColumns = dataviewColumnsWithName.map(mapDataviewColumnsToTableColumns);

    /** @type {Object.<string, boolean>} */
    const dataviewsSelection = appendNameColumnVisibility(
        nameColumn,
        allDataviewColumns.reduce((acc, val) => ({ ...acc, [val.uniqueKey]: val.visible }), {}),
    );
    const [selectedColumnsKeysDictionary, setSelectedColumnsKeysDictionary] = useState(dataviewsSelection);
    useEffect(() => setSelectedColumnsKeysDictionary(dataviewsSelection), [JSON.stringify(allDataviewColumns)]);

    const indexedData = fullData.map((entry) => addSearchIndex(entry, selectedColumnsKeysDictionary));
    const dataviewColumnsWithVisibility = allDataviewColumns.map((dvc) => ({
        ...dvc,
        visible: selectedColumnsKeysDictionary[dvc.uniqueKey],
    }));
    const onSearch = disableSearch
        ? undefined
        : (event, /** @type {string} */ value) => setFilterTerm(value);

    const filteredData = !filterTerm
        ? indexedData
        : indexedData.filter((i) => i.searchIndex?.includes(filterTerm.toLocaleLowerCase()));

    const cleanedUpFilteredData = filteredData.map(removeSearchIndex);
    const options = allDataviewColumns
        .filter((c) => c.key !== nameColumn && !ignoredKeys.includes(c.key))
        .map((c) => ({ checked: selectedColumnsKeysDictionary[c.uniqueKey], label: c.tKey, value: c.uniqueKey }));

    const columnFilterProps = {
        options,
        onOptionSelected: (value) => setSelectedColumnsKeysDictionary(appendNameColumnVisibility(
            nameColumn,
            toKeyVisibilityDictionary(value),
        )),
    };

    const onDownloadCSVIntermediate = () => {
        if (downloadCSVProps) {
            downloadCSVProps.onClick({
                dataviewFields: options.filter((field) => field.checked).map((field) => field.value),
                searchQuery: filterTerm,
            });
        }
    };

    const downloadCSVIntermediateProps = downloadCSVProps
        ? {
            onClick: onDownloadCSVIntermediate,
            disabled: downloadCSVProps.disabled || !(cleanedUpFilteredData.length > 0),
        }
        : undefined;

    return (
        <div className="core--dataview-table">
            <TableHeader
                onSearch={onSearch}
                rowFilterProps={rowFilterProps}
                downloadCSVProps={downloadCSVIntermediateProps}
                columnFilterProps={columnFilterProps}
            />
            <DataviewTableContent
                data={cleanedUpFilteredData}
                dataviewColumns={dataviewColumnsWithVisibility}
                columns={columns}
                selectedItems={selectedItems}
                isDataviewFixed={isDataviewFixed}
                isRowsSelectable={isRowsSelectable}
                isRowSelectionDisabled={isRowSelectionDisabled}
                nameColumn={nameColumn}
                onChange={onChange}
                fillAvailableHeight={fillAvailableHeight}
                height={height}
                ignoredKeys={ignoredKeys}
                {...others}
            />
        </div>
    );
};

export default DataviewTable;
