import React from 'react';

const OnClickInterpreter = ({ children }) => (
    <div
        onClick={(event) => {
            event.stopPropagation();
        }}
    >
        {children}
    </div>
);

export default OnClickInterpreter;
