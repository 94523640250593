import { useState } from 'react';

/** @param {boolean=} isInitiallyOpened */
const useModal = (isInitiallyOpened = false) => {
    const [isModalOpen, setIsModalOpen] = useState(isInitiallyOpened);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return {
        isModalOpen,
        openModal,
        closeModal,
    };
};

export default useModal;
