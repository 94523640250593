import { useCallback, useState } from 'react';
import useDebouncedFunction from '../useDebouncedFunction';

/** @param {number} delay default debounce 350ms delay */
const useSearch = (delay = 350) => {
    const [search, setSearch] = useState('');
    const debouncedSetSearch = useDebouncedFunction(setSearch, delay);
    const searchCallBack = useCallback((event, value) => debouncedSetSearch(value), [debouncedSetSearch]);

    /** @type {[string, (event: any, value: string) => void]} */
    const tuple = [search, searchCallBack];
    return tuple;
};

export default useSearch;
