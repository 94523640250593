import { useTranslation } from 'react-i18next';
import PeriodDisplay from '../PeriodDisplay';

/** @param {{start: Date; end:Date; dateFormatFunction?:(date: Date) => string;}} param0 */
const RequestPeriod = ({ start, end, dateFormatFunction }) => {
    const { t } = useTranslation('bid', { keyPrefix: 'periods' });
    return (
        <div className="request-period-display">
            <PeriodDisplay
                start={start}
                end={end}
                formatFunction={dateFormatFunction}
                prefix={t('request')}
            />
        </div>
    );
};

export default RequestPeriod;
