import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './TableHeader.scss';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons';
import SearchBar from '../SearchBar';
import RowFilter from '../RowFilter';
import ActionIconButton from '../ActionIconButton';

/**
 * @param {{
 *  children: *,
 *  onSearch?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, value: string) => void,
 *  onCreate?: (event: React.MouseEvent<HTMLElement>) => void,
 *  createLabel?:string,
 *  isActionDisabled?: boolean,
 *  rowFilterProps?: import('../RowFilter/RowFilter').RowFilterProps,
 *  downloadCsvProps?:{onClick: function; isDisabled: boolean;};
 * }} param0
 */
const DashboardHeader = ({
    children,
    onSearch,
    rowFilterProps,
    onCreate,
    isActionDisabled = false,
    createLabel,
    downloadCsvProps,
}) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation('table');
    createLabel = createLabel || t('actions.create');

    useEffect(() => () => setSearch(''), [setSearch]); // Reset the search value on destroy

    const headerRequired = !!(onCreate || onSearch);
    if (!headerRequired) {
        return <></>;
    }

    return (
        <div className="core--table-header">
            <div className="core--table-header-left">
                {
                    !!onCreate && (
                        <Button
                            className="create-button"
                            color="primary"
                            variant="contained"
                            onClick={onCreate}
                            disabled={isActionDisabled}
                        >
                            <FontAwesomeIcon
                                // @ts-ignore
                                icon={faPlusCircle}
                            />
                            <Typography>{createLabel}</Typography>
                        </Button>
                    )
                }
                {children}
                <div className="core--search-container">
                    {
                        !!onSearch && (
                            <div className="search-box">
                                <SearchBar
                                    id="react-search-input"
                                    value={search}
                                    onChange={(event) => {
                                        const value = event?.target?.value;
                                        setSearch(value);
                                        onSearch(event, value);
                                    }}
                                />
                            </div>
                        )
                    }

                </div>
                {
                    !!rowFilterProps && (
                        <div className="core--row-filter-box">
                            <RowFilter {...rowFilterProps} />
                        </div>
                    )
                }

            </div>
            <div className="core--table-header-right">
                {
                    !!downloadCsvProps && (
                        <ActionIconButton
                            className="core--csv-button"
                            icon={faFileCsv}
                            title={t('actions.downloadCSV')}
                            onClick={downloadCsvProps.onClick}
                            disabled={downloadCsvProps.isDisabled}
                        />
                    )
                }
            </div>
        </div>
    );
};
export default DashboardHeader;
