const HOURS = 'Hours';
const DAYS = 'Days';

export default Object.freeze({
    [HOURS]: 0,
    [DAYS]: 1,
});

export const PayCodeUnitTypeNames = Object.freeze({
    0: HOURS,
    1: DAYS,
});
