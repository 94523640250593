import axios from 'axios';
import { getAuthHeader } from '../auth';

const axiosService = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API || `https://${window.location.host}`,
});

/**
 * @param {import('axios').AxiosResponse} response
 */
const handleSuccess = (response) => response;

/**
 * @param {import('axios').AxiosRequestConfig} request
 */
const handleRequest = (request) => {
    request.headers = getAuthHeader();
    return request;
};

/**
* @param {any} error
*/
const handleRequestError = (error) => Promise.reject(error);

/**
 * @param {any} error
 */
const handleResponseError = (error) => Promise.reject(error);

/**
 * @param {*} error
 */
export const extractErrorMessage = (error) => error?.response?.data?.message || error.message || JSON.stringify(error);
axiosService.interceptors.request.use(handleRequest, handleRequestError);
axiosService.interceptors.response.use(handleSuccess, handleResponseError);
export default axiosService;
