/* eslint-disable no-unused-vars */
const informationStatusOnOpen = (bidSetup) => (bidSetup.name ? 'finish' : 'wait');
const accessProfileStatusOnOpen = (bidSetup) => (bidSetup.creationHyperfindId && bidSetup.managementHyperfindId ? 'finish' : 'wait');
const displayStatusOnOpen = (bidSetup) => (bidSetup.dataviewId && bidSetup.dataviewDisplayedCols.length ? 'finish' : 'wait');

const payCodesAccrualStatusOnOpen = (bidSetup) => (bidSetup.paycodeProfileId ? 'finish' : 'wait');
const processStatusOnOpen = (bidSetup) => (bidSetup.bidProcessType ? 'finish' : 'wait');
const bidRulesStatusOnOpen = (bidSetup) => (bidSetup.awardingRuleSetId ? 'finish' : 'wait');

const getStepsCompletion = (bidSetup) => ({
    informationStatus: informationStatusOnOpen(bidSetup),
    accessProfileStatus: accessProfileStatusOnOpen(bidSetup),
    displayStatus: displayStatusOnOpen(bidSetup),
    paycodeAccrualsStatus: payCodesAccrualStatusOnOpen(bidSetup),
    processStatus: processStatusOnOpen(bidSetup),
    bidRulesStatus: bidRulesStatusOnOpen(bidSetup),
});

export const recomputeInformationStatus = (errors, name) => {
    if (errors.name) {
        return 'error';
    }
    return name ? 'finish' : 'wait';
};

export const recomputeAccessProfileStatus = (errors, creationHyperfindId, managementHyperfindId, creationManagementQuotaHyperfindId, isQuotaHyperfindRequired) => {
    if (errors.creationEmployeeGroupIdentifier || errors.managementEmployeeGroupIdentifier || errors.creationManagementQuotaEmployeeGroupIdentifier) {
        return 'error';
    }

    if (isQuotaHyperfindRequired) {
        return creationHyperfindId && managementHyperfindId && creationManagementQuotaHyperfindId ? 'finish' : 'wait';
    }
    return creationHyperfindId && managementHyperfindId ? 'finish' : 'wait';
};

export const recomputeBidRules = (errors, awardingRuleSetId) => {
    if (errors.awardingRuleSetId) {
        return 'error';
    }
    return awardingRuleSetId ? 'finish' : 'wait';
};

export const recomputeProcess = (errors, bidProcessType) => {
    if (errors.bidProcessType) {
        return 'error';
    }
    return (typeof bidProcessType === 'number') ? 'finish' : 'wait';
};

export const recomputeDisplay = (errors, dataviewId, dataviewsSelectedCount) => {
    if (errors.dataviewId || errors.dataviewDisplayedCols) {
        return 'error';
    }

    return dataviewId && dataviewsSelectedCount ? 'finish' : 'wait';
};

export const recomputePaycodesAccruals = (errors, paycodeProfileId, paycodesCount) => {
    if (errors.paycodeProfileId || errors.paycodes || errors.selectedAccrualsFromPaycodes) {
        return 'error';
    }

    return paycodeProfileId && paycodesCount ? 'finish' : 'wait';
};

export default getStepsCompletion;
