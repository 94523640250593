import showSpinner from '@services/addSpinnerOnQueryStarted';

export const ACCRUAL_BALANCES_TAGS = ['AccrualBalances'];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Dto.IAccrualBalancesRequestParams, any, any, Dto.IAccrualBalance[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: (requestBody) => ({
            url: 'api/v1/employee/utilities/GetEmployeeAccrualBalances',
            method: 'POST',
            data: requestBody,
        }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: ACCRUAL_BALANCES_TAGS,
    });

export const endpointsAccrualBalances = (builder) => ({
    getAccrualBalances: getAll(builder),
});
