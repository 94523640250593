import showSpinner from '@services/addSpinnerOnQueryStarted';
import addRealTimeListener from '@services/addRealTimeListener';
import { AWARDING_RULE_SETS_TAG } from '@apis/awardingRuleSets';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/admin/bidSetups';
const appendToApiPath = appendPath(BASE_PATH);

export const ADMIN_BID_SETUPS_TAG = 'AdminBidSetups';
export const ADMIN_BID_SETUPS_TAGS = [ADMIN_BID_SETUPS_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IAdminBidSetup[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener('bidSetups', api); },
        providesTags: [ADMIN_BID_SETUPS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IAdminBidSetup>}
 */
const get = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: (result, error, id) => [{ type: ADMIN_BID_SETUPS_TAG, id }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAdminBidSetup, any, any, Dto.IAdminBidSetup>}
 */
const create = (builder) =>
    builder.mutation({
        query: (setup) => ({ url: BASE_PATH, method: 'POST', data: setup }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [ADMIN_BID_SETUPS_TAG, AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAdminBidSetup, any, any, Dto.IAdminBidSetup>}
 */
const update = (builder) =>
    builder.mutation({
        query: (setup) => ({ url: appendToApiPath(setup.id), method: 'PUT', data: setup }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [ADMIN_BID_SETUPS_TAG, AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAdminBidSetup, any, any, void>}
 */
const deleteAdminBidSetup = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id), method: 'DELETE' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [ADMIN_BID_SETUPS_TAG, AWARDING_RULE_SETS_TAG],
    });

export const endpointsAdminBidSetups = (builder) => ({
    getAdminBidSetups: getAll(builder),
    getAdminBidSetup: get(builder),
    createAdminBidSetup: create(builder),
    updateAdminBidSetup: update(builder),
    deleteAdminBidSetup: deleteAdminBidSetup(builder),
});
