import React, { useEffect, useState } from 'react';
import {
    Table, Whisper, Popover, Checkbox as RSCheckbox, Pagination,
} from 'rsuite';
import './WeeklyShiftLineTable.scss';
import {
    Button,
    Paper,
    Grid,
} from '@material-ui/core';
import {
    ChevronLeft,
    ChevronRight,
    SwapHoriz,
} from '@material-ui/icons';

import ShiftToolTip from '../ShiftToolTip';
// eslint-disable-next-line no-unused-vars
import useVirtualization, { HiddenBox } from '../../hooks/useVirtualization';

export const DaysOfWeekNames = Object.freeze({
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
});

const { Column, HeaderCell, Cell } = Table;
const paginationHeight = 65;
const RSuiteTableRowDefaultHeight = 46;
const pageSizeOptions = [10, 25, 50, 100, 200];
const initialPageSize = 10;

/** @param {{rowData: import('./typing').ShiftLineTableRow}} rowData */
const JobCell = ({ rowData, ...props }) => {
    const tooltipStatus = (
        <Popover visible>
            {rowData.orgJobPath}
        </Popover>
    );
    return (
        <Cell {...props}>
            <span className="wslt_orgJob_text">
                <Whisper
                    placement="top"
                    delay={500}
                    delayOpen={500}
                    delayClose={500}
                    speaker={tooltipStatus}
                >
                    <span className="wslt-cell-text-content">
                        {' '}
                        {rowData.orgJobPath}
                        {' '}
                    </span>
                </Whisper>
            </span>

        </Cell>
    );
};

/**
 * @param {import('./typing').ShiftLineTableRow[]} rows
 */
const getRowHeight = (rows) => {
    const maxShiftsPerWeek = Math.max(...rows.map((x) => Math.max(...x.days.map((y) => y.shifts.length))));
    return (RSuiteTableRowDefaultHeight + 24 * (maxShiftsPerWeek - 1));
};

/**
 * yarn lint won't work, extrated this in a function
 * @param {{ rowName: string }} row
 * @param {{ name: string }} x
 * @returns
 */
function getKey(row, x) {
    return `shift_element_${row.rowName}_${x.name}`;
}

/**
 * @param {import('./typing').ShiftLineTableProps} props
 */
function WeeklyShiftLineTable({
    rowsProp,
    firstDayOfTheWeek = 0,
    handleCheck = () => { },
    handleCheckAll = () => { },
    isCheckable = false,
    isCheckboxDisabled = false,
    tableHeight = 400, 
    renderEmpty,
    dayCount,
    isPeriodTypeDay,
    daysPerPeriod = 7,
    isPaginated = false,
}) {
    const [weekNumber, setWeekNumber] = useState(0);
    const [rows, setRows] = useState(rowsProp);
    const week = Math.ceil(dayCount / 7);
    const [nbWeeks, setNbWeeks] = useState(week);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [pageIndex, setPageIndex] = useState(1);
    const { debounceRender, randomValue } = useVirtualization();

    const data = isPaginated 
        ? rows.filter((v, i) => {
            const start = pageSize * (pageIndex - 1);
            const end = start + pageSize;
            return i >= start && i < end;
        })
        : rows;

    useEffect(() => {
        const maximumWeekDays = Math.max(...rowsProp.map((a) => a.days.length));
        const weeks = Math.ceil(maximumWeekDays / daysPerPeriod);

        if (weekNumber + 1 > weeks) {
            setWeekNumber(weeks);
        }
        setRows(rowsProp);
        setNbWeeks(weeks);

        // We only  want to reset if the number of weeks is smaller than the selected week
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(rowsProp)]);

    /**
     * @param {import('./typing').ShiftLineTableRow} row
     * @param {import('./typing').ShiftLineTableRowDay} dayElement
     * @returns
     */
    const GenerateDayElements = (row, dayElement) => {
        const shifts = dayElement?.shifts?.map((x) => {
            const key = getKey(row, x);
            const shiftNameSpan = (
                <span
                    key={key}
                    className="wslt_shiftElement"
                >
                    {x.name}
                    {x.tooltipShiftInfo?.hasTransfer && <SwapHoriz className="wslt_transfer_icon" />}
                </span>
            );

            if (x.tooltipShiftInfo) {
                return (
                    <ShiftToolTip
                        key={key}
                        element={shiftNameSpan}
                        shiftInfos={x.tooltipShiftInfo}
                    />
                );
            }

            return shiftNameSpan;
        });

        // Add paycodes code here when needed
        return shifts;
    };

    /**
     * @param {Number} direction
     */
    const handleWeekChange = (direction) => {
        if (direction > 0) {
            setWeekNumber(Math.min(weekNumber + 1, nbWeeks - 1));
        } else {
            setWeekNumber(Math.max(weekNumber - 1, 0));
        }
    };

    /**
     * @param {number} size 
     */
    const handleChangePageSize = (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    /**
     * @param {number} pageNumber 
     */
    const handleChangePageIndex = (pageNumber) => {
        setPageIndex(pageNumber);
    };

    /**
     * @type {number[]}
     */
    const checkedRows = rows.filter((x) => x.isSelected).map((x) => x.id);

    const adjustedTableHeight = isPaginated ? tableHeight - paginationHeight : tableHeight;

    const isSelectAllChecked = checkedRows.length === rows.length;
    const isSelectAllIndeterminate = !!(!isSelectAllChecked
        && checkedRows.length
        && checkedRows.length < rows.length);

    return (
        <Paper
            className="wslt_paper"
            key="weeklyShiftLinePaper"
        >
            <div className="wslt_weekSelectionBar">
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    className="wslt_root"
                >
                    <Grid
                        xs={4}
                        item
                    >
                        {
                            nbWeeks > 1
                                ? (
                                    <Button onClick={() => handleWeekChange(-1)}>
                                        <ChevronLeft />
                                    </Button>
                                ) : null
                        }
                    </Grid>
                    <Grid
                        xs={4}
                        className="wslt_alignCenter"
                        item
                    >
                        {isPeriodTypeDay
                            ? `Day ${((weekNumber + 1) * daysPerPeriod) - 6} to ${(weekNumber + 1) * daysPerPeriod}`
                            : `Week ${weekNumber + 1}`}
                    </Grid>
                    <Grid
                        xs={4}
                        className="wslt_flexRight"
                        item
                    >
                        {
                            nbWeeks > 1
                                ? (
                                    <Button onClick={() => handleWeekChange(1)}>
                                        <ChevronRight />
                                    </Button>
                                ) : null
                        }

                    </Grid>
                </Grid>
            </div>
            <HiddenBox value={randomValue} />
            <Table
                virtualized
                height={adjustedTableHeight}
                data={data}
                rowHeight={getRowHeight(rows)}
                // @ts-ignore
                renderEmpty={renderEmpty}
                onScroll={debounceRender}
                rowClassName="wslt_row"
                hover={false}
            >
                {isCheckable && (
                    <Column
                        width={50}
                        align="center"
                        resizable
                    >
                        <HeaderCell>
                            <div style={{ lineHeight: '40px' }}>
                                <RSCheckbox
                                    inline
                                    checked={isSelectAllChecked}
                                    indeterminate={isSelectAllIndeterminate}
                                    onChange={handleCheckAll}
                                    disabled={isCheckboxDisabled}
                                />
                            </div>
                        </HeaderCell>
                        <Cell>
                            {
                                // @ts-ignore
                                (/** @type {import('./typing').ShiftLineTableRow} */ rowData) => (
                                    <div style={{ lineHeight: '46px' }}>
                                        <RSCheckbox
                                            value={rowData.id}
                                            inline
                                            onChange={handleCheck}
                                            checked={checkedRows.includes(rowData.id)}
                                            disabled={isCheckboxDisabled}
                                        />
                                    </div>
                                )
                            }
                        </Cell>
                    </Column>
                )}
                <Column
                    align="center"
                    verticalAlign="middle"
                    flexGrow={3}
                >
                    <HeaderCell>Shiftline</HeaderCell>
                    <Cell
                        className="wslt_tableCells wslt_text_cell"
                        dataKey="rowName"
                    />
                </Column>

                <Column
                    align="center"
                    flexGrow={2}
                    verticalAlign="middle"
                >
                    <HeaderCell>Jobs</HeaderCell>
                    <JobCell
                        // @ts-ignore
                        className="wslt_tableCells wslt_text_cell"
                        dataKey="Name"
                    />
                </Column>
                {
                    Array.from(Array(daysPerPeriod).keys()).map((x) => (
                        <Column
                            flexGrow={2}
                            key={`scheduleCol_Day${x}`}
                        >
                            <HeaderCell
                                className={`${(weekNumber * daysPerPeriod) + (x + 1) > dayCount ? 'wslt_lightgrey' : ''}`}
                            >
                                {isPeriodTypeDay
                                    ? `Day ${(weekNumber * daysPerPeriod) + (x + 1)}`
                                    : DaysOfWeekNames[(x + firstDayOfTheWeek) % daysPerPeriod]}
                            </HeaderCell>
                            <Cell
                                className={`wslt_tableCells ${(weekNumber * daysPerPeriod) + (x + 1) > dayCount ? 'wslt_lightgrey' : ''}`}
                            >
                                {
                                    // @ts-ignore
                                    (/** @type {import('./typing').ShiftLineTableRow} */rowData) =>
                                        GenerateDayElements(rowData, rowData.days[(weekNumber * daysPerPeriod) + x])
                                }
                            </Cell>
                        </Column>
                    ))
                }
            </Table>
            {isPaginated && (
                <div className="pagination-wrapper">
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'pager', '|', 'skip', '|', 'limit']}
                        total={rows.length}
                        limitOptions={pageSizeOptions}
                        limit={pageSize}
                        onChangeLimit={handleChangePageSize}
                        activePage={pageIndex}
                        onChangePage={handleChangePageIndex}
                    />
                </div>
            )}
        </Paper>

    );
}

export default WeeklyShiftLineTable;
