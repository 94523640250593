import managerBidDashboardSlice from './managerBidDashboard';

export {
    statusFilterSelector,
    dataviewFieldsSelector,
    employeeIdsSelector,
} from './managerBidDashboard';

const { actions } = managerBidDashboardSlice;

export const {
    reset,
    setStatusFilterSelected,
    setDataviewFieldsSelected,
    setEmployeeIdsSelected,
} = actions;

export default managerBidDashboardSlice;
