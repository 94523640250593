import { Controller } from 'react-hook-form';
import TimePicker from '../TimePicker';

/**
 * @param {import('rsuite').DatePickerProps & {
 *  name: string; control: any;
 *  error?: boolean; rules?: import('react-hook-form').RegisterOptions;
 *  value?: Date;
 * }} param0
 */
const FormTimePicker = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, value,
            },
        }) => (
            <TimePicker
                {...props}
                value={value}
                onSubmit={(date, event) => {
                    onChange(date, event);

                    if (valueChanged) {
                        valueChanged(date, event);
                    }
                }}
            />
        )}
    />
);

export default FormTimePicker;
