const SEQUENTIAL = 'Sequential';
const PREFERENTIAL = 'Preferential';

export default Object.freeze({
    [SEQUENTIAL]: 0,
    [PREFERENTIAL]: 1,
});

export const BidProcessTypeNames = Object.freeze({
    0: SEQUENTIAL,
    1: PREFERENTIAL,
});
