import {
    useState, useMemo, useEffect, useRef,
} from 'react';
import { Paper, Tooltip } from '@material-ui/core';
import { faAward as awardIcon } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Checkbox, Table } from 'rsuite';
import {
    Warning, Error, CheckCircle, Info,
} from '@material-ui/icons';
import AlertType, { AlertClassNames } from '../../enums/AlertType';
import { useAvailableHeight } from '../../hooks';
import { dynamicSort } from '../../services/sort';
import { DataviewValue, SummaryLine, TableRsuiteWrapper } from '..';
import columnWidthMap from './columnWidthMap';
import './DataviewTableContent.scss';
import LightTooltip from '../LightTooltip';

const { Cell, Column, HeaderCell } = Table;

/** @type {CommonClient2.SortType} */
let defaultSortType;
// eslint-disable-next-line prefer-const
defaultSortType = 'asc';

const CheckCell = ({
    rowData, isRowSelectionDisabled, onChange, checkedKeys, dataKey, ...props
}) => (
    <Cell
        {...props}
        style={{ padding: 0 }}
    >
        <div style={{ lineHeight: '46px' }}>
            <Checkbox
                value={rowData[dataKey]}
                inline
                onChange={onChange}
                disabled={!!isRowSelectionDisabled}
                checked={checkedKeys.some((item) => item?.toString() === rowData[dataKey]?.toString())}
            />
        </div>
    </Cell>
);

// eslint-disable-next-line react/display-name
const mapNonDataviewColumn = (t) => (/** @type {CommonClient2.DataviewTableStaticColumn} */ {
    key, uniqueKey, tKey, isTooltip, ...rest
}) => {
    if (isTooltip) {
        return (
            <Column
                {...rest}
                key={`${uniqueKey || key}-attribute-column`}
            >
                <HeaderCell>{t(tKey)}</HeaderCell>
                <Cell dataKey={key}>
                    {(rowData) => (
                        <div
                            className="core--tooltip-cell"
                        >
                            <Tooltip title={rowData[key]}>
                                <span>{rowData[key]}</span>
                            </Tooltip>
                        </div>
                    )}
                </Cell>
            </Column>
        );
    }

    return (
        <Column
            {...rest}
            key={key}
        >
            <HeaderCell>{t(tKey)}</HeaderCell>
            <Cell dataKey={key} />
        </Column>
    );
};

const GetAlertIcon = ({ alertType }) => {
    switch (alertType) {
        case AlertType.Info:
            return <Info />;
        case AlertType.Warning:
            return <Warning />;
        case AlertType.Error:
            return <Error />;
        case AlertType.Success:
            return <CheckCircle />;
        default:
            return <></>;
    }
};

/**
 * @template T
 * @param {{
 *  data: T[];
 *  isDataviewFixed?: boolean;
 *  tableId?: string;
 *  columns: CommonClient2.DataviewTableStaticColumn[];
 *  dataviewColumns: CommonClient2.DataviewTableColumn[];
 *  onRowClick?: (rowData: T, event: *) => void;
 *  isRowsSelectable?: boolean;
 *  isRowSelectionDisabled?: boolean;
 *  selectedItems: string[];
 *  nameColumn: string;
 *  onChange?: (ids: number[]) => {};
 *  fillAvailableHeight: boolean;
 *  ignoredKeys: string[];
 *  warnings?: CommonClient2.RowAlertProp[];
 *  showAlertColumn?: boolean;
 * } & import('rsuite-table').TableProps} param0
 */
const DataviewTableContent = ({
    data,
    isDataviewFixed = false,
    columns = [],
    dataviewColumns = [],
    nameColumn,
    isRowsSelectable,
    isRowSelectionDisabled,
    selectedItems,
    onChange,
    fillAvailableHeight,
    height,
    ignoredKeys,
    showAlertColumn = false,
    ...others
}) => {
    const ref = useRef(null);
    const [firstColumn] = columns;
    const [sortType, setSortType] = useState(defaultSortType);
    const [sortColumn, setSortColumn] = useState(firstColumn?.key || '');
    const [checkedKeys, setCheckedKeys] = useState(selectedItems || []);

    const sortKey = sortType === 'desc' ? `-${sortColumn}` : sortColumn;
    const sortedData = useMemo(() => data?.sort(dynamicSort(sortKey)), [JSON.stringify(data), sortType, sortColumn]);
    const { t } = useTranslation('table');

    useEffect(() => setCheckedKeys(selectedItems || []), [JSON.stringify(selectedItems)]);
    /**
     * @param {string} newSortColumn
     * @param {CommonClient2.SortType} newSortType
     */
    const handleSortColumn = (newSortColumn, newSortType) => {
        setSortColumn(newSortColumn);
        setSortType(newSortType);
    };

    const onSelectionChange = (keys) => {
        const stringifiedKeys = keys?.map((k) => k.toString());
        setCheckedKeys(stringifiedKeys);
        if (onChange) {
            onChange(stringifiedKeys);
        }
    };

    const handleCheckAll = (_, checked) => {
        // @ts-ignore
        const keys = checked ? data.map((item) => item.id) : [];
        onSelectionChange(keys);
    };

    const handleCheck = (value, checked) => {
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);
        onSelectionChange(keys);
    };

    const getRowClass = (rowData) => {
        const alertClass = AlertClassNames[rowData?.alert?.alertType] || '';
        return `core--table-dataview-row ${rowData?.isAwarded ? 'awarded' : ''} ${alertClass}`;
    };

    const checked = checkedKeys.length === data?.length;
    const indeterminate = !!checkedKeys.length && checkedKeys.length < data?.length;

    const beforeNameColumns = (columns || []).filter((c) => c.isBeforeName);
    const beforeDataviewColumns = (columns || []).filter((c) => !c.isBeforeName && c.isBeforeDataview);
    const afterDataviewColumns = (columns || []).filter((c) => !c.isBeforeName && !c.isBeforeDataview);

    const nameColumnFound = (dataviewColumns || []).find((dv) => dv.key.includes(nameColumn));
    const autoHeightValue = useAvailableHeight(ref, fillAvailableHeight);

    return (
        <Paper
            elevation={2}
            ref={ref}
            className="core--table-dataview-content"
        >
            <TableRsuiteWrapper
                data={sortedData}
                sortType={sortType}
                sortColumn={sortColumn}
                // @ts-ignore
                onSortColumn={handleSortColumn}
                rowClassName={getRowClass}
                height={height || autoHeightValue}
                {...others}
            >
                {
                    isRowsSelectable && (
                        <Column
                            width={50}
                            align="center"
                            key="row-selection-column"
                            fixed="left"
                        >
                            <HeaderCell className="header-checkbox-cell">
                                <Checkbox
                                    inline
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    disabled={!!isRowSelectionDisabled}
                                    onChange={handleCheckAll}
                                />
                            </HeaderCell>
                            <CheckCell
                                dataKey="id"
                                checkedKeys={checkedKeys}
                                onChange={handleCheck}
                                isRowSelectionDisabled={isRowSelectionDisabled}
                                rowData={null}
                            />
                        </Column>
                    )
                }
                {showAlertColumn && (
                    <Column
                        width={40}
                        align="center"
                        fixed="left"
                    >
                        <HeaderCell><></></HeaderCell>
                        <Cell dataKey="alert">
                            {({ alert }) => (
                                <LightTooltip title={<div>{alert?.message || ''}</div>}>
                                    <div>{(GetAlertIcon(alert))}</div>
                                </LightTooltip>
                            )}
                        </Cell>

                    </Column>
                )}
                {(beforeNameColumns).map(mapNonDataviewColumn(t))}
                {
                    nameColumnFound && (
                        <Column
                            fixed="left"
                            {...nameColumnFound}
                            minWidth={175}
                            key="name-column"
                        >
                            <HeaderCell>{t(`columns.${nameColumnFound.tKey}`)}</HeaderCell>
                            <Cell dataKey={nameColumnFound.key}>
                                {(rowData) => (
                                    <div className="name-column">
                                        {rowData?.isAwarded && (
                                            <div className="award-icon">
                                                <FontAwesomeIcon
                                                    // @ts-ignore
                                                    icon={awardIcon}
                                                    size="lg"
                                                    color="#00862c"
                                                />
                                            </div>
                                        )}
                                        <LightTooltip
                                            placement="bottom-start"
                                            title={(
                                                <>
                                                    <span className="tooltip-title">
                                                        {rowData[nameColumnFound.key]}
                                                    </span>
                                                    {
                                                        dataviewColumns
                                                            .filter((dvc) => !ignoredKeys?.includes(dvc.key))
                                                            .map(({
                                                                // @ts-ignore
                                                                uniqueKey, tKey: lineLabel, label: title, dataValueType,
                                                            }) => (
                                                                <SummaryLine
                                                                    key={uniqueKey}
                                                                    label={lineLabel || title}
                                                                    value={(
                                                                        <DataviewValue
                                                                            dataValueType={dataValueType}
                                                                            value={rowData[uniqueKey]}
                                                                        />
                                                                    )}
                                                                />
                                                            ))
                                                    }
                                                </>
                                            )}
                                        >
                                            <span>{rowData[nameColumnFound.key]}</span>
                                        </LightTooltip>
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    )
                }
                {(beforeDataviewColumns).map(mapNonDataviewColumn(t))}
                {(dataviewColumns || []).filter((dv) => dv.visible && !dv.key.includes(nameColumn))
                    .map(({
                        uniqueKey, tKey, dataValueType, ...rest
                    }) => (
                        <Column
                            fixed={isDataviewFixed ? 'left' : undefined}
                            {...rest}
                            key={uniqueKey}
                            minWidth={columnWidthMap[dataValueType]}
                        >
                            <HeaderCell>{t(tKey)}</HeaderCell>
                            <Cell dataKey={uniqueKey}>
                                {(rowData) => (
                                    <DataviewValue
                                        dataValueType={dataValueType}
                                        value={rowData[uniqueKey]}
                                    />
                                )}
                            </Cell>
                        </Column>
                    ))}
                {(afterDataviewColumns || []).map(mapNonDataviewColumn(t))}
            </TableRsuiteWrapper>
        </Paper>
    );
};
export default DataviewTableContent;
