import { debounce } from 'lodash';
import { TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';

const debouncedUpdate = debounce((updatedValueString, updatedValue, onChange) => onChange(updatedValueString, updatedValue), 200);

const DebouncedTextField = ({ onChange, value, ...others }) => {
    const [displayedValue, setDisplayedValue] = useState(value);
    const onValueChange = (ev, changedValue) => {
        debouncedUpdate(ev, changedValue, onChange);
        setDisplayedValue(changedValue);
    };

    useEffect(() => setDisplayedValue(value), [value]);
    return (
        <TextField
            onChange={onValueChange}
            value={displayedValue}
            {...others}
        />
    );
};

export default DebouncedTextField;
