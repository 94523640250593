import { Controller } from 'react-hook-form';
import { DebouncedTextField } from '..';

/**
 * @param {import('@material-ui/core').TextFieldProps & {
 *  name: string; control: any;
 *  error?: boolean; rules?: any;
 * }} param0
 */
const FormDebouncedTextField = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, onBlur, value, ref,
            },
        }) => (
            <DebouncedTextField
                {...props}
                value={value}
                ref={ref}
                onBlur={onBlur}
                onChange={(event) => {
                    onChange(event);
                    if (valueChanged) {
                        valueChanged(event);
                    }
                }}
            />
        )}
    />
);

export default FormDebouncedTextField;
