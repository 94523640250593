import React from 'react';
import { useLocation } from 'react-router-dom';
import Licenses from '../../enums/Licenses';
import useCookies from '../../hooks/useCookies';
import { HrSystemIntegrationTypes } from '../../services/enums';
import Unauthorized from '../Unauthorized';
import authService from '../../services/auth';

// paths are /{userRole}/otherPages, so we take first element of split
const getUserRoleFromPath = (path) => {
    const licenses = Object.values(Licenses);
    return path.split('/')?.find((el) => licenses.includes(el)) || '';
};

/**
 * @param {{ children: any, requiredLicense?: CommonClient2.JwtLicenses, redirectUrl: string, configs:any }} param0
 */
const PrivateRoute = ({
    children,
    redirectUrl = '',
    requiredLicense,
    configs,
}) => {
    const { setCookie } = useCookies(configs.domainName);
    const location = useLocation();

    // Set Cookie for which role the user is currently using -- ie: employee, manager, admin
    const userRole = getUserRoleFromPath(location.pathname);
    setCookie('userRole', userRole);
    const loginUri = `/login/${userRole}`;

    const isLoggedIn = authService.isLoggedIn();
    const isLicenseValid = !requiredLicense || (requiredLicense && isLoggedIn && authService.validateLicense(requiredLicense));

    const isDev = process.env.NODE_ENV === 'development';
    const isUkgDimensions = configs?.hrSystemIntegrationType === HrSystemIntegrationTypes.UKGWorkForceDimensions;
    const isRedirect = !(isDev && isUkgDimensions) && !isLoggedIn;

    if (isRedirect) {
        window.location.href = redirectUrl;
        return <></>;
    }

    if (isLoggedIn && !isLicenseValid) {
        return <Unauthorized />;
    }

    if (!isLoggedIn) {
        window.location.href = `${window.location.origin}${loginUri}`;
        return <></>;
    }

    return children;
};

export default PrivateRoute;
