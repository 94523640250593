import React from 'react';
import './ErrorDisplay.scss';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

/**
 * @param {{message?: string, children?: any}} param0
 */
const ErrorDisplay = ({ message, children }) => {
    const { t } = useTranslation('common');
    const content = message
        ? (
            <Alert severity="error">
                <AlertTitle>{t('error')}</AlertTitle>
                <span>{message}</span>
            </Alert>
        )
        : <div>{children}</div>;

    return (
        <div className="core--error-display">
            {content}
        </div>
    );
};

export default ErrorDisplay;
