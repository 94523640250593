import { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { intersection } from 'lodash';
import TransferListHeader from './TransferListHeader';
import TransferListBody from './TransferListBody';

/**
 * @param {{
 *  width?: number,
 *  showCount?: boolean;
 *  title: string;
 *  items: *[];
 *  useSearchBars?: boolean;
 *  useSelectAll?: boolean;
 *  onCheckToggleItem: *;
 *  onCheckToggleAll: *;
 *  disabled?: boolean;
 *  checked: *[];
 * }} param0
 * @returns
 */
const TransferListSection = ({
    width = 300,
    showCount,
    title,
    items,
    useSearchBars,
    useSelectAll,
    onCheckToggleItem,
    onCheckToggleAll,
    disabled,
    checked,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const checkedItemsCount = intersection(checked, items).length;

    return (
        <Paper className="core--transferList-paper">
            <div className="core--transferList-headerTitle">
                {showCount ? `${items.length} ${title}` : `${title}`}
            </div>
            {(useSearchBars || useSelectAll) && (
                <TransferListHeader
                    items={items}
                    searchValue={searchValue}
                    searchValueHandler={setSearchValue}
                    useSelectAll={useSelectAll}
                    useSearchBars={useSearchBars}
                    onCheckToggleAll={onCheckToggleAll}
                    disabled={disabled}
                    checkedItemsCount={checkedItemsCount}
                />
            )}
            <TransferListBody
                items={items}
                search={searchValue}
                checked={checked}
                disabled={disabled}
                onCheckToggleItem={onCheckToggleItem}
                width={width}
            />
        </Paper>
    );
};

export default TransferListSection;
