import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_MANAGER_PATH = 'api/v1/manager/Utilities';
const BASE_ADMIN_PATH = 'api/v1/admin/Utilities';
const appendToManagerApiPath = appendPath(BASE_MANAGER_PATH);
const appendToAdminApiPath = appendPath(BASE_ADMIN_PATH);

export const DATASETS_TAG = 'DataSet';
export const DATASET_COLUMNS_TAG = 'DataSetsColumns';
export const DATASET_INFORMATION_TAG = 'DataSetInformation';
export const DATASETS_TAGS = [DATASETS_TAG, DATASET_COLUMNS_TAG, DATASET_INFORMATION_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, CommonClient2.DataSetGroupsResponse>}
 */
const getDataSetGroups = (builder) =>
    builder.query({
        query: () => ({ url: appendToManagerApiPath('dataSetGroups'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [DATASETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, CommonClient2.DataSetGroupsResponse>}
 */
const getDataSetGroupsAdmin = (builder) =>
    builder.query({
        query: () => ({ url: appendToAdminApiPath('dataSetGroups'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [DATASETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, CommonClient2.DataviewColumn[]>}
 */
const getDataSetColumnsForSetup = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToManagerApiPath('dataSetColumnsForSetup', id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [DATASET_COLUMNS_TAG],
    });

/**
* @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
* @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Dto.IDataSetInformationFilter, any, any, Dto.IDataviewInformation[]>}
*/
const getDataSetsForEmployeeGroup = (builder) =>
    builder.query({
        query: (filter) => ({
            url: appendToManagerApiPath('EmployeeDataviewInformations'),
            method: 'POST',
            data: filter,
        }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [DATASET_COLUMNS_TAG],
    });

export const endpointsDataSets = (builder) => ({
    getDataSetGroups: getDataSetGroups(builder),
    getDataSetGroupsAdmin: getDataSetGroupsAdmin(builder),
    getDataSetColumnsForSetup: getDataSetColumnsForSetup(builder),
    getDataSetsForEmployeeGroup: getDataSetsForEmployeeGroup(builder),
});
