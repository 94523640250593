import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import logo from '../../assets/zaddons_logo.png';
import authService from '../../services/auth';
import LoginForm from './LoginForm';

import './Login.scss';

const Login = () => {
    const [isSpinnerVisible, setSpinner] = useState(false);
    const [account, setAccount] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    /** @type {{ target?: string }} */
    const { target } = useParams(); // Only accessible if route is /login/:target

    const validateParameters = () => {
        let errorMessage = '';

        if (account.password.trim() === '') {
            errorMessage = 'Password is required';
        }

        if (account.username.trim() === '') {
            errorMessage = 'Username is required';
        }

        if (errorMessage !== '') {
            toastr.error('Authentication Failed', errorMessage);
        }
        const isValid = !errorMessage;
        return isValid;
    };

    /** @type {(event: React.FormEvent<HTMLFormElement>) => Promise<void>} */
    const handleSubmitLogin = async (event) => {
        event.preventDefault();
        if (validateParameters()) {
            setSpinner(true);
            try {
                await authService.login(account.username, account.password);
                navigate(`/${target}`);
            } catch (error) {
                toastr.error('Authentication Failed', 'Wrong username or password');
            } finally {
                setSpinner(false);
            }
        }
    };

    /** @type {(event: React.ChangeEvent<HTMLInputElement>) => void} */
    const handleChange = (event) => {
        const { name, value } = event.target;
        // @ts-ignore
        account[name] = value;
        setAccount((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <LoginForm
            account={account}
            handleChange={handleChange}
            handleSubmitLogin={handleSubmitLogin}
            isSpinnerVisible={isSpinnerVisible}
            logo={logo}
        />
    );
};

export default Login;
