import showSpinner from '@services/addSpinnerOnQueryStarted';

export const CONFIGS_TAGS = ['BidSetups'];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, CommonClient2.IPublicConfig>}
 */
const getPublicConfig = (builder) => builder.query({
    query: () => ({ url: 'api/v1/common/publicConfig', method: 'GET' }),
    async onQueryStarted(id, api) { await showSpinner(api); },
    providesTags: CONFIGS_TAGS,
});

export const endpointsAppConfigs = (builder) => ({
    getPublicConfigs: getPublicConfig(builder),
});
