import React from 'react';
import { Controller } from 'react-hook-form';
import TransferList from '../TransferList';

/**
 *  @param { Omit<CommonClient2.TransferListProps, 'items'> & {
 *  name: string;
 *  control: *;
 *  rules : import('react-hook-form').RegisterOptions;
 *  }} param0
 * */
export default function FormTransferList({
    name,
    control,
    rules,
    onChange: valueChanged,
    ...props
}) {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: {
                    onChange, value,
                },
            }) => (
                <TransferList
                    {...props}
                    items={value}
                    onChange={(selections) => {
                        onChange(selections);
                        if (valueChanged) {
                            valueChanged(selections);
                        }
                    }}
                />
            )}
        />
    );
}
