import { isNil } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * @param {string} fieldName
 * @param {number|null|undefined} min
 * @param {number|null|undefined} max
 * @param {number=} debounce
 */
const useNumericFormField = (fieldName, min, max, debounce = 350) => {
    const { watch, setValue } = useFormContext();
    const delay = watch(fieldName);

    const setDelay = useCallback((/** @type {number} */ value) => setValue(fieldName, Number(value)), [setValue, fieldName]);

    useEffect(() => {
        let adjustedValue = Number(delay);
        if (!isNil(min)) {
            adjustedValue = Math.max(adjustedValue, min);
        }
        if (!isNil(max)) {
            adjustedValue = Math.min(adjustedValue, max);
        }
        setDelay(adjustedValue);
    }, [setDelay, delay, min, max, debounce]);

    return { min, max };
};

export default useNumericFormField;
