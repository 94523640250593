import React, { useEffect, useState } from 'react';
import { intersection, difference, union } from 'lodash';
import { BidirectionalArrows } from '..';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import TransferListSection from './TransferListSection';
import './TransferList.scss';
import {
    onlySelected,
    onlyUnselected,
    selectItem,
    unselectItem,
    toggleItemInList,
} from './TransferList.service';

/** @type {CommonClient2.TransferListItem[]} */
const emptyCheckedItems = [];

/** @param {CommonClient2.TransferListProps} props */
const TransferList = ({
    items = [],
    disabled,
    onChange,
    onItemToggle = () => { },
    useTotalLeft,
    useSearchBars,
    useSelectAll,
    useTotalRight,
    titleLeft,
    titleRight,
    width: widthProp = 300,
}) => {
    const [checkedItems, setCheckedItems] = useState(emptyCheckedItems);
    const [leftItems, setLeftItems] = useState(items.filter(onlyUnselected));
    const [rightItems, setRightItems] = useState(items.filter(onlySelected));

    useEffect(() => {
        setLeftItems(items.filter(onlyUnselected));
        setRightItems(items.filter(onlySelected));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(items)]);

    const leftChecked = intersection(checkedItems, leftItems);
    const rightChecked = intersection(checkedItems, rightItems);

    /** @param {CommonClient2.TransferListItem[]} itemsToToggle */
    const checkToggleAll = (itemsToToggle) => () => {
        const checkedItemsCount = intersection(checkedItems, itemsToToggle).length;
        const isCheckAll = checkedItemsCount !== itemsToToggle.length;
        const selectionApply = isCheckAll ? union : difference;
        setCheckedItems(selectionApply(checkedItems, itemsToToggle));
    };

    /** @param {CommonClient2.TransferListItem} item */
    const checkToggleItem = (item) => () => {
        setCheckedItems(toggleItemInList(checkedItems, item));
        if (onItemToggle) {
            onItemToggle(item);
        }
    };

    const moveCheckItemsToRightList = () => {
        const leftSelection = difference(leftItems, leftChecked).map(unselectItem);
        const rightSelection = rightItems.concat(leftChecked).map(selectItem);
        setLeftItems(leftSelection);
        setRightItems(rightSelection);
        setCheckedItems(difference(checkedItems, leftChecked));
        onChange?.([...leftSelection, ...rightSelection]);
    };

    const moveCheckedItemsToLeftList = () => {
        const leftSelection = leftItems.concat(rightChecked).map(unselectItem);
        const rightSelection = difference(rightItems, rightChecked).map(selectItem);
        setLeftItems(leftSelection);
        setRightItems(rightSelection);
        setCheckedItems(difference(checkedItems, rightChecked));
        onChange?.([...leftSelection, ...rightSelection]);
    };

    const { width } = useWindowDimensions();
    const isMobile = width < 587;
    const orientation = isMobile ? 'horizontal' : 'vertical';
    const direction = isMobile ? 'upDown' : 'leftRight';
    const style = isMobile ? { justifyContent: 'space-around' } : {};

    return (
        <div className="core--transferList">
            <TransferListSection
                items={leftItems}
                title={titleLeft}
                showCount={useTotalLeft}
                useSearchBars={useSearchBars}
                useSelectAll={useSelectAll}
                disabled={disabled}
                onCheckToggleAll={checkToggleAll}
                onCheckToggleItem={checkToggleItem}
                checked={leftChecked}
                width={widthProp}
            />
            <div className="core--transferList-button">
                <BidirectionalArrows
                    direction={direction}
                    buttonGroupProps={{ orientation, style }}
                    firstButtonProps={{
                        onClick: moveCheckedItemsToLeftList,
                        disabled: !rightChecked.length,
                    }}
                    secondButtonProps={{
                        onClick: moveCheckItemsToRightList,
                        disabled: !leftChecked.length,
                    }}

                />
            </div>
            <TransferListSection
                items={rightItems}
                title={titleRight}
                showCount={useTotalRight}
                useSearchBars={useSearchBars}
                useSelectAll={useSelectAll}
                disabled={disabled}
                onCheckToggleAll={checkToggleAll}
                onCheckToggleItem={checkToggleItem}
                checked={rightChecked}
                width={widthProp}
            />
        </div>
    );
};

export default TransferList;
