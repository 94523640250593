import api from '@apis/api';
import { HrSystemIntegrationTypes } from '@commonClient/services/enums';

const DEFAULT_HR_SYSTEM = -1;
const ukgDimensions = HrSystemIntegrationTypes.UKGWorkForceDimensions;
const ukgCentral = HrSystemIntegrationTypes.UKGWorkForceCentral;
const adp = HrSystemIntegrationTypes.ADPWorkForceNow;
const shiftBoard = HrSystemIntegrationTypes.ShiftBoard;
const allHrSystems = [ukgDimensions, ukgCentral, adp, shiftBoard, DEFAULT_HR_SYSTEM];

const useHrSystem = () => {
    const { data } = api.useGetPublicConfigsQuery();
    const hrSystem = !data?.hrSystemIntegrationType && data?.hrSystemIntegrationType !== 0
        ? DEFAULT_HR_SYSTEM
        : data?.hrSystemIntegrationType;

    if (!allHrSystems.includes(hrSystem)) {
        throw new Error('Hr system not defined!');
    }

    return {
        isUkgDimensions: hrSystem === ukgDimensions,
        isUkgCentral: hrSystem === ukgCentral,
        isAdp: hrSystem === adp,
        isShiftBoard: hrSystem === shiftBoard,
        isNotYetAvailable: hrSystem === DEFAULT_HR_SYSTEM,
    };
};

export default useHrSystem;
