import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

export const COMMANDS_TAG = 'Commands';
const BASE_PATH = 'api/v1/common/SendCommand';
const appendToApiPath = appendPath(BASE_PATH);

const sendTypeCommand = (builder) =>
    builder.query({
        query: ({ type, payload }) => ({ url: appendToApiPath(type), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

const sendCommand = (builder) =>
    builder.query({
        query: (data) => ({ url: BASE_PATH, method: 'POST', data }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

export const commandEndpoints = (builder) => ({
    sendTypeCommand: sendTypeCommand(builder),
    sendCommand: sendCommand(builder),
});
