import bidSetupStepsSlice from './bidSetupSteps';

export {
    default as getStepsCompletion,
    recomputeInformationStatus,
    recomputeAccessProfileStatus,
    recomputeBidRules,
    recomputeProcess,
    recomputeDisplay,
    recomputePaycodesAccruals,
} from './bidSetupStepsCompletion';

export {
    informationStepStatusSelector, accessProfileStepStatusSelector, bidRulesStepStatusSelector,
    displayStepStatusSelector, payCodesAccrualStepStatusSelector,
    processStepStatusSelector,
} from './bidSetupSteps';

const { actions } = bidSetupStepsSlice;

export const {
    createBidSetupSteps, editBidSetupSteps, setAccessProfileStep, setBidRulesStep,
    setDisplayStep, setInformationStep, setPayCodesAccrualStep,
    setProcessStep,
} = actions;

export default bidSetupStepsSlice;
