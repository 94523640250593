const MANUAL = 'manualApproval';
const AUTOMATIC = 'automaticApproval';

export default Object.freeze({
    [MANUAL]: 0,
    [AUTOMATIC]: 1,
});

export const BidApprovalProcessTypeNames = Object.freeze({
    0: MANUAL,
    1: AUTOMATIC,
});
