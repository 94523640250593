const DAILY = 'Daily';
const WEEKLYSEVEN = 'Weekly';
const WEEKLYFIVE = 'WeeklyFiveDays';
const GLOBALDAILY = 'GlobalDaily';
const GLOBALWEEKLY = 'GlobalWeekly';

export default Object.freeze({
    [DAILY]: 0,
    [WEEKLYSEVEN]: 1,
    [WEEKLYFIVE]: 2,
    [GLOBALDAILY]: 3,
    [GLOBALWEEKLY]: 4,
});

export const QuotaTypeNames = Object.freeze({
    0: DAILY,
    1: WEEKLYSEVEN,
    2: WEEKLYFIVE,
    3: GLOBALDAILY,
    4: GLOBALWEEKLY,
});
