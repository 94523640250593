import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Search as SearchIcon } from '@material-ui/icons';
import './SearchBar.scss';

/** @param {import('@material-ui/core').TextFieldProps} param0 */
const SearchBar = ({ className, ...props }) => {
    const { t } = useTranslation('common');
    return (
        <TextField
            size="small"
            variant="outlined"
            {...props}
            className={`${className || ''} search`}
            placeholder={t('actions.searchPlaceholder')}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchBar;
