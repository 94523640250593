import { CircularProgress } from '@material-ui/core';
import './Splash.scss';

const Splash = () => (
    <div className="core--splash-screen">
        <CircularProgress color="inherit" />
    </div>
);

export default Splash;
