import showSpinner from '@services/addSpinnerOnQueryStarted';

export const AWARDED_PTO_TAG = 'AwardedPto';
export const AWARDED_PTO_TAGS = [AWARDED_PTO_TAG];

const getUrl = (profileId) => `api/v1/employee/bidProfiles/${profileId}/awardedPto`;
const getAllUrl = (aggregateRootId) => `api/v1/employee/bidPackages/${aggregateRootId}/awardedPtos`;

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number, any, any, Dto.IEmployeeBidProfileAwardingResultPtoSelection>}
 */
const getAwardedPto = (builder) =>
    builder.query({
        query: (profileId) => ({ url: getUrl(profileId), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [AWARDED_PTO_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number, any, any, Dto.IEmployeeBidProfileAwardingResultPtoSelection[]>}
 */
const getAllAwardedPto = (builder) =>
    builder.query({
        query: (aggregateRootId) => ({ url: getAllUrl(aggregateRootId), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [AWARDED_PTO_TAG],
    });

export const endpointsAwardedPto = (builder) => ({
    getAwardedPto: getAwardedPto(builder),
    getAllAwardedPto: getAllAwardedPto(builder),
});
