import { Trans } from 'react-i18next';
import AdditionalInfo from '../AdditionalInfo';
import { ErrorMessage, FormTextField, LabeledComponent } from '../index';
import './RowNumberInput.scss';

/**
 * Returns a component horizontal aligned with label, number input and a help icon
 * @param {{
 *  name: string,
 *  defaultValue: string,
 *  inputProps: {},
 *  label: string,
 *  control: *,
 *  disabled?: boolean,
 *  additionalInformation?: string
 * }} param0
 */
const RowNumberInput = ({
    label,
    control,
    disabled = false,
    additionalInformation = '',
    ...props
}) => (
    <LabeledComponent name={label}>
        <FormTextField
            {...props}
            label=""
            control={control}
            type="number"
            variant="outlined"
            disabled={disabled}
        />
        {additionalInformation && (
            <AdditionalInfo>
                <Trans>
                    {additionalInformation}
                </Trans>
            </AdditionalInfo>
        )}
        <ErrorMessage show={!!props?.error?.message}>
            {props?.error?.message}
        </ErrorMessage>
    </LabeledComponent>
);

export default RowNumberInput;
