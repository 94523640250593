import showSpinner from '@services/addSpinnerOnQueryStarted';
import addRealTimeListener from '@services/addRealTimeListener';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/manager/bidSetups';
const appendToApiPath = appendPath(BASE_PATH);

export const MANAGER_BID_SETUPS_TAG = 'ManagerBidSetups';
export const MANAGER_BID_SETUPS_TAGS = [MANAGER_BID_SETUPS_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IManagerBidSetup[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener('bidSetups', api); },
        providesTags: (result) => (result ? result.map(({ id }) => ({ type: MANAGER_BID_SETUPS_TAG, id })) : [MANAGER_BID_SETUPS_TAG]),
    });

/**
* @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
* @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IManagerBidSetup>}
*/
const get = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: (result, error, id) => [{ type: MANAGER_BID_SETUPS_TAG, id }],
    });

export const endpointsManagerBidSetups = (builder) => ({
    getManagerBidSetups: getAll(builder),
    getManagerBidSetup: get(builder),
});
