import React from 'react';
import './Disclaimer.scss';

const Disclaimer = ({ children, ...others }) => (
    <p {...others}>
        <small className="core--disclaimer">
            {children}
        </small>
    </p>
);

export default Disclaimer;
