import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Avatar, Button, Divider, Menu, MenuItem,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import LightTooltip from '../LightTooltip';

import './UserProfileMenu.scss';

const UserAvatar = ({ initials }) => (
    <Avatar className="user-avatar">
        <span className="text-md-semibold">
            {initials}
        </span>
    </Avatar>
);

const UserMenuSection = ({ title, children }) => (
    <>
        <Divider />
        <div className="user-menu-section">
            <div className="user-menu-subtitle">
                <span className="heading-200">{title}</span>
            </div>
            {children}
        </div>
    </>
);

const UserMenuLanguageItem = ({
    name, language, onClick, selected,
}) => (
    <MenuItem
        className="user-menu-language-item"
        onClick={() => onClick(language)}
    >
        <span className={`text-md-medium${selected ? ' selected' : ''}`}>
            {name}
        </span>
        {selected && (<CheckIcon />)}
    </MenuItem>
);

const UserMenuAboutItem = ({ label, value }) => (
    <div className="user-menu-about-item">
        <span className="text-sm-medium label">{label}</span>
        <span className="text-sm-medium value">{value}</span>
    </div>
);

const UserProfileMenu = ({
    fullName, initials, onChangeLanguage, currentLanguage, configs,
}) => {
    const { appVersion, appEnvironment, isMultiLanguage } = configs;
    const [anchorEl, setAnchorEl] = useState(null);

    const { t } = useTranslation('menu', { keyPrefix: 'userMenu' });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = !!anchorEl;
    const id = open ? 'user-profile-menu' : undefined;

    return (
        <div className="core--user-profile-menu">
            <LightTooltip
                enterDelay={500}
                title={fullName}
            >
                <Button
                    className="user-profile-menu-button"
                    onClick={handleClick}
                >
                    <UserAvatar initials={initials} />
                </Button>
            </LightTooltip>
            <Menu
                id={id}
                open={open}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                onClose={handleClose}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="user-profile-menu--content">
                    <div className="user-profile">
                        <UserAvatar initials={initials} />
                        <span className="text-lg-semibold">{fullName}</span>
                    </div>
                    {isMultiLanguage &&
                        (
                            <UserMenuSection
                                title={t('language.title')}
                            >
                                <UserMenuLanguageItem
                                    name={t('language.english')}
                                    language="en"
                                    onClick={onChangeLanguage}
                                    selected={currentLanguage === 'en'}
                                />
                                <UserMenuLanguageItem
                                    name={t('language.french')}
                                    language="fr"
                                    onClick={onChangeLanguage}
                                    selected={currentLanguage === 'fr'}
                                />
                            </UserMenuSection>
                        )
                    }
                    <UserMenuSection
                        title={t('about')}
                    >
                        <UserMenuAboutItem
                            label={t('version')}
                            value={appVersion}
                        />
                        <UserMenuAboutItem
                            label={t('environment')}
                            value={t(`environment_${appEnvironment}`)}
                        />
                    </UserMenuSection>
                </div>
            </Menu>
        </div>
    );
};

export default UserProfileMenu;
