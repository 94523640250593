import {
    useState, useMemo, useRef, useEffect,
} from 'react';
import {
    Paper, Button, Tooltip, Chip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { faAward as awardIcon } from '@fortawesome/pro-light-svg-icons';
import {
    Add as AddIcon,
    Edit as EditIcon,
    FileCopy as FileCopyIcon,
    DeleteForever as DeleteForeverIcon,
    Settings as SettingsIcon,
    ArrowRightRounded as ArrowRight,
    ArrowDropDownRounded as ArrowDown,
    Visibility as ViewOnlyIcon,
} from '@material-ui/icons';
import { Checkbox, Table } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stringWithLineBreakToHtmlTooltip from '../../services/tooltipPrettify';
import useAvailableHeight from '../../hooks/useAvailableHeight';
import OnClickInterpreter from '../OnClickInterpreter';
import { dynamicSort } from '../../services/sort';
import statusColorMap from '../StatusMultiSelect/statusColorMap.json';
import './TableContent.scss';
import { columnWidthMap } from '../DataviewTable';
import { DataviewValue, TableRsuiteWrapper } from '..';

const { Cell, Column, HeaderCell } = Table;

/** @type {CommonClient2.SortType} */
let defaultSortTypeIfUnset;
// eslint-disable-next-line prefer-const
defaultSortTypeIfUnset = 'asc';

/**
 * @template T
 * @param {{
 *  data: T[];
 *  columns: CommonClient2.TableStaticColumn[];
 *  customActionIcon?: JSX.Element;
 *  customActionIconFixed?: JSX.Element;
 *  customColumnRenderers?: [{ columnName: string, renderCell: (rowData: any) => JSX.Element }];
 *  statusFilterField?: string;
 *  nameColumnKey?: string;
 *  nameColumnChipKey?: string;
 *  defaultSortColumnKey: string;
 *  defaultSortType?: CommonClient2.SortType;
 *  isRowsSelectable?: boolean;
 *  tableId?: string;
 *  onAddRound?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onChange?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onDelete?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onDuplicate?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onEdit?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onManage?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  onNameCellClick?: (event: React.MouseEvent<HTMLElement>, value: T) => void;
 *  fillAvailableHeight?: boolean;
 *  selectedItems: string[];
 *  treeChildrenKey?: string; // to be use with isTree
 *  expandedRowRef?: React.MutableRefObject<null>
 *  isTaskPending?: boolean
 * } & import('rsuite-table').TableProps} param0
 */
const DashboardContent = ({
    data,
    columns,
    customActionIcon,
    customActionIconFixed,
    customColumnRenderers,
    statusFilterField,
    nameColumnKey = 'name',
    nameColumnChipKey = '',
    defaultSortType = defaultSortTypeIfUnset,
    defaultSortColumnKey,
    isRowsSelectable,
    onAddRound,
    onChange,
    onDelete,
    onDuplicate,
    onEdit,
    onManage,
    onNameCellClick,
    onRowClick,
    fillAvailableHeight = false,
    height,
    selectedItems,
    treeChildrenKey,
    expandedRowRef,
    isTaskPending,
    ...others
}) => {
    const ref = useRef(null);
    const [sortType, setSortType] = useState(defaultSortType);
    const [sortColumn, setSortColumn] = useState(defaultSortColumnKey || '');
    const [checkedKeys, setCheckedKeys] = useState(selectedItems || []);

    /** @type {number[]} */
    const initExpandedRows = [];
    const [expandedRows, setExpandedRows] = useState(initExpandedRows);

    const sortKey = sortType === 'desc' ? `-${sortColumn}` : sortColumn;
    const sortedData = useMemo(() => data?.sort(dynamicSort(sortKey)), [JSON.stringify(data), sortType, sortKey]);
    const { t } = useTranslation(['table']);

    /**
     * @param {string} newSortColumn
     * @param {CommonClient2.SortType=} newSortType
     */
    const handleSortColumn = (newSortColumn, newSortType) => {
        setSortColumn(newSortColumn);
        // @ts-ignore
        setSortType(newSortType);
    };

    const onSelectionChange = (keys) => {
        const stringifiedKeys = keys.map((k) => k.toString());
        setCheckedKeys(stringifiedKeys);
        if (onChange) {
            onChange(stringifiedKeys);
        }
    };

    const handleCheckAll = (_, checked) => {
        // @ts-ignore
        const keys = checked ? data.map((item) => item.id) : [];
        onSelectionChange(keys);
    };

    const handleCheck = (value, checked) => {
        const keys = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value.toString());
        onSelectionChange(keys);
    };

    const hasActions = !!(onEdit || onDuplicate || onDelete || onAddRound || onManage || customActionIcon || customActionIconFixed);
    const onAction = onManage || onEdit;

    let rowClick;
    if (!onNameCellClick && (onAction || onRowClick)) {
        rowClick = onRowClick
            ? (value, event) => onRowClick && onRowClick(value, event)
            // @ts-ignore
            : (value, event) => onAction && onAction(event, value);
    }
    const checked = checkedKeys.length === data?.length;
    const indeterminate = !!checkedKeys.length && checkedKeys.length < data?.length;
    const autoHeightValue = useAvailableHeight(ref, fillAvailableHeight);

    const getData = () => {
        if (others.isTree && treeChildrenKey) {
            return sortedData.map((d) => (
                { ...d, children: d[treeChildrenKey] || [] }
            ));
        }

        return sortedData;
    };

    const getTooltipTitle = (rowData, tooltipKey, key) => {
        if (typeof rowData[key] !== 'object') {
            return tooltipKey
                ? stringWithLineBreakToHtmlTooltip(rowData[tooltipKey])
                : <div>{rowData[key]}</div>;
        }

        return JSON.stringify(rowData[key]);
    };

    const isExpandable = !!others?.renderRowExpanded;
    const isSingleExpand = !others?.rowExpandedHeight;

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [rowExpandedHeight, setRowExpandedHeight] = useState(0);

    useEffect(() => {
        // @ts-ignore
        const clientHeight = expandedRowRef?.current?.clientHeight;
        setRowExpandedHeight(clientHeight || 100);
    }, [expandedRowKeys, expandedRowRef]);

    const handleExpanded = (rowData) => {
        let alreadyOpened = false;
        const clickedRowKey = rowData[others?.rowKey || ''];
        const nextExpandedRowKeys = [];
        expandedRowKeys.forEach((key) => {
            if (key === clickedRowKey) {
                alreadyOpened = true;
            } else if (!isSingleExpand) {
                nextExpandedRowKeys.push(key);
            }
        });
        if (!alreadyOpened) {
            nextExpandedRowKeys.push(clickedRowKey);
        }
        // @ts-ignore
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    const renderRowExpanded = (rowData) => {
        // @ts-ignore
        const clientHeight = expandedRowRef?.current?.clientHeight;
        setRowExpandedHeight(clientHeight || 100);
        // @ts-ignore
        return others?.renderRowExpanded(rowData);
    };

    return (
        <Paper
            elevation={2}
            className="core--table-content"
            ref={ref}
        >
            <TableRsuiteWrapper
                data={getData()}
                sortType={sortType}
                sortColumn={sortColumn}
                onSortColumn={handleSortColumn}
                rowClassName="core--table-row"
                shouldUpdateScroll={false}
                height={height || autoHeightValue}
                renderTreeToggle={(icon, rowData) => {
                    if (!rowData?.children || rowData.children.length === 0) {
                        return <></>;
                    }
                    const isRowExpanded = expandedRows.includes(rowData.id);
                    return (isRowExpanded) ? <ArrowDown /> : <ArrowRight />;
                }}
                onExpandChange={(expanded, rowData) => {
                    let newExpandedRows = [];
                    if (expanded) {
                        expandedRows.push(rowData.id);
                        newExpandedRows = expandedRows;
                    } else {
                        newExpandedRows = expandedRows.filter((id) => id !== rowData.id);
                    }
                    setExpandedRows(newExpandedRows);
                }}
                {...others}
                onRowClick={rowClick}
                expandedRowKeys={(!others?.isTree && expandedRowKeys) || undefined}
                rowExpandedHeight={others?.rowExpandedHeight || rowExpandedHeight}
                renderRowExpanded={renderRowExpanded}
            >
                {
                    isExpandable && (
                        <Column
                            width={50}
                            align="center"
                        >
                            <HeaderCell><></></HeaderCell>
                            <Cell>
                                {(rowData) => (
                                    <div onClick={() => { handleExpanded(rowData); }} style={{ cursor: 'pointer' }}>
                                        {
                                            expandedRowKeys?.some((key) => key === rowData[others?.rowKey || ''])
                                                ? (<ArrowDown />)
                                                : (<ArrowRight />)
                                        }
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    )
                }
                {
                    isRowsSelectable && (
                        <Column
                            width={50}
                            align="center"
                        >
                            <HeaderCell className="header-checkbox-cell">
                                <Checkbox
                                    inline
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    onChange={handleCheckAll}
                                />
                            </HeaderCell>
                            <Cell
                                rowData={null}
                                style={{ padding: 0 }}
                            >
                                {(rowData) => (
                                    <div style={{ lineHeight: '46px' }}>
                                        <Checkbox
                                            value={rowData.id}
                                            inline
                                            onChange={handleCheck}
                                            checked={checkedKeys.some((item) => item.toString() === rowData.id.toString())}
                                        />
                                    </div>
                                )}
                            </Cell>
                        </Column>
                    )
                }
                {(columns || []).map(({
                    key, uniqueKey, tKey, dataValueType, isTooltip, tooltipKey, ...rest
                }) => {
                    if (key === statusFilterField) {
                        return null;
                    }
                    const rendered = customColumnRenderers?.find((ccr) => ccr.columnName === uniqueKey || ccr.columnName === key);

                    if (rendered) {
                        return (
                            <Column
                                {...rest}
                                key={key}
                            >
                                <HeaderCell><div className="name-header-cell">{t(tKey)}</div></HeaderCell>
                                <Cell
                                    className="core--status-name-cell"
                                    dataKey={key}
                                >
                                    {(rowData) => rendered.renderCell(rowData)}
                                </Cell>
                            </Column>
                        );
                    }

                    if (key === nameColumnKey) {
                        return (
                            <Column
                                {...rest}
                                key={key}
                            >
                                <HeaderCell><div className="name-header-cell">{t(tKey)}</div></HeaderCell>
                                <Cell
                                    className="core--status-name-cell"
                                    dataKey={key}
                                >
                                    {(rowData) => {
                                        const cellContentClasses = ['core--status-name-cell-content'];
                                        if (others?.isTree) {
                                            if (!rowData.children) {
                                                cellContentClasses.push('cell-content--children-row-margin');
                                            } else if (rowData.children.length === 0) {
                                                cellContentClasses.push('cell-content--no-chldren-row-margin');
                                            }
                                        }
                                        return (
                                            <div
                                                className={cellContentClasses.join(' ')}
                                                // @ts-ignore
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    // @ts-ignore
                                                    onNameCellClick?.(event, rowData);
                                                }}
                                            >
                                                {statusFilterField
                                                    && (
                                                        <Tooltip
                                                            title={rowData[statusFilterField]}
                                                            classes={{ tooltip: 'core--status-tooltip' }}
                                                        >
                                                            <div
                                                                className="core--status-cell"
                                                                style={{ backgroundColor: statusColorMap[rowData[statusFilterField]] }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {rowData?.isAwarded && (
                                                    <div className="award-icon">
                                                        <FontAwesomeIcon
                                                            // @ts-ignore
                                                            icon={awardIcon}
                                                            size="lg"
                                                            color="#00862c"
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className="core--tooltip-cell"
                                                >
                                                    <Tooltip title={getTooltipTitle(rowData, tooltipKey, key)}>
                                                        <span>
                                                            {rowData[key]}
                                                        </span>

                                                    </Tooltip>
                                                    {
                                                        !!nameColumnChipKey
                                                        && rowData[nameColumnChipKey]
                                                        && (
                                                            <Chip
                                                                className="name-columm-chip"
                                                                label={rowData[nameColumnChipKey]}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Cell>
                            </Column>
                        );
                    }

                    if (dataValueType) {
                        return (
                            <Column
                                {...rest}
                                key={key}
                                minWidth={columnWidthMap[dataValueType]}
                            >
                                <HeaderCell>{t(tKey)}</HeaderCell>
                                <Cell dataKey={key}>
                                    {(rowData) => (
                                        <DataviewValue
                                            dataValueType={dataValueType}
                                            value={rowData[key]}
                                        />
                                    )}
                                </Cell>
                            </Column>
                        );
                    }

                    if (isTooltip) {
                        return (
                            <Column
                                {...rest}
                                key={key}
                            >
                                <HeaderCell className="header-cell-container">
                                    <span style={{ flexGrow: 1 }}>{t(tKey)}</span>
                                </HeaderCell>
                                <Cell dataKey={key}>
                                    {(rowData) => (
                                        <div
                                            className="core--tooltip-cell"
                                        >

                                            <Tooltip title={getTooltipTitle(rowData, tooltipKey, key)}>
                                                {
                                                    typeof rowData[key] === 'object'
                                                        ? <span style={{ width: '100%' }}>{JSON.stringify(rowData[key])}</span>
                                                        : <span style={{ width: '100%' }}>{rowData[key]}</span>
                                                }
                                            </Tooltip>
                                        </div>
                                    )}
                                </Cell>
                            </Column>
                        );
                    }

                    return (
                        <Column
                            {...rest}
                            key={key}
                        >
                            <HeaderCell>{t(tKey)}</HeaderCell>
                            <Cell dataKey={key} />
                        </Column>
                    );
                })}
                {hasActions && (
                    <Column
                        minWidth={250}
                        flexGrow={2}
                        fixed="right"
                    >
                        <HeaderCell> </HeaderCell>
                        <Cell>
                            {(rowData) => {
                                const {
                                    isAddRoundDisabled,
                                    isAddRoundDisplayed,
                                    isEditDisabled,
                                    isEditDisplayed = true,
                                    isDuplicateDisabled,
                                    isDeleteDisabled,
                                    isManageDisabled,
                                } = rowData?.actionButtons || {};

                                return (
                                    <>
                                        <div className="core--action-button-container">
                                            {
                                                (!!onAddRound && isAddRoundDisplayed) && (
                                                    <OnClickInterpreter>
                                                        <Tooltip title={t('actions.addRound') || ''}>
                                                            <span>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        // @ts-ignore
                                                                        onAddRound(event, rowData);
                                                                    }}
                                                                    className={
                                                                        // eslint-disable-next-line max-len
                                                                        `core--tablerow-action-button add-round ${isAddRoundDisabled && 'disabled'}`
                                                                    }
                                                                    disabled={isAddRoundDisabled}
                                                                >
                                                                    <AddIcon fontSize="inherit" />
                                                                    <span>{t('actions.addRound')}</span>
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </OnClickInterpreter>
                                                )
                                            }
                                            {
                                                (!!onEdit && isEditDisplayed) && (
                                                    <OnClickInterpreter>
                                                        <Tooltip title={(isEditDisabled ? t('actions.viewOnly') : t('actions.edit')) || ''}>
                                                            <span>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        // @ts-ignore
                                                                        onEdit(event, rowData);
                                                                    }}
                                                                    className="core--tablerow-action-button"
                                                                >
                                                                    {isEditDisabled
                                                                        ? <ViewOnlyIcon fontSize="inherit" />
                                                                        : <EditIcon fontSize="inherit" />}

                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </OnClickInterpreter>
                                                )
                                            }
                                            {
                                                !!onDuplicate && (
                                                    <OnClickInterpreter>
                                                        <Tooltip title={t('actions.duplicate') || ''}>
                                                            <span>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        // @ts-ignore
                                                                        onDuplicate(event, rowData);
                                                                    }}
                                                                    className={
                                                                        `core--tablerow-action-button ${(isDuplicateDisabled || isTaskPending) && 'disabled'}`
                                                                    }
                                                                    disabled={isDuplicateDisabled || isTaskPending}
                                                                >
                                                                    <FileCopyIcon fontSize="inherit" />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </OnClickInterpreter>
                                                )
                                            }
                                            {
                                                !!onDelete && (
                                                    <OnClickInterpreter>
                                                        <Tooltip title={t('actions.delete') || ''}>
                                                            <span>
                                                                <Button
                                                                    onClick={(event) => {
                                                                        // @ts-ignore
                                                                        onDelete(event, rowData);
                                                                    }}
                                                                    className={
                                                                        `core--tablerow-action-button ${isDeleteDisabled && 'disabled'}`
                                                                    }
                                                                    disabled={isDeleteDisabled}
                                                                >
                                                                    <DeleteForeverIcon fontSize="inherit" />
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    </OnClickInterpreter>
                                                )
                                            }
                                            {
                                                !!onManage && (
                                                    <OnClickInterpreter>
                                                        <Button
                                                            onClick={(event) => {
                                                                // @ts-ignore
                                                                onManage(event, rowData);
                                                            }}
                                                            className={`core--tablerow-action-button ${isManageDisabled && 'disabled'}`}
                                                            disabled={isManageDisabled}
                                                        >
                                                            <SettingsIcon fontSize="inherit" />
                                                            <span>{t('actions.manage')}</span>
                                                        </Button>
                                                    </OnClickInterpreter>
                                                )
                                            }
                                            {
                                                !!customActionIcon && (
                                                    <OnClickInterpreter>{customActionIcon}</OnClickInterpreter>
                                                )
                                            }
                                        </div>
                                        {
                                            !!customActionIconFixed && (
                                                <OnClickInterpreter>{customActionIconFixed}</OnClickInterpreter>
                                            )
                                        }
                                    </>
                                );
                            }}
                        </Cell>
                    </Column>
                )}
            </TableRsuiteWrapper>
        </Paper>
    );
};
export default DashboardContent;
