export const onlySelected = ({ selected }) => selected;
export const onlyUnselected = ({ selected }) => !selected;
export const selectItem = (i) => ({ ...i, selected: true });
export const unselectItem = (i) => ({ ...i, selected: false });

export const toggleItemInList = (list, item) => {
    const indexOfItem = list.indexOf(item);
    const updatedCheckedItems = [...list];

    if (indexOfItem === -1) {
        updatedCheckedItems.push(item);
    } else {
        updatedCheckedItems.splice(indexOfItem, 1);
    }
    return updatedCheckedItems;
};
