import { Controller } from 'react-hook-form';
import RadioGroup from '../RadioGroup';

/**
 *
 * @param {import('rsuite').RadioGroupProps & {
 * name: string;
 * control: any;
 * error?: boolean;
 * rules?: any;
 * onChange?: (e)=>{};
 * radioButtons: {value: any, label: string}[]
 * }} param0
 * @returns
 */
const FormRadioGroup = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    radioButtons,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, value, ref,
            },
        }) => (
            <RadioGroup
                name={name}
                radioButtons={radioButtons}
                {...props}
                value={value}
                ref={ref}
                // @ts-ignore
                onChange={(event) => {
                    onChange(event);
                    if (valueChanged) {
                        valueChanged(event);
                    }
                }}
            />
        )}
    />
);

export default FormRadioGroup;
