import './SummaryLine.scss';

/** @param {{ label: string; value: string|JSX.Element }} param0 */
const SummaryLine = ({ label, value }) => {
    if (!value) {
        return <div className="core--summary-line" />;
    }

    return (
        <div className="core--summary-line">
            <span>{`${label}:`}</span>
            <span>{value}</span>
        </div>
    );
};

export default SummaryLine;
