import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/admin/Utilities/notifications';
const appendToApiPath = appendPath(BASE_PATH);
export const NOTIFICATIONS_TAG = 'Notifications';
export const NOTIFICATIONS_TAGS = [NOTIFICATIONS_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.INotification[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [NOTIFICATIONS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.INotification>}
 */
const get = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: (result, error, id) => [{ type: NOTIFICATIONS_TAG, id }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, string[]>}
 */
const getTagKeys = (builder) =>
    builder.query({
        query: () => ({ url: 'api/v1/admin/Utilities/notificationtagkeys', method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

export const endpointsNotifications = (builder) => ({
    getNotifications: getAll(builder),
    getNotification: get(builder),
    getTagKeys: getTagKeys(builder),
});
