import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Search from '@material-ui/icons/Search';

/**
 * @param {{
 *  items: CommonClient2.TransferListItem[];
 *  searchValue: string;
 *  searchValueHandler: React.Dispatch<React.SetStateAction<string>>;
 *  useSelectAll?: boolean;
 *  useSearchBars?: boolean;
 *  disabled?: boolean;
 *  onCheckToggleAll: *;
 *  checkedItemsCount: *;
 * }} param0
 */
const TransferListHeader = ({
    items,
    searchValue,
    searchValueHandler,
    useSelectAll,
    useSearchBars,
    disabled,
    onCheckToggleAll,
    checkedItemsCount,
}) => {
    const isAllChecked = !!checkedItemsCount && checkedItemsCount === items.length;
    const isIndeterminate = !!checkedItemsCount && checkedItemsCount < items?.length;

    return (
        <div className="core--transferList-listHeader">
            <div className="core--transferList-headerActions">
                {useSelectAll && (
                    <div className="core--transferList-checkAll">
                        <Checkbox
                            color="primary"
                            onClick={onCheckToggleAll(items)}
                            checked={isAllChecked}
                            indeterminate={isIndeterminate}
                            disabled={!items.length || disabled}
                            inputProps={{ 'aria-label': 'all items selected' }}
                        />
                    </div>
                )}
                {useSearchBars && (
                    <div className="core--transferList-headerSearch">
                        <TextField
                            id="react-search-input"
                            placeholder="Search..."
                            value={searchValue}
                            onChange={(e) => searchValueHandler(e.target.value)}
                            InputProps={{
                                startAdornment:
                                    (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TransferListHeader;
