import './SummaryEmpty.scss';

/** @param {{ className: string; label: string; }} param0 */
const SummaryEmpty = ({ className, label }) => (
    <div className={`${className || ''} core--summary-empty`}>
        <span>{label}</span>
    </div>
);

export default SummaryEmpty;
