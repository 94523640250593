import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

export const QUERYS_TAG = 'Querys';
const BASE_PATH = 'api/v1/common/ProcessQuery';
const appendToApiPath = appendPath(BASE_PATH);

const processTypeQuery = (builder) =>
    builder.query({
        query: ({ type, payload }) => ({ url: appendToApiPath(type), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

const processQuery = (builder) =>
    builder.query({
        query: (data) => ({ url: BASE_PATH, method: 'POST', data }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

export const queryEndpoints = (builder) => ({
    processTypeQuery: processTypeQuery(builder),
    processQuery: processQuery(builder),
});
