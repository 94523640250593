import { Chip } from '@material-ui/core';
import './StatusChip.scss';
/**
 * @param {{ label: string, statusName: string, className?: string }} param0
 */
const StatusChip = ({ label = '', statusName = '', className }) => (
    label
        ? (
            <Chip
                label={label}
                className={`core--status-chip ${statusName?.toLowerCase() || ''} ${className || ''}`}
            />
        ) : <></>
);
export default StatusChip;
