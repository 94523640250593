import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, TextField, Typography } from '@material-ui/core';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons';

import './RowFilter.scss';

/**
 * @typedef {{
 *  label: string;
 *  options: *[];
 *  value?: *[];
 *  limitTags: number;
 *  getOptionLabel: Function;
 *  onChange: Function;
 * }} RowFilterProps
 * @param {RowFilterProps} param0
 */
const RowFilter = ({
    label,
    options = [],
    value = [],
    limitTags = 1,
    getOptionLabel,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation('common', { keyPrefix: 'rowFilter' });

    const getValuesTag = (values) => {
        const numTags = values?.length;
        const valuesString = values?.slice(0, limitTags).map((option) => getOptionLabel(option)).join(', ');
        const overFlowTags = numTags > limitTags ? `${numTags - limitTags} ${t('more')}` : '';
        return numTags === options?.length
            ? `${t('all')} ${label}`
            : `${valuesString} ${t('plus')} ${overFlowTags}`;
    };

    return (
        <div className="core--row-filter">
            <Autocomplete
                className="row-filter"
                options={options}
                multiple
                value={value}
                limitTags={limitTags}
                disableCloseOnSelect
                getOptionLabel={(option) => getOptionLabel(option)}
                onChange={(event, values) => onChange(values)}
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                            color="primary"
                        />
                        {getOptionLabel(option)}
                    </>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label={label}
                    />
                )}
                renderTags={(values) => (
                    <Typography
                        variant="body2"
                        className="selected-option-label"
                    >
                        {getValuesTag(values)}
                    </Typography>
                )}
                {...rest}
            />
        </div>
    );
};

export default RowFilter;
