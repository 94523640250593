import './PeriodDisplay.scss';
import { getAsShortDate } from '../../services/ukgDateTime';

/**
 * @param {{
 *  start: Date; end: Date; prefix?: string; formatFunction?:(date: Date) => string;
 *  isBidSequential?: boolean;
 * }} param0
 * */
const PeriodDisplay = ({
    start, end, prefix = '', formatFunction = getAsShortDate,
}) => {
    const startLabel = formatFunction(start || new Date());
    const endLabel = formatFunction(end || new Date());
    const rangeLabel = `${startLabel} - ${endLabel}`;

    return (
        <span className="core--period-display">
            {prefix && <span className="prefix">{`${prefix}: `}</span>}
            <span className="range">
                {(!!start && !!end) && rangeLabel}
            </span>
        </span>
    );
};

export default PeriodDisplay;
