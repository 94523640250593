import { useTranslation } from 'react-i18next';
import { getAsLongDateTime } from '../../services/ukgDateTime';
import PeriodDisplay from '../PeriodDisplay';

/**
 * @param {{
 *  start: Date; end:Date; formatFunction?: (date: Date) => string;
 *  isBidSequential?: boolean;
 * }} param0
 * */

const SubmissionsPeriod = ({
    start, end, formatFunction = getAsLongDateTime, isBidSequential = false,
}) => {
    const { t } = useTranslation('bid', { keyPrefix: 'periods' });

    return (
        <div className="submission-period-display">
            <PeriodDisplay
                start={start}
                end={end}
                prefix={t('submissionPeriod')}
                formatFunction={formatFunction}
                isBidSequential={isBidSequential}
            />
        </div>
    );
};

export default SubmissionsPeriod;
