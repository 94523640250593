import { useTranslation } from 'react-i18next';
import {
    CardContent,
    CardMedia,
    Card,
    Button,
    TextField,
    InputLabel,
} from '@material-ui/core';
import Spinner from '../Spinner';

/**
 * @param {{
 *  account: { username: string, password: string };
 *  logo: string;
 *  handleSubmitLogin: import('react').FormEventHandler<HTMLFormElement>;
 *  handleChange: import('react').ChangeEventHandler<HTMLInputElement>;
 *  isSpinnerVisible: boolean,
 * }} param0
 */
const LoginForm = ({
    account, handleSubmitLogin, handleChange, isSpinnerVisible, logo,
}) => {
    const { t } = useTranslation('common');
    return (
        <>
            <div className="login-container">
                <Card className="login-content">
                    <CardMedia
                        component="img"
                        image={logo}
                    />
                    <CardContent className="login-card-content">
                        <p className="login-title-text">{t('Login.title')}</p>
                        <form
                            data-parsley-validate=""
                            onSubmit={handleSubmitLogin}
                            noValidate
                            className="mb-lg"
                        >
                            <div>
                                <InputLabel
                                    className="input-label"
                                    htmlFor="username"
                                >
                                    {t('Login.username')}
                                </InputLabel>
                                <TextField
                                    id="username"
                                    name="username"
                                    type="text"
                                    placeholder={t('Login.username')}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    required
                                    value={account.username}
                                    variant="outlined"
                                />
                            </div>
                            <div>
                                <InputLabel
                                    className="input-label"
                                    htmlFor="password"
                                >
                                    {t('Login.password')}
                                </InputLabel>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder={t('Login.password')}
                                    onChange={handleChange}
                                    required
                                    value={account.password}
                                    variant="outlined"
                                />
                            </div>
                            <Button
                                disabled={isSpinnerVisible}
                                type="submit"
                                className="buttonSubmit"
                            >
                                {t('Login.login')}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
                <span className="copyRights">{t('Login.copyRights')}</span>
                {isSpinnerVisible && (
                    <div className="spinner-container">
                        <Spinner />
                    </div>
                )}
            </div>
        </>

    );
};

export default LoginForm;
