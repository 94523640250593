/** @param {string} x */
const trimSlashes = (x) => x.toString().replace(/^\/+|\/+$/g, '');

const appendPath = (apiPath) => (...args) => [apiPath, ...args].map(trimSlashes).join('/');

/**
 * ex.: {a: 1, b: "one"} returns "?a=1&b=one"
 * @param {Object=} object
 * @returns {string}
 */
export const toQueryString = (object) => {
    if (!object) {
        return '';
    }

    const str = [];
    for (const p in object) {
        if (Object.prototype.hasOwnProperty.call(object, p)) {
            str.push(`${encodeURIComponent(p)}=${encodeURIComponent(object[p])}`);
        }
    }
    return `?${str.join('&')}`;
};

export default appendPath;
