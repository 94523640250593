import { Alert } from '@material-ui/lab';
import './ErrorMessage.scss';

const ErrorMessage = ({ children, show = true, ...others }) => (
    <div className={`core--error-message${show ? ' show' : ''}`}>
        <Alert
            className="alert-error"
            severity="error"
            icon={false}
            {...others}
        >
            <p className="text-sm-semibold">{children}</p>
        </Alert>
    </div>
);

export default ErrorMessage;
