import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/manager/Utilities';
const appendToApiPath = appendPath(BASE_PATH);

const availableQuotaByIdsSelected = (bidSetupId, ids) => {
    let path = appendToApiPath('AvailableAbsenceQuotas', bidSetupId);
    ids.forEach((x, idx) => {
        const char1 = idx === 0 ? '?' : '&';
        path += `${char1}guids[${idx}]=${x}`;
    });
    return path;
};

export const ABSENCE_QUOTA_TAG = 'AbsenceQuota';
export const ABSENCE_QUOTA_BID_SETUP_TAG = 'AbsenceQuotaBidSetup';
export const ABSENCE_QUOTA_TAGS = [ABSENCE_QUOTA_TAG, ABSENCE_QUOTA_BID_SETUP_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, {itemList: VBD.AbsenceQuotaSummaryInfo[]}>}
 */
const getAbsenceQuotas = (builder) =>
    builder.query({
        query: () => ({ url: appendToApiPath('absenceQuotas'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [ABSENCE_QUOTA_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number, any, any, VBD.AbsenceQuotaSummaryInfo[]>}
 */
const getAbsenceQuotasBySetupId = (builder) =>
    builder.query({
        query: (bidSetupId) => ({ url: appendToApiPath('AbsenceQuotasByBidSetupId', bidSetupId), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [ABSENCE_QUOTA_BID_SETUP_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<{bidSetupId: number, ids: string[] }, any, any, VBD.AbsenceQuotaSummaryInfo[]>}
 */
const getNonOverlappingAbsenceQuotas = (builder) =>
    builder.query({
        query: ({ bidSetupId, ids }) => ({ url: availableQuotaByIdsSelected(bidSetupId, ids), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [ABSENCE_QUOTA_TAG],
    });

export const endpointsAbsenceQuotas = (builder) => ({
    getAbsenceQuotas: getAbsenceQuotas(builder),
    getAbsenceQuotasBySetupId: getAbsenceQuotasBySetupId(builder),
    getNonOverlappingAbsenceQuotas: getNonOverlappingAbsenceQuotas(builder),
});
