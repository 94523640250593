import { AppBar, Divider } from '@material-ui/core';
import './AppBar.scss';

/** @param {import('@material-ui/core').AppBarProps} param0 */
const CoreAppBar = ({ children, className, ...props }) => (
    <AppBar
        className={`core--app-bar ${className || ''}`}
        position="sticky"
        {...props}
    >
        {children}
        <Divider />
    </AppBar>
);

export default CoreAppBar;
