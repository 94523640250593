import { Chip } from '@material-ui/core';
import './EventChip.scss';
/**
 * @param {{ label: string, eventName: string, className?: string }} param0
 */
const EventChip = ({ label = '', eventName = '', className }) => (
    <Chip
        label={label || ''}
        className={`core--event-chip ${eventName?.toLowerCase() || ''} ${className || ''}`}
    />
);
export default EventChip;
