import managerBidEditTab from './managerBidEditTab';
import bidSetupSteps from './bidSetupSteps';
import awardingRulesSteps from './awardingRuleSetsSteps';
import managerBidDashboard from './managerBidDashboard';
import employeeBidEdit from './employeeBidEdit';

export default {
    [managerBidEditTab.name]: managerBidEditTab.reducer,
    [bidSetupSteps.name]: bidSetupSteps.reducer,
    [awardingRulesSteps.name]: awardingRulesSteps.reducer,
    [managerBidDashboard.name]: managerBidDashboard.reducer,
    [employeeBidEdit.name]: employeeBidEdit.reducer,
};
