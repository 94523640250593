import { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { Box } from '@material-ui/core';

/**
 * @param {{ value: number }} param0
 */
export const HiddenBox = ({ value }) => <Box style={{ display: 'none' }}>{value}</Box>;

/**
 * To bypass the virtualization issue of rsuite, we need to force a rerender of the DOM after the scroll
 * useage:
 *
 * - const { debounceRender, randomValue } = useVirtualization();
 * - <div>
 * -    <HiddenBox value={randomValue} />
 * -    <Table
 * -     onScroll={debounceRender}
 * -     virtualized
 * -    />
 * - </div>
 *
 *
 * @param {number=} delay
 */
const useVirtualization = (delay) => {
    const [randomValue, setRandomValue] = useState(0);

    const debounceRender = useCallback(
        debounce(() => {
            setRandomValue(Math.random());
        }, delay || 200),
        [debounce],
    );

    return { debounceRender, randomValue };
};

export default useVirtualization;
