import showSpinner from '@services/addSpinnerOnQueryStarted';

const BASE_PATH = 'api/v1/employee/Utilities/ValidateCalendarRules';

export const VALIDATE_CALENDAR_RULES_TAG = 'ValidateCalendarRules';
export const VALIDATE_CALENDAR_RULES_TAGS = [VALIDATE_CALENDAR_RULES_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *  .QueryDefinition<Dto.ICalendarRuleValidationRequest, any, any, Dto.ICalendarRuleValidationResult>
 * }
 */
const validateCalendarRules = (builder) =>
    builder.query({
        query: (validation) => ({ url: BASE_PATH, method: 'POST', data: validation }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [VALIDATE_CALENDAR_RULES_TAG],
    });

export const endpointsValidateCalendarRules = (builder) => ({
    validateCalendarRules: validateCalendarRules(builder),
});
