import React from 'react';
import { Controller } from 'react-hook-form';
import { TagPicker } from 'rsuite';

/**
    @param {
        import('rsuite').TagPickerProps & {
            name: string;
            control: any;
            rules?: any;
            onChange?: any;
            label?: string;
        }
    } param0
 */
const FormSelect = ({
    name,
    onChange: valueChanged,
    rules,
    control,
    label,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onBlur, onChange, value,
            },
        }) => (
            <TagPicker
                {...props}
                onChange={(event, checked) => {
                    onChange(event);
                    if (valueChanged) {
                        valueChanged(event, checked);
                    }
                }}
                onBlur={onBlur}
                value={value}
            />
        )}
    />
);

export default FormSelect;
