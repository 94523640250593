import './ChipCompletedStep.scss';
import { useTranslation } from 'react-i18next';
import { Chip } from '@material-ui/core';

/** @param {import('@material-ui/core').ChipProps} param0 */
const ChipCompletedStep = ({ className, ...props }) => {
    const { t } = useTranslation('common', { keyPrefix: 'components.chipCompletedStep' });
    return (
        <Chip
            className={`core--chip-completed ${className || ''}`}
            label={t('completedLabel')}
            {...props}
        />
    );
};

export default ChipCompletedStep;
