import showSpinner from '@services/addSpinnerOnQueryStarted';
import { EMPLOYEE_BID_PROFILES_TAG, MANAGER_BID_PROFILE_MANAGEMENT_TAG } from '../bidProfiles';
import { SCHEDULE_TAG } from '../schedule';
import { EMPLOYEE_BID_SELECTION_TAG } from './bidSelections.manager';

const BASE_PATH = 'api/v1/employee/BidSelections';

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IBidSelectionSumbitParam, any, any, Dto.IBidSelection>}
 */
const submit = (builder) =>
    builder.mutation({
        query: (bid) => ({ url: `${BASE_PATH}/submit`, method: 'POST', data: bid }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [EMPLOYEE_BID_PROFILES_TAG, SCHEDULE_TAG, MANAGER_BID_PROFILE_MANAGEMENT_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Number, any, any, Dto.IBidSelection[]>}
 */
const profileBidSelections = (builder) =>
    builder.query({
        query: (profileId) => ({ url: `${BASE_PATH}/${profileId}/profileBidSelections`, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [EMPLOYEE_BID_SELECTION_TAG],
    });

const endpointsEmployeeBidSelections = (builder) => ({
    submitEmployeeSelection: submit(builder),
    getProfileBidSelections: profileBidSelections(builder),
});

export default endpointsEmployeeBidSelections;
