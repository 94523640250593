const MANAGER = 'manager';
const EMPLOYEE = 'employee';
const ADMIN = 'admin';

export default Object.freeze({
    [MANAGER]: 0,
    [EMPLOYEE]: 1,
    [ADMIN]: 2,
});

export const AppTypeNames = Object.freeze({
    0: MANAGER,
    1: EMPLOYEE,
    2: ADMIN,
});
