import { Controller } from 'react-hook-form';
import { InputNumber } from 'rsuite';

/**
 *
 * @param {import('rsuite').InputNumberProps & {
 * name: string;
 * control: any;
 * error?: boolean;
 * rules?: any;
 * onChange?: (e)=>{};
 * }} param0
 * @returns
 */
const FormInputNumber = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, value, ref,
            },
        }) => (
            <InputNumber
                {...props}
                value={value}
                ref={ref}
                onChange={(val, event) => {
                    onChange(Number(val), event);
                    if (valueChanged) {
                        valueChanged(val, event);
                    }
                }}
            />
        )}
    />
);

export default FormInputNumber;
