import './TimeDisplay.scss';
import { getCompleteTimeObject } from '../../services/ukgDateTime';

/**
 * @param {{ time: Date }} param0
 */
const TimeDisplay = ({ time }) => {
    const { hour, minute, period } = getCompleteTimeObject(time);
    return (
        <div className="core--time-display">
            <div className="time-frame">
                <label className="time-label">{hour}</label>
            </div>
            <label className="time-label">:</label>
            <div className="time-frame">
                <label className="time-label">{minute}</label>
            </div>
            <div className="time-frame">
                <label className="time-label">{period}</label>
            </div>
        </div>
    );
};

export default TimeDisplay;
