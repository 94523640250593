import { getAsShortDateTime } from '../../services/ukgDateTime';
import './DateDisplay.scss';

/** @param {{ date: Date; prefix?: string; formatFunction?:(date: Date) => string}} param0 */
const DateDisplay = ({
    date, prefix = '', formatFunction = getAsShortDateTime,
}) => (
    <span className="core--date-display">
        {prefix && <span className="prefix">{`${prefix}: `}</span>}
        <span className="date-label">{date && `${formatFunction(date)}`}</span>
    </span>
);

export default DateDisplay;
