/* eslint-disable no-console */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    CardHeader, Card, CardContent, Button, CardActions,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-light-svg-icons';
import axiosInstance from '../../services/api';
import './ErrorBoundary.scss';

let lastError;

/**
 * @param {{message: string, stack: string, componentStack: string}} errorDetails
 */
export const reportError = (errorDetails) => {
    // prevent duplicate errors reporting
    if (errorDetails.message === lastError?.message) {
        return;
    }
    lastError = errorDetails;
    axiosInstance
        .post('api/v1/common/errorReporting', errorDetails)
        .catch((e) => console.error(e));
};

export const reportUnhandledAsyncErrors = () => {
    /**
     * The onunhandledrejection property of the WindowEventHandlers mixin is the EventHandler for processing unhandledrejection events.
     * These events are raised for unhandled Promise rejections.
     * @param {PromiseRejectionEvent} e
     */
    const onUnhandledRejection = (e) => {
        console.error(e.reason);
        reportError({
            message: e.reason?.message || 'Unknown error',
            stack: e.reason?.stack?.toString() || '',
            componentStack: 'Unhandled promise rejection',
        });
    };

    /**
     * The onerror property of the GlobalEventHandlers mixin is an EventHandler that processes error events.
     * @param {string} msg Error message. Available as event in HTML onerror="" handler.
     * @param {string} source URL of the script where the error was raised
     * @param {number} line Line number where error was raised
     * @param {number} col Column number for the line where the error occurred
     * @param {Error} error Error Object
     */
    const onError = (msg, source, line, col, error) => {
        reportError({
            message: error?.message || 'Unknown error',
            stack: error?.stack || '',
            componentStack: `${msg}\nSource: ${source}\nColumn: ${col}\nLine: ${line}`,
        });

        // If you return true, then error alerts (like in older versions of Internet Explorer) will be suppressed.
        const suppressErrorAlert = true;
        return suppressErrorAlert;
    };
    window.onunhandledrejection = onUnhandledRejection;
    // @ts-ignore
    window.onerror = onError;
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError = (error) => ({
        hasError: true,
        error,
    });

    componentDidCatch(error, errorInfo) {
        const errorDetails = {
            message: error.message,
            stack: error.stack,
            componentStack: errorInfo.componentStack,
        };
        console.error(errorDetails);
        reportError(errorDetails);
    }

    render() {
        const { t, children } = this.props;
        const { hasError } = this.state;
        const title = t('errors.generic');
        const pageReload = t('errors.pageReload');
        const workingOnFix = t('errors.workingOnFix');

        if (hasError) {
            return (
                <div className="container">
                    <Card>
                        <CardHeader
                            color="textSecondary"
                            title={title}
                        />
                        <CardContent>
                            <div className="content-container">
                                <FontAwesomeIcon
                                    size="4x"
                                    icon={faTools}
                                />
                                <span>{workingOnFix}</span>
                                <FontAwesomeIcon
                                    size="4x"
                                    icon={faTools}
                                />
                            </div>

                        </CardContent>
                        <CardActions>
                            <Button
                                color="primary"
                                onClick={() => window.location.reload()}
                            >
                                {pageReload}
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            );
        }
        return children;
    }
}

export default withTranslation('common')(ErrorBoundary);
