import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import { Spinner } from 'react-redux-spinner';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary, { reportUnhandledAsyncErrors } from '@commonClient/components/ErrorBoundary';
import authService from '@commonClient/services/auth';
import '@commonClient/services/logger';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store';
import Routes from './routes';
import theme from './theme';
import './mockServer';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-redux-spinner/dist/react-redux-spinner.css';
import './translations/i18n';
import './style.scss';
import '@commonClient/style.scss';
import 'rsuite/dist/rsuite.min.css';

const renderApp = () => {
    /** @type {HTMLElement} */
    const rootElement = document.querySelector('#root') || document.createElement('DIV');
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <ErrorBoundary>
                <PersistGate
                    loading={null}
                    persistor={persistor}
                >
                    <Provider store={store}>
                        <MuiThemeProvider theme={theme}>
                            <Spinner config={{ easing: 'ease', speed: 1000 }} />
                            <Suspense fallback={<div />}>
                                <Router>
                                    <Routes />
                                </Router>
                            </Suspense>
                            <ReduxToastr
                                timeOut={4000}
                                newestOnTop={false}
                                preventDuplicates
                                position="top-center"
                                transitionIn="fadeIn"
                                transitionOut="fadeOut"
                                progressBar
                                closeOnToastrClick
                            />
                        </MuiThemeProvider>
                    </Provider>
                </PersistGate>
            </ErrorBoundary>
        </React.StrictMode>,
    );
};

// Wait for mockserver to load
const mockStartDelay = process.env.REACT_APP_MOCK_ADAPTER_START_DELAY;
const delay = process.env.NODE_ENV === 'development' && mockStartDelay ? Number(mockStartDelay) : 0;
setTimeout(renderApp, delay);
authService.startTokenRefreshInterval();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.debug);
reportUnhandledAsyncErrors();
