import { useState, useMemo } from 'react';
import { addSearchIndex, removeSearchIndex } from '../../services/searchIndexer';
import StatusMultiSelect from '../StatusMultiSelect';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import extractColumns from './extractColumnsFromData';
import './Table.scss';

const unique = (value, index, self) => self.indexOf(value) === index;
/** @type {string[]} */
const initialStatuses = [];

/**
 * @param {CommonClient2.TableComponentProps & import('rsuite-table').TableProps} param0
 * @example <Table
 *               data={bidSetups}
 *               columns={tableColumns}
 *               id="admin-settings-table"
 *               rowClassName="table-row"
 *               autoHeight
 *           />
 */
const Table = ({
    columns,
    createLabel,
    customActionIcon,
    customActionIconFixed,
    customColumnRenderers,
    data,
    dateColumnKeys,
    dateTimeColumnKeys,
    defaultSortColumnKey = '',
    defaultSortType,
    disableSearch = false,
    downloadCsvProps,
    dropdownPlaceholder = '',
    fillAvailableHeight = false,
    hiddenColumns = [],
    isRowsSelectable = false,
    isActionDisabled = false,
    nameColumnChipKey,
    nameColumnKey,
    onAddRound,
    onChange,
    onCreate,
    onDelete,
    onDuplicate,
    onEdit,
    onManage,
    onNameCellClick,
    rowFilterProps,
    statusFilterField,
    selectedItems = [],
    treeChildrenKey,
    useSimpleDropdown = false,
    expandedRowRef,
    isTaskPending,
    ...others
}) => {
    data = data || [];
    const stringifiedData = JSON.stringify(data);
    const [filterTerm, setFilterTerm] = useState('');

    const [selectedStatus, setSelectedStatuses] = useState(initialStatuses);
    const availableStatuses = useMemo(
        () => {
            /** @type {string[]} */
            const allAvailableStatuses = data.map((d) => d[statusFilterField || 'status']).filter(unique);
            return allAvailableStatuses;
        },
        [stringifiedData],
    );

    const indexedData = useMemo(() => data.map((entry) => addSearchIndex(entry)), [stringifiedData]);

    columns = !columns?.length ? extractColumns(data) : columns;
    if (hiddenColumns?.length) {
        columns = columns.filter((c) => !hiddenColumns.includes(c.key));
    }

    if (dateColumnKeys?.length) {
        dateColumnKeys.forEach((dck) => {
            const dateColumn = columns?.find((c) => c.key === dck) || { dataValueType: '' };
            dateColumn.dataValueType = 'DATE';
        });
    }

    if (dateTimeColumnKeys?.length) {
        dateTimeColumnKeys.forEach((dtck) => {
            const dateTimeColumn = columns?.find((c) => c.key === dtck) || { dataValueType: '' };
            dateTimeColumn.dataValueType = 'DATETIME';
        });
    }

    let filteredData = !statusFilterField
        ? indexedData
        : indexedData.filter((i) => selectedStatus.includes(i[statusFilterField]));

    filteredData = !filterTerm
        ? filteredData
        : filteredData.filter((i) => i.searchIndex?.includes(filterTerm.toLocaleLowerCase()));

    const cleanedUpFilteredData = filteredData.map(removeSearchIndex);

    const onSearch = disableSearch
        ? undefined
        : (event, /** @type {string} */ value) => setFilterTerm(value.trim());

    /** @param {string[]} updatedSelectedStatuses */
    const onStatusSelectionChange = (updatedSelectedStatuses) => setSelectedStatuses(updatedSelectedStatuses);

    return (
        <div className={`core--table ${isActionDisabled ? 'loading' : ''}`}>
            <TableHeader
                onSearch={onSearch}
                rowFilterProps={rowFilterProps}
                downloadCsvProps={downloadCsvProps}
                onCreate={onCreate}
                createLabel={createLabel}
                isActionDisabled={isActionDisabled}
            >
                {
                    !statusFilterField
                        ? <></>
                        : (
                            <StatusMultiSelect
                                allStatuses={availableStatuses}
                                onStatusChange={onStatusSelectionChange}
                                useSimpleDropdown={useSimpleDropdown}
                                placeholder={dropdownPlaceholder}
                            />
                        )
                }
            </TableHeader>
            <TableContent
                columns={columns}
                customActionIconFixed={customActionIconFixed}
                customColumnRenderers={customColumnRenderers}
                data={cleanedUpFilteredData}
                defaultSortColumnKey={defaultSortColumnKey}
                defaultSortType={defaultSortType}
                fillAvailableHeight={fillAvailableHeight}
                nameColumnChipKey={nameColumnChipKey}
                nameColumnKey={nameColumnKey}
                isRowsSelectable={isRowsSelectable}
                onAddRound={onAddRound}
                onChange={onChange}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
                onEdit={onEdit}
                onManage={onManage}
                onNameCellClick={onNameCellClick}
                selectedItems={selectedItems}
                statusFilterField={statusFilterField}
                treeChildrenKey={treeChildrenKey}
                expandedRowRef={expandedRowRef}
                isTaskPending={isTaskPending}
                {...others}
            />
        </div>
    );
};

export default Table;
