import { useState } from 'react';

/** @param {boolean=} isInitiallyOpened */
const useDrawer = (isInitiallyOpened = false) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(isInitiallyOpened);
    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);

    return {
        isDrawerOpen,
        openDrawer,
        closeDrawer,
    };
};

export default useDrawer;
