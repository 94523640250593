import { Table as RsuiteTable } from 'rsuite';
import { useVirtualization } from '../../hooks';
import { HiddenBox } from '../../hooks/useVirtualization';

/**
 * @param {import('rsuite-table').TableProps} param0
 */
const VirtualizedTable = ({ virtualized, onScroll, ...others }) => {
    const { debounceRender, randomValue } = useVirtualization();

    return (
        <>
            <HiddenBox value={randomValue} />
            <RsuiteTable
                onScroll={(scrollX, scrollY) => {
                    if (onScroll) {
                        onScroll(scrollX, scrollY);
                    }
                    debounceRender();
                }}
                virtualized={virtualized}
                {...others}
            />
        </>
    );
};

/**
 * The current version of the Rsuite table doesn't virtualize properly,
 * this wrapper intends to bypass the virtualization issue until it's fixed
 * @param {import('rsuite-table').TableProps} param0
 */
const Table = ({ virtualized, ...others }) => (
    virtualized
        ? (
            <VirtualizedTable
                virtualized={virtualized}
                {...others}
            />
        )
        : <RsuiteTable {...others} />
);

export default Table;
