import React from 'react';
import { formatAs } from '../../services/ukgDateTime';
import './CalendarDateDisplay.scss';

/**
 * @param {{ date: Date }} param0
 */
const CalendarDateDisplay = ({ date }) => {
    const year = formatAs(date, 'RRRR');
    const month = formatAs(date, 'MMM');
    const day = formatAs(date, 'do');
    return (
        <div className="core--calendar-date-display-container">
            <div className="core--calendar-date-display">
                <span className="year">{year}</span>
                <span className="month">{month}</span>
                <span className="day">{day}</span>
            </div>
        </div>
    );
};

export default CalendarDateDisplay;
