import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { configurablePendingTasksReducer } from 'react-redux-spinner';
import api from '@apis/api';
import coreApis from '@apis/index';
import coreReducers from './slices';
import crashReporter from './middlewares/crashReporter';

const pendingTasks = configurablePendingTasksReducer({ actionKeyPath: ['meta'] });
const middlewares = [
    thunk,
    crashReporter,
];

const rootReducer = {
    toastr,
    pendingTasks,
    ...coreReducers,
    ...coreApis,
};

// Make sure to blacklist ALL RTQ Queries
const persistConfig = {
    key: 'store',
    storage,
    blacklist: [
        api.reducerPath,
        'pendingTasks',
        'toastr',
    ],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));

// Make sure to concat all middlewares
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(middlewares)
            .concat(thunk)
            .concat(crashReporter)
            .concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
export const persistor = persistStore(store);
