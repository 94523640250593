import showSpinner from '@services/addSpinnerOnQueryStarted';

const BASE_PATH = 'api/v1/admin/Utilities/localePolicies';
export const LOCALE_POLICY_TAG = 'LocalePolicy';
export const LOCALE_POLICY_TAGS = [LOCALE_POLICY_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.ILocalePolicy[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [LOCALE_POLICY_TAG],
    });

export const endpointsLocalePolicy = (builder) => ({
    getLocalePolicies: getAll(builder),
});
