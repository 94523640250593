import React from 'react';
import { Switch, FormControlLabel, FormHelperText } from '@material-ui/core';
import './SwitchWithInfo.scss';

/**
 * @param {import('@material-ui/core').SwitchProps & {
 *   info: string;
 *   label: string;
 *   inputRef?: import('react').RefCallback<SwitchWithInfo>;
 *   onBlur?: any;
 *   onFocus?: any;
 *   color?: 'primary' | 'secondary' | 'default';
 *   checked?: boolean;
 * }} param0
 */
const SwitchWithInfo = ({
    info,
    label,
    inputRef,
    onBlur,
    onChange,
    onFocus,
    checked,
    ...props
}) => {
    const containerClass = info ? { className: 'core--switch-with-info' } : {};

    return (
        <div {...containerClass}>
            <FormControlLabel
                label={label || ''}
                control={(
                    <Switch
                        {...props}
                        inputRef={inputRef}
                        onBlur={onBlur}
                        checked={checked}
                        onChange={onChange}
                    />
                )}
            />

            <FormHelperText className="info">{info}</FormHelperText>
        </div>
    );
};

export default SwitchWithInfo;
