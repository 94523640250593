const HOLIDAY = 'holiday';
const SHIFT = 'shift';
const UKG_PAYCODE = 'ukg-paycode';
const AWARDED_PAYCODE = 'awarded-paycode';
const DAILY_QUOTA = 'daily-quota';
const WEEKLY_QUOTA = 'weekly-quota';

export default Object.freeze({
    HOLIDAY,
    SHIFT,
    UKG_PAYCODE,
    AWARDED_PAYCODE,
    DAILY_QUOTA,
    WEEKLY_QUOTA,
});
