/* eslint-disable no-console */
import { HubConnectionBuilder } from '@microsoft/signalr';
import { toastr } from 'react-redux-toastr';
import combineURLs from 'axios/lib/helpers/combineURLs';

const showToasts = process.env.REACT_APP_LIVE_UPDATE_SHOW_TOASTS === 'true';
const path = process.env.REACT_APP_LIVE_UPDATE_PATH || '';

export const realTimeActions = Object.freeze({
    Created: 'Created',
    Updated: 'Updated',
    Deleted: 'Deleted',
    Scheduled: 'Scheduled',
    Started: 'Started',
    Closed: 'Closed',
});

export const hubs = Object.freeze({
    BidProfileDraft: 'BidProfileDraft',
    BidProfile: 'BidProfile',
    BidSetup: 'BidSetup',
});

/**
 * @returns {import('@microsoft/signalr').HubConnection}
 */
const createLiveUpdateConnection = (endpoint) => {
    if (!path) {
        console.debug('/** ## SignalR ## Missing Path, no connection initiated');
        // @ts-ignore
        return {
            on: () => { },
        };
    }
    const fullPath = combineURLs(path, endpoint);
    console.debug(`/** ## SignalR ## Initializing connection to ${fullPath}.`);

    const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_BACKEND_API}/${path}`)
        .withAutomaticReconnect()
        .build();

    console.debug('/** ## SignalR ## connection', connection);

    connection.onreconnecting((error) => {
        console.debug('/** ## SignalR ## ON RECONNECTING, Error: ', error);
    });

    connection.onreconnected((connectionId) => {
        if (showToasts) {
            toastr.info('Live update', 'Successfully re-established live updates');
        }
        console.debug('/** ## SignalR ## ON RECONNECTED, ConnectionId: ', connectionId);
    });

    connection.onclose((error) => {
        if (showToasts) {
            toastr.warning('Live update', 'Live updates lost, will try to reconnect');
        }
        console.debug('/** ## SignalR ## ON CLOSE, Error: ', error);
    });

    return connection;
};

export default createLiveUpdateConnection;
