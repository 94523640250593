const AVAILABLE = 'Available';
const DRAFT = 'Draft';
const SUBMITTED = 'Submitted';
const CLOSED = 'Closed';

export default Object.freeze({
    [AVAILABLE]: 0,
    [DRAFT]: 1,
    [SUBMITTED]: 2,
    [CLOSED]: 3,
});

export const BidProfileStateNames = Object.freeze({
    0: AVAILABLE,
    1: DRAFT,
    2: SUBMITTED,
    3: CLOSED,
});
