import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';

/**
 * @param {import('@material-ui/core').CheckboxProps
 * & {
 *  name: string;
 *  label: string;
 *  error?: boolean,
 *  control: any,
 *  rules?: import('react-hook-form').RegisterOptions,
 * }} param0
 * @returns
 */
const FormCheckbox = ({
    name,
    control,
    rules,
    onChange: valueChanged,
    label,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, onBlur, value, ref,
            },
        }) => (
            <FormControlLabel
                label={label || ''}
                control={(
                    <Checkbox
                        {...props}
                        checked={value}
                        onBlur={onBlur}
                        ref={ref}
                        onChange={(event, checked) => {
                            onChange(event);
                            if (valueChanged) {
                                valueChanged(event, checked);
                            }
                        }}
                    />
                )}
            />
        )}
    />
);

export default FormCheckbox;
