import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { dynamicSortMultiple } from '../../services/sort';

/**
 * @param {{
 *  width?: number,
 *  items: CommonClient2.TransferListItem[];
 *  search: string;
 *  onCheckToggleItem: *;
 *  disabled?: boolean;
 *  checked: *[];
 * }} param0
 * @returns
 */
const TransferListBody = ({
    width = 300,
    items,
    search,
    onCheckToggleItem,
    disabled,
    checked,
}) => (
    <List
        dense
        component="div"
        role="list"
        className="core--transferList-customList"
        style={{ width }}
    >
        {
            items
                .map((item) => ({
                    isFoundInSearch: item.label?.toLowerCase().includes(search?.toLowerCase()),
                    item,
                }))
                .sort(dynamicSortMultiple('-isFoundInSearch', 'label'))
                .map(({ item, isFoundInSearch }) => {
                    const labelId = `transfer-list-item-${item.id}-${item.label}-label`;
                    return (
                        <ListItem
                            key={`list_item_transfer_${item.id}-${item.label}`}
                            className="core--transferList-listItemElement"
                            role="listitem"
                            onClick={onCheckToggleItem(item)}
                            disabled={disabled}
                            button
                        >
                            <ListItemIcon>
                                <Checkbox
                                    color="primary"
                                    checked={checked.indexOf(item) !== -1}
                                    disabled={disabled}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                className={isFoundInSearch
                                    ? 'core--transferList-itemFoundInSearch' : 'core--transferList-itemNotFoundInSearch'}
                                primary={item.label}
                                secondary={item.secondaryLabel}
                            />
                        </ListItem>
                    );
                })
        }
        <ListItem />
    </List>
);

export default TransferListBody;
