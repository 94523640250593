const SHIFT = 'Shift';
const QUOTA = 'Quota';
const UKGPAYCODES = 'ukg-paycode';
const HOLIDAY = 'holiday';

export default Object.freeze({
    [SHIFT]: 0,
    [QUOTA]: 1,
    [UKGPAYCODES]: 2,
    [HOLIDAY]: 3,
});

export const BidShiftTypeName = Object.freeze({
    0: SHIFT,
    1: QUOTA,
    2: UKGPAYCODES,
    3: HOLIDAY,
});
