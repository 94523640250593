import {
    Accordion, AccordionSummary, Avatar, Typography, AccordionDetails, Divider, AccordionActions, Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import './AccordionEdit.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * @param {{
 *  stepIndex: string;
 *  summary: string|React.ReactElement<any, any>;
 *  headerTitle: string;
 *  subHeader: string;
 *  subHeaderContent: string;
 *  onDiscard: () => void;
 *  onApply: () => void;
 *  formId: string;
 *  children: any;
 *  isDirty: boolean;
 *  hasErrors: boolean;
 *  isActionsDisabled: boolean;
 *  expanded: boolean;
 *  onAccordionChange: *;
 * }} param0
 */
const AccordionEdit = ({
    stepIndex,
    summary,
    headerTitle,
    subHeader,
    subHeaderContent,
    onDiscard,
    onApply,
    formId,
    children,
    isDirty,
    hasErrors,
    isActionsDisabled,
    expanded,
    onAccordionChange,
}) => {
    const { t } = useTranslation('common');
    return (
        <Accordion
            expanded={expanded}
            onChange={onAccordionChange}
            className="accordion-edit"
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className="accordion-edit--header-main">
                    <Avatar className="accordion-edit--header-avatar">{stepIndex}</Avatar>
                    <Typography variant="h6">{headerTitle}</Typography>
                </div>
                <Typography className="accordion-edit--header-summary">{summary}</Typography>
            </AccordionSummary>
            <div className="sub-header">
                <Typography variant="h6">{subHeader}</Typography>
                <Typography variant="subtitle1">{subHeaderContent}</Typography>
            </div>
            <AccordionDetails>
                <Divider />
                <div className="accordion-edit--details">
                    {children}
                </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onDiscard}
                    disabled={!isDirty || isActionsDisabled}
                >
                    {t('actions.discard')}
                </Button>
                <Button
                    className="apply-button"
                    form={formId}
                    onClick={onApply}
                    color="primary"
                    variant="contained"
                    disabled={!isDirty || hasErrors || isActionsDisabled}
                >
                    <FontAwesomeIcon icon={faCheckCircle} />
                    {t('actions.saveAndContinue')}
                </Button>
            </AccordionActions>
        </Accordion>
    );
};
export default AccordionEdit;
