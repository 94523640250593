import showSpinner from '@services/addSpinnerOnQueryStarted';

export const COMMANDS_TAG = 'Commands';
const BASE_PATH = 'api/v1/common/SendCommands';

const sendCommands = (builder) =>
    builder.query({
        query: (data) => ({ url: BASE_PATH, method: 'POST', data }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

export const commandsEndpoints = (builder) => ({
    sendCommands: sendCommands(builder),
});
