import { pendingTask, begin, end } from 'react-redux-spinner';

const dummyLoadingStartAction = {
    type: 'LOADING_ACTION_START',
    meta: {
        [pendingTask]: begin,
    },
};

const dummyLoadingEndAction = {
    type: 'LOADING_ACTION_COMPLETE',
    meta: {
        [pendingTask]: end,
    },
};

/**
 * @param {{
 *      dispatch: import('redux-thunk').ThunkDispatch<any, any, any>,
 *      queryFulfilled: import('@reduxjs/toolkit/dist/query/core/buildMiddleware/types').PromiseWithKnownReason<any, any>,
 *  }} api
 */
const onQueryStarted = async (api) => {
    api.dispatch(dummyLoadingStartAction);
    try {
        await api.queryFulfilled;
        api.dispatch(dummyLoadingEndAction);
    } catch (error) {
        api.dispatch(dummyLoadingEndAction);
    }
};

export default onQueryStarted;
