import { useTranslation } from 'react-i18next';
import { getAsLongDateTime, getAsLongDate } from '../../services/ukgDateTime';
import LabeledControl from '../LabeledComponent';
import './BidPeriods.scss';

/**
 * @param {{
    * requestPeriodStartDate: Date;
    * requestPeriodEndDate: Date;
    * submissionPeriodStartDate: Date;
    * submissionPeriodEndDate: Date;
    * priorityStart?: Date;
    * priorityEnd?: Date;
    * }} param0
 */
const BidPeriods = ({
    requestPeriodStartDate,
    requestPeriodEndDate,
    submissionPeriodStartDate,
    submissionPeriodEndDate,
    priorityStart,
    priorityEnd,

}) => {
    const { t } = useTranslation('bid', { keyPrefix: 'periods' });

    return (
        <div className="bid-periods">
            {priorityStart && priorityEnd && (
                <>
                    <LabeledControl name={`${t('priorityStart')}: `}>{getAsLongDateTime(priorityStart)}</LabeledControl>
                    <LabeledControl name={`${t('priorityEnd')}: `}>{getAsLongDateTime(priorityEnd)}</LabeledControl>
                </>
            )}
            <LabeledControl name={`${t('submissionStart')}: `}>{getAsLongDateTime(submissionPeriodStartDate)}</LabeledControl>
            <LabeledControl name={`${t('submissionEnd')}: `}>{getAsLongDateTime(submissionPeriodEndDate)}</LabeledControl>
            <LabeledControl name={`${t('request')}: `}>
                {`${getAsLongDate(requestPeriodStartDate)} ${t('seperator.to')} ${getAsLongDate(requestPeriodEndDate)}`}
            </LabeledControl>
        </div>
    );
};

export default BidPeriods;
