import { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import './DateRangePicker.scss';
import isPeriodRangeEqual from '../../services/periods';

const today = new Date();
today.setHours(0, 0, 0, 0);

/** @type {import('rsuite/cjs/DateRangePicker/types').RangeType} */
const defaultValue = { label: '', value: [] };

/**
 * This component is for RSuite v5
 * @param {import('rsuite/DateRangePicker').DateRangePickerProps & {
 *  selectedPeriod?: import('rsuite/cjs/DateRangePicker/types').RangeType
 *  hasError?: boolean,
 * }} props
 * @returns
 */
const DateRangePickerWrapper = ({
    selectedPeriod, onChange, size, ranges, hasError, character, ...others
}) => {
    const [isInitialized, setInitialized] = useState(false);
    const [selectedRange, setSelectedRange] = useState(defaultValue);

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        if (selectedPeriod?.value) {
            setSelectedRange(selectedPeriod);
            setInitialized(true);
        }
    });

    const onSelectionChange = (
        /** @type {import('rsuite/cjs/DateRangePicker/types').ValueType} */ value,
        /** @type {React.SyntheticEvent<Element, Event>} */ event,
    ) => {
        const updatedValue = { ...defaultValue, value };
        if (!value.length || !ranges?.length) {
            setSelectedRange(defaultValue);
        } else {
            // @ts-ignore
            const updatedSelectedRange = ranges?.find((range) => isPeriodRangeEqual(range.value, value));
            setSelectedRange(updatedSelectedRange || updatedValue);
        }

        if (onChange) {
            onChange(value, event);
        }
    };

    return (
        <div className={`rsuite-v5-date-range-picker-wrapper ${hasError ? 'rsuite-v5-date-range-picker-wrapper-error' : ''}`}>
            <div className={`selected-label ${selectedRange?.label ? '' : 'hidden'}`}>
                <span>{selectedRange?.label}</span>
            </div>
            <DateRangePicker
                menuClassName={ranges?.length ? 'rsuite-date-range-picker-with-ranges' : ''}
                ranges={ranges}
                size={size || 'lg'}
                character={character || ' - '}
                onChange={onSelectionChange}
                // @ts-ignore
                value={selectedRange.value}
                {...others}
            />
        </div>
    );
};

export default DateRangePickerWrapper;
