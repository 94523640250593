import { get } from 'lodash';
/**
 * Dynamic sort function that sorts objects by the given prop name
 * @see https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
 *
 * @example
 * const result = people.sort(dynamicSort("Name"));  // Sort by "Name" ascending
 * const result = people.sort(dynamicSort("Surname")); // Sort by "Surname" ascending
 * const result = people.sort(dynamicSort("-Surname")); // Sort by "Surname" descending
 * const result = people.sort(dynamicSort("Pet.Name")); // Sort by sub-property ["Pet"]["Name"] ascending
 *
 * @note always keeps falsy values at the end of the list eg. [null, 0, 1, null] => [0, 1, null, null] or [1, 0, null, null]
 * @param {string} propName
 */
export const dynamicSort = (propName) => {
    let sortOrder = 1;
    if (propName[0] === '-') {
        sortOrder = -1;
        propName = propName.substr(1);
    }
    /**
     * @param {object} obj1
     * @param {object} obj2
     */
    const sortFunction = (obj1, obj2) => {
        const val1 = get(obj1, propName, null);
        const val2 = get(obj2, propName, null);
        if (val1 === null && val2 === null) {
            return 0;
        }
        if (val1 === null) {
            return 1;
        }
        if (val2 === null) {
            return -1;
        }

        const sortStrategy = (typeof val1 === typeof val2 && typeof val1 === 'string')
            ? (a, b) => a.localeCompare(b)
            // eslint-disable-next-line no-nested-ternary
            : (a, b) => ((a < b) ? -1 : (a > b) ? 1 : 0);

        const result = sortStrategy(val1, val2);
        return result * sortOrder;
    };

    return sortFunction;
};

/**
 * Generates sort functions for objects with multiple sort parameters, works with numbers and strings
 * @see https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
 *
 * @example
 * // Sort by name then by surname descending, Given object example { name: "John Doe", surname: "JD"}
 * const result = someList.sort(dynamicSortMultiple("Name", "-Surname"));
 *
 * @param  {...string} args - Name of properties to sort by
 */
export const dynamicSortMultiple = (...args) => {
    const props = args;

    /**
     * @param {object} obj1
     * @param {object} obj2
     */
    const sortFunction = (obj1, obj2) => {
        let i = 0;
        let result = 0;
        const numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    };
    return sortFunction;
};
