import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '@commonClient/services/axiosBaseQuery';
import { CONFIGS_TAGS, endpointsAppConfigs } from '@apis/appConfigs';
import { ABSENCE_QUOTA_TAGS, endpointsAbsenceQuotas } from '@apis/AbsenceQuotas';
import endpointsAbsenceThresholdSources from '@apis/absenceThresholdSources';
import { ACCRUAL_PROFILES_TAGS, endpointsAccrualProfiles } from '@apis/accrualProfiles';
import { ACCRUAL_BALANCES_TAGS, endpointsAccrualBalances } from '@apis/accruals';
import { AUDIT_TAGS, endpointsAudit } from '@apis/audit';
import { AWARDING_RULE_SETS_TAGS, endpointsAwardingRuleSets } from '@apis/awardingRuleSets';
import { AWARDED_PTO_TAGS, endpointsAwardedPto } from '@apis/awardedPtos';
import {
    EMPLOYEE_BID_PROFILES_TAGS, MANAGER_BID_PROFILES_TAGS, endpointsEmployeeBidProfiles, endpointsManagerBidProfiles,
} from '@apis/bidProfiles';
import {
    BID_SELECTIONS_TAGS, endpointsDraftBidSelections, endpointsEmployeeBidSelections, endpointsManagerBidSelections,
} from '@apis/bidSelections';
import {
    ADMIN_BID_SETUPS_TAGS, MANAGER_BID_SETUPS_TAGS, endpointsAdminBidSetups, endpointsManagerBidSetups,
} from '@apis/bidSetups';
import { commandEndpoints, commandsEndpoints, queryEndpoints } from '@apis/cqsEndpoints';
import { DATASETS_TAGS, endpointsDataSets } from '@apis/dataSets';
import { EMPLOYEE_GROUP_TAGS, endpointsEmployeeGroups } from '@apis/EmployeeGroups';
import { GLOBAL_SETUP_TAGS, endpointsGlobalSetup } from '@apis/globalSetup';
import { LOCALE_POLICY_TAGS, endpointsLocalePolicy } from '@apis/localePolicy';
import { NOTIFICATIONS_TAGS, endpointsNotifications } from '@apis/notifications';
import { REQUEST_SUBTYPES_TAGS, endpointsRequestSubtypes } from '@apis/requestSubtype';
import { SCHEDULE_TAGS, endpointsSchedule } from '@apis/schedule';
import endpointsScheduleDataItems from '@apis/scheduleDataItem';
import { SYMBOLIC_PERIOD_TAGS, endpointsSymbolicPeriods } from '@apis/symbolicPeriod';
import { TEAM_DEFINITIONS_TAGS, endpointsTeamDefinitions } from '@apis/teamDefinitions';
import { UTILITIES_TAGS, endpointsManagerUtilities, endpointsEmployeeUtilities } from '@apis/utilities';
import { VALIDATE_CALENDAR_RULES_TAGS, endpointsValidateCalendarRules } from '@apis/validateCalendarRules';

const api = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery,
    tagTypes: [
        ...ACCRUAL_PROFILES_TAGS,
        ...ABSENCE_QUOTA_TAGS,
        ...ACCRUAL_BALANCES_TAGS,
        ...ADMIN_BID_SETUPS_TAGS,
        ...AUDIT_TAGS,
        ...AWARDING_RULE_SETS_TAGS,
        ...AWARDED_PTO_TAGS,
        ...BID_SELECTIONS_TAGS,
        ...CONFIGS_TAGS,
        ...DATASETS_TAGS,
        ...EMPLOYEE_BID_PROFILES_TAGS,
        ...EMPLOYEE_GROUP_TAGS,
        ...GLOBAL_SETUP_TAGS,
        ...LOCALE_POLICY_TAGS,
        ...MANAGER_BID_PROFILES_TAGS,
        ...MANAGER_BID_SETUPS_TAGS,
        ...NOTIFICATIONS_TAGS,
        ...REQUEST_SUBTYPES_TAGS,
        ...SCHEDULE_TAGS,
        ...SYMBOLIC_PERIOD_TAGS,
        ...TEAM_DEFINITIONS_TAGS,
        ...UTILITIES_TAGS,
        ...VALIDATE_CALENDAR_RULES_TAGS,
    ],
    endpoints: (builder) => ({
        ...endpointsAbsenceQuotas(builder),
        ...endpointsAbsenceThresholdSources(builder),
        ...endpointsAccrualProfiles(builder),
        ...endpointsAccrualBalances(builder),
        ...endpointsAudit(builder),
        ...endpointsAwardingRuleSets(builder),
        ...endpointsAwardedPto(builder),
        ...commandEndpoints(builder),
        ...commandsEndpoints(builder),
        ...queryEndpoints(builder),
        ...endpointsEmployeeBidProfiles(builder),
        ...endpointsManagerBidProfiles(builder),
        ...endpointsDraftBidSelections(builder),
        ...endpointsEmployeeBidSelections(builder),
        ...endpointsGlobalSetup(builder),
        ...endpointsManagerBidSelections(builder),
        ...endpointsAdminBidSetups(builder),
        ...endpointsManagerBidSetups(builder),
        ...endpointsDataSets(builder),
        ...endpointsEmployeeGroups(builder),
        ...endpointsNotifications(builder),
        ...endpointsSchedule(builder),
        ...endpointsSymbolicPeriods(builder),
        ...endpointsTeamDefinitions(builder),
        ...endpointsManagerUtilities(builder),
        ...endpointsEmployeeUtilities(builder),
        ...endpointsSymbolicPeriods(builder),
        ...endpointsValidateCalendarRules(builder),
        ...endpointsAppConfigs(builder),
        ...endpointsRequestSubtypes(builder),
        ...endpointsLocalePolicy(builder),
        ...endpointsScheduleDataItems(builder),
    }),
});

export default api;
