import showSpinner from '@services/addSpinnerOnQueryStarted';
import addRealTimeListener from '@services/addRealTimeListener';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/admin/awardingRuleSets';
const appendToApiPath = appendPath(BASE_PATH);

export const AWARDING_RULE_SETS_TAG = 'AwardingRules';
export const CUSTOM_DATE_FORMAT_TAG = 'CustomDateFormat';
export const AWARDING_RULE_SETS_TAGS = [AWARDING_RULE_SETS_TAG, CUSTOM_DATE_FORMAT_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IAwardingRuleSet[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener('awardingRuleSets', api); },
        providesTags: [AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IAwardingRuleSet>}
 */
const get = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: (result, error, id) => [{ type: AWARDING_RULE_SETS_TAG, id }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAwardingRuleSet, any, any, Dto.IAwardingRuleSet>}
 */
const create = (builder) =>
    builder.mutation({
        query: (awardingRuleSet) => ({ url: BASE_PATH, method: 'POST', data: awardingRuleSet }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAwardingRuleSet, any, any, Dto.IAwardingRuleSet>}
 */
const update = (builder) =>
    builder.mutation({
        query: (awardingRuleSet) => ({ url: appendToApiPath(awardingRuleSet.id), method: 'PUT', data: awardingRuleSet }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IAwardingRuleSet, any, any, void>}
 */
const deleteRuleSet = (builder) =>
    builder.mutation({
        query: (id) => ({ url: appendToApiPath(id), method: 'DELETE' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [AWARDING_RULE_SETS_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *  .QueryDefinition<any, any, any, [Dto.ICustomDateFormat, Dto.ICustomDateFormat, Dto.ICustomDateFormat]>}
 */
const getCustomDateFormats = (builder) =>
    builder.query({
        query: () => ({ url: appendToApiPath('CustomDateFormat'), method: 'GET' }),
        async onQueryStarted(_, api) { await showSpinner(api); },
        providesTags: [CUSTOM_DATE_FORMAT_TAG],
    });

export const endpointsAwardingRuleSets = (builder) => ({
    getAwardingRuleSets: getAll(builder),
    getAwardingRuleSet: get(builder),
    createAwardingRuleSet: create(builder),
    updateAwardingRuleSet: update(builder),
    deleteAwardingRuleSet: deleteRuleSet(builder),
    getCustomDateFormats: getCustomDateFormats(builder),
});
