import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';
import { EMPLOYEE_BID_PROFILES_TAGS, MANAGER_BID_PROFILE_MANAGEMENT_TAG, MANAGER_BID_PROFILES_TAG } from '../bidProfiles/index';
import { SCHEDULE_TAG } from '../schedule';

const BASE_PATH = 'api/v1/manager/BidSelections';
const appendToApiPath = appendPath(BASE_PATH);
export const MANAGER_BID_SELECTION_TAG = 'ManagerBidSelection';
export const MANAGER_BID_SELECTION_CSV_TAG = 'ManagerBidSelectionCSV';
export const EMPLOYEE_BID_SELECTION_TAG = 'EmployeeBidSelection';
export const BID_SELECTIONS_TAGS = [MANAGER_BID_SELECTION_TAG, EMPLOYEE_BID_SELECTION_TAG];

/** @typedef {{bidProfileId: number; bidSelectionIds: number[]}} Selection  */

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 // eslint-disable-next-line max-len
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Selection, any, any, Dto.IManagerApproveSelection>}
 */
const approve = (builder) =>
    builder.mutation({
        query: (payload) => ({ url: appendToApiPath('approveBidSelections'), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_SELECTION_TAG, EMPLOYEE_BID_SELECTION_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Selection, any, any, Dto.IManagerRefuseSelection>}
 */
const refuse = (builder) =>
    builder.mutation({
        query: (payload) => ({ url: appendToApiPath('refuseBidSelections'), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_SELECTION_TAG, EMPLOYEE_BID_SELECTION_TAG, SCHEDULE_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Selection, any, any, Dto.IManagerUnawardSelection>}
 */
const unaward = (builder) =>
    builder.mutation({
        query: (bidSelectionIds) => ({ url: appendToApiPath('unawardBidSelections'), method: 'POST', data: bidSelectionIds }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_SELECTION_TAG, EMPLOYEE_BID_SELECTION_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IBidSelectionSumbitOnBehalfParam, any, any, Dto.IBidSelection>}
 */
const submitOnBehalf = (builder) =>
    builder.mutation({
        query: (payload) => ({ url: appendToApiPath('bidOnBehalfOfEmployee'), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILE_MANAGEMENT_TAG, ...EMPLOYEE_BID_PROFILES_TAGS, SCHEDULE_TAG, MANAGER_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *      .MutationDefinition<number|Dto.IDeclineBidProfilePayload, any, any, VBD.IEmployeeBidProfile>}
     */
const declineOnBehalf = (builder) =>
    builder.mutation({
        query: (payload) => ({ url: appendToApiPath('declineOnBehalf'), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [MANAGER_BID_PROFILE_MANAGEMENT_TAG, ...EMPLOYEE_BID_PROFILES_TAGS, SCHEDULE_TAG, MANAGER_BID_PROFILES_TAG],
    });

const generateCsv = (builder) =>
    builder.query({
        query: (params) => ({ url: appendToApiPath('EmployeesSelectionsCsv'), method: 'POST', data: params }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [MANAGER_BID_SELECTION_CSV_TAG],
    });

export const endpointsManagerBidSelections = (builder) => ({
    managerApproveSelections: approve(builder),
    managerRefuseSelections: refuse(builder),
    managerUnawardSelections: unaward(builder),
    declineOnBehalf: declineOnBehalf(builder),
    managerDownloadEmployeesSelections: generateCsv(builder),
    managerSubmitSelectionOnBehalf: submitOnBehalf(builder),
});
