import { FormSwitch } from '../index';

const SwitchControlSection = ({
    label,
    name,
    control,
    ...props
}) => (
    <>
        <FormSwitch
            {...props}
            name={name}
            label={label}
            control={control}
            color="primary"
        />
        <div>
            {props.children}
        </div>
    </>
);

export default SwitchControlSection;
