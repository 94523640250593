export const MANAGER_BID_PROFILES_TAG = 'ManagerBidProfiles';
export const MANAGER_ARCHIVED_BID_PROFILES_TAG = 'ManagerArchivedBidProfiles';
export const MANAGER_BID_PROFILE_NAME_VALIDATION_TAG = 'ManagerBidProfilesNamesValidation';
export const MANAGER_BID_PROFILE_MANAGEMENT_TAG = 'ManagerBidProfileManagement';
export const MANAGER_BID_PROFILES_TAGS = [
    MANAGER_BID_PROFILES_TAG,
    MANAGER_BID_PROFILE_MANAGEMENT_TAG,
    MANAGER_ARCHIVED_BID_PROFILES_TAG,
    MANAGER_BID_PROFILE_NAME_VALIDATION_TAG,
];

export const EMPLOYEE_BID_PROFILES_TAG = 'EmployeeBidProfiles';
export const EMPLOYEE_BID_PROFILES_ARCHIVED_TAG = 'EmployeeBidProfilesArchived';
export const EMPLOYEE_BID_PROFILES_TAGS = [EMPLOYEE_BID_PROFILES_TAG, EMPLOYEE_BID_PROFILES_ARCHIVED_TAG];
