import './ShiftToolTip.scss';
import { Tooltip } from '@material-ui/core';
import { SwapHoriz } from '@material-ui/icons';
import { SegmentType } from '../../enums/index';

/**
 * @param {{ id: any }} x
 * @param {number} i
 */
function getKey(x, i) {
    return `${x.id}-${i}`;
}
/**
 * @param {CommonClient2.ShiftTooltipProps} props
 * @returns
 */
const ShiftToolTip = ({ element, shiftInfos }) => ( // TODO change element to children
    <Tooltip
        arrow
        enterDelay={500}
        enterNextDelay={500}
        classes={{
            popper: 'ShiftTooltip',
        }}
        placement="top"
        title={(
            <div className="ShiftTooltipContent">
                <div className="ShiftToolTipShiftSection">
                    <div className="ShiftTooltipShiftBlueBox">Shift</div>
                    {
                        shiftInfos.date ? (
                            <div className="ShiftTooltipShiftDate">{shiftInfos.date}</div>
                        ) : null
                    }
                    <div className="ShiftTooltipShiftTime">{shiftInfos.timeString}</div>
                </div>
                {
                    shiftInfos.segments.map((x, i) => (
                        <div
                            key={`ShiftToolTipSegmentsSection_${shiftInfos.id}_${x.id}`}
                            className="ShiftToolTipSegmentsSection"
                        >
                            <div className="ShiftTooltipSegmentDate">
                                <span className="ShiftTooltipSegmentIndex">{i + 1}</span>
                                <span className="ShiftTooltipSegmentTime">{x.timeString}</span>
                                {x.segmentType === SegmentType.TRANSFER_SEGMENT && <SwapHoriz />}
                                {x.segmentType === SegmentType.BREAK_SEGMENT && ' (Break)'}
                            </div>
                            {
                                x.segmentType === SegmentType.REGULAR_SEGMENT && (
                                    <div className="ShiftTooltipSegmentLocation">{x.orgJobPath}</div>
                                )
                            }
                            {
                                x.segmentType === SegmentType.TRANSFER_SEGMENT && x.transferStrings?.map((value, index) => (
                                    <div
                                        className="ShiftTooltipSegmentLocation"
                                        key={getKey(x, index)}
                                    >
                                        {value}
                                    </div>
                                ))
                            }
                            {
                                x.skillAndCertifProfileLabels && x.skillAndCertifProfileLabels.length && (
                                    <div className="ShiftTooltipSegmentScProfiles-container">
                                        <div>Skill and Certification Profiles</div>
                                        {x.skillAndCertifProfileLabels.map(x => (<div>{x}</div>))}
                                    </div>
                                )
                            }
                        </div>
                    ))
                }
            </div>
        )}
    >
        {
            element
        }
    </Tooltip>
);

export default ShiftToolTip;
