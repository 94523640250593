import { debounce } from 'lodash';
import { useMemo } from 'react';

/**
 * @template T
 * @param {T} func
 * @param {number=} delay Default 350ms
 */
const useDebouncedFunction = (func, delay = 350) =>
    // @ts-ignore
    useMemo(() => debounce(func, delay), [func, delay]);

export default useDebouncedFunction;
