import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    informationStatus: 'wait',
    accessProfileStatus: 'wait',
    displayStatus: 'wait',
    paycodeAccrualsStatus: 'wait',
    processStatus: 'wait',
    bidRulesStatus: 'wait',
};

const sliceName = 'bidSetupSteps';

const bidSetupStepsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        createBidSetupSteps: () => ({ ...initialState }),
        editBidSetupSteps: (_, action) => ({ ...action.payload }),
        setInformationStep: (state, action) => { state.informationStatus = action.payload; },
        setAccessProfileStep: (state, action) => { state.accessProfileStatus = action.payload; },
        setDisplayStep: (state, action) => { state.displayStatus = action.payload; },
        setPayCodesAccrualStep: (state, action) => { state.paycodeAccrualsStatus = action.payload; },
        setProcessStep: (state, action) => { state.processStatus = action.payload; },
        setBidRulesStep: (state, action) => { state.bidRulesStatus = action.payload; },
    },
});

export const informationStepStatusSelector = (state) => state[sliceName].informationStatus;
export const accessProfileStepStatusSelector = (state) => state[sliceName].accessProfileStatus;
export const displayStepStatusSelector = (state) => state[sliceName].displayStatus;
export const payCodesAccrualStepStatusSelector = (state) => state[sliceName].paycodeAccrualsStatus;
export const processStepStatusSelector = (state) => state[sliceName].processStatus;
export const bidRulesStepStatusSelector = (state) => state[sliceName].bidRulesStatus;
export default bidSetupStepsSlice;
