import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath, { toQueryString } from '@services/path';

import {
    applyUserTimeZoneConversionMapping,
    removeBrowserTimeZoneConversionMapping,
} from '../bidProfiles/bidProfiles.service';

const BASE_PATH = 'api/v1/manager/Audit';
const appendToApiPath = appendPath(BASE_PATH);

export const AUDIT_TAG = 'audit';
export const AUDIT_TAGS = [AUDIT_TAG];

/** @typedef {{ page: number; pageSize: number; search: string; selectedStatuses: string }} ManagerAuditQueryString */

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<
 *    ManagerAuditQueryString|void,
 *    any,
 *    any,
 *    { audits: VBD.IAudit[]; totalCount: number }
 * >}
 */
const getAll = (builder) => builder.query({
    query: (queryParams) => ({ url: `${BASE_PATH}${toQueryString(queryParams)}`, method: 'GET' }),
    async onQueryStarted(id, api) { await showSpinner(api); },
    transformResponse: (audit, meta) => ({
        audits: audit
            .map((audits) => {
                audits = applyUserTimeZoneConversionMapping(audits);
                return removeBrowserTimeZoneConversionMapping(audits);
            }),
        totalCount: Number(meta?.response?.headers['x-total-count']),
    }),
    providesTags: AUDIT_TAGS,
});

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number, any, any, string>}
 */
const downloadAuditReportCsv = (builder) => builder.query({
    query: (id) => ({ url: appendToApiPath('bidProfile', id, 'csv'), method: 'GET' }),
    async onQueryStarted(_, api) { await showSpinner(api); },
});

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<any, any, any, string>}
 */
const downloadMultipleAuditReportCsv = (builder) => builder.query({
    query: (bidProfileIds) => ({ url: appendToApiPath('bidProfile', 'csv'), method: 'POST', data: bidProfileIds }),
    async onQueryStarted(_, api) { await showSpinner(api); },
});

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number[], any, any, string>}
 */
const getEmployeeAuditReport = (builder) => builder.query({
    query: (bidProfileIds) => {
        const route = appendToApiPath('bidProfileEmployee', 'csv');
        const queryParams = new URLSearchParams(bidProfileIds.map((i) => ['bidProfileIds', i.toString()])).toString();
        return {
            url: `${route}?${queryParams}`, method: 'GET',
        };
    },
    async onQueryStarted(_, api) { await showSpinner(api); },
});

export const endpointsAudit = (builder) => ({
    getAllBidProfileAudit: getAll(builder),
    downloadAuditReportCsv: downloadAuditReportCsv(builder),
    downloadMultipleAuditReportCsv: downloadMultipleAuditReportCsv(builder),
    getEmployeeAuditReport: getEmployeeAuditReport(builder),
});
