import combineURLs from 'axios/lib/helpers/combineURLs';
import { HrSystemIntegrationTypes } from '../enums';

/**
 * @param {string=} path
 */
const getSsoUrl = (path) => {
    const rootPath = process.env.REACT_APP_BACKEND_API || `https://${window.location.host}`;
    const ssoPath = combineURLs(rootPath, '/api/v2/sso');
    const targetUrl = combineURLs(ssoPath, path);
    return targetUrl;
};

/**
 * @param {string} ukgUrl
 * @param {string} ukgClientId
 * @param {string=} ssoPath
 * @returns
 */
export const getUkgRedirectUrl = (ukgUrl, ukgClientId, ssoPath) => {
    if (ssoPath !== undefined && ssoPath != null && !(ssoPath.indexOf('/') > -1)) {
        ssoPath = ssoPath.charAt(0).toUpperCase() + ssoPath.slice(1);
        ssoPath = `/${ssoPath}`;
    } else if (ssoPath !== undefined && ssoPath != null) {
        ssoPath = ssoPath.charAt(0) + ssoPath.charAt(1).toUpperCase() + ssoPath.slice(2);
    }

    ssoPath = getSsoUrl(ssoPath);
    const queryParams = `/oauth2/authorize?response_type=code&client_id=${encodeURIComponent(ukgClientId)}&redirect_uri=${ssoPath}`;
    const redirectUrl = combineURLs(ukgUrl, queryParams);
    return redirectUrl;
};

/**
 *
 * @param {CommonClient2.IPublicConfig} configs
 * @param {string=} ssoPath
 * @returns
 */
export const GetHrSystemRedirectUrl = (configs, ssoPath) => {
    switch (configs.hrSystemIntegrationType) {
        case HrSystemIntegrationTypes.UKGWorkForceDimensions:
            return getUkgRedirectUrl(configs.ukgUrl, configs.externalClientId, ssoPath);
        case (HrSystemIntegrationTypes.ADPWorkForceNow):
            return `${configs.externalAuthUrl}--${ssoPath}`;
        case (HrSystemIntegrationTypes.ShiftBoard):
            return configs.externalAuthUrl;
        case (HrSystemIntegrationTypes.UKGWorkForceCentral):
        default:
            return `${window.location.origin} /login/${ssoPath} `;
    }
};
