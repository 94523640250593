import './ColoredDot.scss';

/**
 * @param {import('./typing').ColoredDotProps} props
 */
function ColoredDot(props) {
    const {
        style = { backgroundColor: 'white', border: '1px solid #B5CBE3', color: '#666666' },
    } = props;

    return (
        <div
            key={`ColoredDot${Math.floor(Math.random() * 100)}`}
            style={style}
            className="colored-dot-status-container"
        />
    );
}

export default ColoredDot;
