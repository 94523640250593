import {
    applyUkgTimeZoneTransformationToProps,
    removeUkgTimeZoneTransformationToProps,
    removeBrowserTimezoneConversionToProps,
    applyUkgUserTimeZoneTransformation,
} from '@commonClient/services/ukgDateTime';
import { WeeklyRuleTypeNames } from '@enums/index';

const listOfDateParamsToConvertForUser = [
    'employeePeriodEndDate',
    'employeePeriodStartDate',
    'employeeSubmissionEndTime',
    'employeeSubmissionStartTime',
    'endBiddingTime',
    'previousSubmissionPeriodEndDate',
    'publishedDate',
    'startBiddingTime',
    'statusDate',
    'submissionPeriodEndDate',
    'submissionPeriodStartDate',
    'submittedDate',
];
const listOfDateParamsToConvertForBrowser = [
    'employeePeriodEndDate',
    'employeePeriodStartDate',
    'requestPeriodStartDate',
    'requestPeriodEndDate',
];
export const removeUserTimeZoneConversionMapping = removeUkgTimeZoneTransformationToProps(listOfDateParamsToConvertForUser);
export const removeBrowserTimeZoneConversionMapping = removeBrowserTimezoneConversionToProps(listOfDateParamsToConvertForBrowser);

export const applyManagementTimeZoneConversionMapping = (bid) => {
    const dateParamsToApplyConversion = [
        'submissionPeriodEndDate',
        'submissionPeriodStartDate',
        'requestPeriodStartDate',
        'requestPeriodEndDate',
        'cancelledDateTime',
        'publishedDateTime',
        'lastUpdate',
        'submittedDateTime',
    ];

    /** @type {VBD.IManagerBidProfile} */
    bid = applyUkgTimeZoneTransformationToProps(dateParamsToApplyConversion)(bid);
    const mappedEmployeesInfos = bid?.employeesInfos?.map((x) => ({
        ...x,
        submissionStart: x?.submissionStart ? applyUkgUserTimeZoneTransformation(x?.submissionStart) : undefined,
        submissionEnd: x?.submissionEnd ? applyUkgUserTimeZoneTransformation(x?.submissionEnd) : undefined,
    }));
    return { ...bid, employeesInfos: mappedEmployeesInfos };
};

export const applyUserTimeZoneConversionMapping = (bid) => {
    /** @type {VBD.IManagerBidProfile} */
    bid = applyUkgTimeZoneTransformationToProps(listOfDateParamsToConvertForUser)(bid);

    return {
        ...bid,
        rankedEmployees: bid?.rankedEmployees?.map((r) => ({
            ...r,
            submissionStart: r?.submissionStart ? applyUkgUserTimeZoneTransformation(r.submissionStart) : null,
            submissionEnd: r?.submissionEnd ? applyUkgUserTimeZoneTransformation(r.submissionEnd) : null,
        })),
    };
};

export const getWeeklyRuleTypeName = (weeklyRuleType) => WeeklyRuleTypeNames[weeklyRuleType];
