import { useTranslation } from 'react-i18next';
import logo from '../../assets/zaddons_logo.png';
import './Unauthorized.scss';

const Unauthorized = () => {
    const { t } = useTranslation('common');
    return (
        <div className="unauthorizedPage">
            <div className="unauthorizedContent">
                <img
                    src={logo}
                    alt=""
                    width="200px"
                    height="40px"
                    className="block-center img-rounded"
                    style={{ objectFit: 'cover' }}
                />
                <div className="text-lg mb-lg">401</div>
                <p className="lead m0">{t('errors.authorizationRequired')}</p>
                <p>{t('errors.contactAdmin')}</p>
            </div>
        </div>
    );
};

export default Unauthorized;
