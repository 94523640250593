import { Button } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import './InfoButton.scss';

/**
 *
 * @param {{
 *      onClick: import('react').MouseEventHandler<HTMLElement> | undefined;
 *      text: JSX.Element | string;
 *      position?: 'left' | 'center' | 'right';
 *      className?: string;
 * }} param0
 * @returns
 */
const InfoButton = ({
    onClick, text, position = 'right', className = '',
}) => (
    <div className={`button-wrapper ${className}`}>
        <Button
            className="button"
            onClick={onClick}
        >
            <FontAwesomeIcon
                className={`icon position-${position}`}
                icon={faInfoCircle}
            />
            {text}
        </Button>
    </div>
);

export default InfoButton;
