const DRAFT = 'Draft';
const SCHEDULED = 'Scheduled';
const ACTIVE = 'Active';
const CLOSED = 'Closed';
const PUBLISHED = 'Published';
const CANCELLED = 'Cancelled';

export default Object.freeze({
    [DRAFT]: 0,
    [SCHEDULED]: 1,
    [ACTIVE]: 2,
    [CLOSED]: 3,
    [PUBLISHED]: 4,
    [CANCELLED]: 5,
});

export const BidProfileStatusNames = Object.freeze({
    0: DRAFT,
    1: SCHEDULED,
    2: ACTIVE,
    3: CLOSED,
    4: PUBLISHED,
    5: CANCELLED,
});
