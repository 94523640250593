import { useTranslation } from 'react-i18next';

/**
 * TODO: Will need to adapt to different languages
 * @see https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
 * @param {number} n
 * @param {string} othersSuffix
 * @param {string} firstSuffix
 * @param {string} secondSuffix
 * @param {string} thirdSuffix
 * @returns
 */
const getNumberWithOrdinalSuffix = (n, othersSuffix, firstSuffix, secondSuffix, thirdSuffix) => {
    const s = [othersSuffix, firstSuffix, secondSuffix, thirdSuffix];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

/** @param {number} numericValue */
const useOrdinalSuffix = (numericValue) => {
    const { t } = useTranslation('common', { keyPrefix: 'ordinalSuffix' });
    return getNumberWithOrdinalSuffix(numericValue, t('others'), t('first'), t('second'), t('third'));
};

export default useOrdinalSuffix;
