/* eslint-disable no-console */
import { reportError } from '@commonClient/components/ErrorBoundary';

/** Inspired from @see https://redux.js.org/understanding/history-and-design/middleware */

const crashReporter = (store) => (next) => (action) => {
    try {
        return next(action);
    } catch (/** @type {*} */ err) {
        console.error('An exception was caught...', err);

        const state = store.getState();

        reportError({
            message: err?.message || 'Unknown error',
            stack: err?.stack?.toString() || '',
            componentStack: 'Exception caught in Redux crashReporter middleware',
        });
        console.debug(state);
        throw err;
    }
};

export default crashReporter;
