const UNSUBMITTED = 'Unsubmitted';
const SUBMITTED = 'Submitted';
const UNAWARDED = 'Unawarded';
const APPROVED = 'Approved';
const REFUSED = 'Refused';
const DECLINED = 'Declined';
const REMOVED = 'Removed';

export default Object.freeze({
    [UNSUBMITTED]: 0,
    [SUBMITTED]: 1,
    [UNAWARDED]: 2,
    [APPROVED]: 3,
    [REFUSED]: 4,
    [DECLINED]: 5,
    [REMOVED]: 6,
});

export const BidSelectionStatusNames = Object.freeze({
    0: UNSUBMITTED,
    1: SUBMITTED,
    2: UNAWARDED,
    3: APPROVED,
    4: REFUSED,
    5: DECLINED,
    6: REMOVED,
});
