import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import './FormAutoComplete.scss';
import { isNil } from 'lodash';

/**
 * @param {any[]} options
 * @param {*} option
 */
const getSelectedOption = (options, option) => (!isNil(option?.id) ? option : options.find((op) => op.id === option));

/**
 * @param {import('@material-ui/lab').AutocompleteProps & { name: string; error?: boolean, control: any, rules?: any }} param0
 */
const FormAutoComplete = ({
    name, renderInput, onBlur: onBlurCallBack, onChange: valueChanged, options, error, control, rules, ...props
}) => {
    // Changing the key forces the component to remount, this is a workaround for issues:
    // https://github.com/mui-org/material-ui/issues/20906
    // https://github.com/mui-org/material-ui/issues/25096
    const [key, setKey] = useState(0);
    useEffect(() => setKey(key + 1), [JSON.stringify(options)]);

    return (
        <div className={`core--controlled-autocomplete ${error ? 'error' : ''}`}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({
                    field: {
                        onChange, onBlur, value, ref,
                    },
                }) => (
                    <Autocomplete
                        key={key}
                        {...props}
                        onBlur={(e) => {
                            // @ts-ignore
                            onBlur(e);
                            if (onBlurCallBack) {
                                setTimeout(() => onBlurCallBack(e), 0);
                            }
                        }}
                        value={value}
                        ref={ref}
                        renderInput={renderInput}
                        options={options}
                        getOptionLabel={(option) => getSelectedOption(options, option)?.name || ''}
                        getOptionSelected={(option, selectedValue) => option.id === getSelectedOption(options, selectedValue)?.id}
                        onChange={(e, data, reason, details) => {
                            onChange(!isNil(data?.id) ? data?.id : data);
                            if (valueChanged) {
                                valueChanged(e, data, reason, details);
                            }
                        }}
                    />
                )}

            />
        </div>
    );
};

export default FormAutoComplete;
