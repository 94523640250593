export const BidReportTypes = Object.freeze({
    EmployeeChoice: 0,
    EmployeeRank: 1,
    EmployeeBiddingResult: 2,
    ManagerRank: 3,
    ManagerBiddingResult: 4,
    ManagerEmployeeChoice: 5,
});

export const BidReportTypesNameKeys = Object.freeze({
    0: 'employeeChoiceReport',
    1: 'employeeRankReport',
    2: 'employeeResultReport',
    3: 'managerRankReport',
    4: 'managerResultReport',
    5: 'managerChoiceReport',
});
