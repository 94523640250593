const TimePickerColumn = ({
    title,
    options,
    selectedOption,
    onClick,
}) => (
    <div className="popover-content--column">
        <div className="headline-h-400 column--list-title">{title}</div>
        <hr />
        <ul className="column--list">
            {options.map((option) => (
                <li
                    className={`list--list-item ${option.value === selectedOption ? 'selected' : ''}`}
                    key={option.key}
                    value={option.value}
                >
                    <div
                        className="list-item"
                        onClick={() => onClick(option)}
                    >
                        <span className="text-sm-semibold">{String(option.value).padStart(2, '0')}</span>
                    </div>
                </li>
            ))}
        </ul>
    </div>
);

export default TimePickerColumn;
