import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/admin/Utilities';
const appendToApiPath = appendPath(BASE_PATH);

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, any, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, VBD.AbsenceThresholdSource[]>}
 */
const getAbsenceThresholdSources = (builder) =>
    builder.query({
        query: () => ({ url: appendToApiPath('AbsenceThresholdSources'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

const endpointsAbsenceThresholdSources = (builder) => ({
    getAbsenceThresholdSources: getAbsenceThresholdSources(builder),
});

export default endpointsAbsenceThresholdSources;
