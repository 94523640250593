import './FormFieldErrorDisplay.scss';

const FormFieldErrorDisplay = ({ children, errorMessage }) => (
    <div className="input-field-container">
        {children}
        {errorMessage && <span role="alert">{errorMessage}</span>}
    </div>
);

export default FormFieldErrorDisplay;
