import React from 'react';
import './CheckboxWithInfo.scss';
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';

/**
 * @param {import('@material-ui/core').CheckboxProps & import('./typing').CheckboxProps} props
 * @returns
 */
export default function CheckboxWithInfo({ 
    label = '', 
    checked, 
    onChange, 
    isDisabled = false, 
    info = '',
    ...props
}) {

    return (
        <div className="core--checkbox-with-info">
            <FormControlLabel
                label={label}
                control={
                    <Checkbox
                        {...props}
                        color='primary'
                        checked={checked}
                        value={checked}
                        onChange={onChange}
                        disabled={isDisabled}
                    />
                }
            />
            <FormHelperText className="info">{info}</FormHelperText>
        </div>
    );
}
