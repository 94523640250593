const Error = 'Error';
const Warning = 'Warning';
const Info = 'Info';
const Success = 'Success';

const AlertType = Object.freeze({
    [Error]: 1,
    [Warning]: 2,
    [Info]: 3,
    [Success]: 4,
});

export const AlertClassNames = Object.freeze({
    1: 'alert-error',
    2: 'alert-warning',
    3: 'alert-info',
    4: 'alert-success',
});

export default AlertType;
