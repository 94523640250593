import { Card, CardContent } from '@material-ui/core';
import './CardPeriod.scss';

const CardPeriod = ({ children }) => (
    <Card className="core--card-period-container">
        <CardContent>
            {children}
        </CardContent>
    </Card>
);

export default CardPeriod;
