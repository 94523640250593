import i18n from '@commonClient/translations/i18n';
import { enUS, fr } from 'date-fns/locale';
import { updateLocale } from '@commonClient/services/ukgDateTime';

import commonFr from './fr/common.json';
import commonEn from './en/common.json';
import bidFr from './fr/bid.json';
import bidEn from './en/bid.json';
import bidEnumsFr from './fr/bidEnums.json';
import bidEnumsEn from './en/bidEnums.json';
import tableFr from './fr/table.json';
import tableEn from './en/table.json';
import notificationFr from './fr/notification.json';
import notificationEn from './en/notification.json';
import menuFr from './fr/menu.json';
import menuEn from './en/menu.json';
import globalSetupFr from './fr/globalSetup.json';
import globalSetupEn from './en/globalSetup.json';

i18n.addResourceBundle('fr', 'common', commonFr, true);
i18n.addResourceBundle('en', 'common', commonEn, true);
i18n.addResourceBundle('fr', 'bid', bidFr, true);
i18n.addResourceBundle('en', 'bid', bidEn, true);
i18n.addResourceBundle('fr', 'bidEnums', bidEnumsFr, true);
i18n.addResourceBundle('en', 'bidEnums', bidEnumsEn, true);
i18n.addResourceBundle('fr', 'table', tableFr, true);
i18n.addResourceBundle('en', 'table', tableEn, true);
i18n.addResourceBundle('fr', 'notification', notificationFr, true);
i18n.addResourceBundle('en', 'notification', notificationEn, true);
i18n.addResourceBundle('fr', 'menu', menuFr, true);
i18n.addResourceBundle('en', 'menu', menuEn, true);
i18n.addResourceBundle('fr', 'globalSetup', globalSetupFr, true);
i18n.addResourceBundle('en', 'globalSetup', globalSetupEn, true);

i18n.loadNamespaces('common');
i18n.loadNamespaces('bid');
i18n.loadNamespaces('bidEnums');
i18n.loadNamespaces('table');
i18n.loadNamespaces('notification');
i18n.loadNamespaces('menu');
i18n.loadNamespaces('globalSetup');

let locale = enUS;
export const setLocale = (toLanguage) => {
    locale = toLanguage === 'en' ? enUS : fr;
    sessionStorage.setItem('selectedLocale', toLanguage);
    updateLocale(toLanguage);
    i18n.changeLanguage(toLanguage);
};

export const getLocale = () => locale;

export default i18n;
