import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath, { toQueryString } from '@services/path';

const BASE_MANAGER_PATH = 'api/v1/manager/Utilities/';
const BASE_ADMIN_PATH = 'api/v1/admin/Utilities/';
const appendToManagerApiPath = appendPath(BASE_MANAGER_PATH);
const appendToAdminApiPath = appendPath(BASE_ADMIN_PATH);

export const EMPLOYEE_GROUP_TAG = 'EmployeeGroup';
export const EMPLOYEE_GROUP_RESOLVE_TAG = 'EmployeeGroupResolve';
export const REMOVED_EMPLOYEE_FROM_GROUP_TAG = 'RemovedEmployeeFromGroup';
export const EMPLOYEE_GROUP_TAGS = [EMPLOYEE_GROUP_TAG, EMPLOYEE_GROUP_RESOLVE_TAG, REMOVED_EMPLOYEE_FROM_GROUP_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IEmployeeGroupInfo[]>}
 */
const getEmployeeGroupInfos = (builder) =>
    builder.query({
        query: () => ({ url: appendToManagerApiPath('employeeGroupInfos'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [EMPLOYEE_GROUP_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IEmployeeGroupInfo[]>}
 */
const getEmployeeGroupInfosAdmin = (builder) =>
    builder.query({
        query: () => ({ url: appendToAdminApiPath('employeeGroupInfos'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [EMPLOYEE_GROUP_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IEmployeePresentationModel[]>}
 */
const resolveEmployeeGroup = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToManagerApiPath('employeeGroup', id), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: (result, error, id) => [{ type: EMPLOYEE_GROUP_RESOLVE_TAG, id }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<{bidProfileId: string}, any, any, Dto.IEmployeePresentationModel[]>}
 */
const getRemovedEmployeeFromGroup = (builder) =>
    builder.query({
        query: (params) => ({ url: appendToManagerApiPath('getRemovedEmployeesFromGroup', toQueryString(params)), method: 'GET' }),
        async onQueryStarted(_, api) { await showSpinner(api); },
        providesTags: [REMOVED_EMPLOYEE_FROM_GROUP_TAG],
    });

export const endpointsEmployeeGroups = (builder) => ({
    getEmployeeGroupInfos: getEmployeeGroupInfos(builder),
    getEmployeeGroupInfosAdmin: getEmployeeGroupInfosAdmin(builder),
    resolveEmployeeGroup: resolveEmployeeGroup(builder),
    getRemovedEmployeeFromGroup: getRemovedEmployeeFromGroup(builder),
});
