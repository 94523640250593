import showSpinner from '@services/addSpinnerOnQueryStarted';
import addRealTimeListener from '@services/addRealTimeListener';
import { hubs } from '@services/liveUpdate';
import { dynamicSort } from '@commonClient/services/sort';
import appendPath from '@services/path';
import { SCHEDULE_TAG } from '@apis/schedule';
import { applyUkgTimeZoneTransformationToProps, getDateAsDateOnly } from '@commonClient/services/ukgDateTime';
import {
    EMPLOYEE_BID_PROFILES_TAG,
    EMPLOYEE_BID_PROFILES_ARCHIVED_TAG,
    MANAGER_BID_PROFILES_TAGS,
    MANAGER_BID_PROFILE_MANAGEMENT_TAG,
} from './bidProfiles.tags';
import {
    applyUserTimeZoneConversionMapping,
    removeBrowserTimeZoneConversionMapping,
} from './bidProfiles.service';

const BASE_PATH = 'api/v1/employee/bidProfiles';
const ARCHIVED_PATH = 'archived';
const appendToApiPath = appendPath(BASE_PATH);

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, VBD.IEmployeeBidProfile[]>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        async onCacheEntryAdded(arg, api) { await addRealTimeListener(hubs.BidProfile, api); },
        transformResponse: (bids) => bids
            .sort(dynamicSort('-lastUpdate'))
            .map((bid) => {
                bid = applyUserTimeZoneConversionMapping(bid);
                return removeBrowserTimeZoneConversionMapping(bid);
            }),
        providesTags: (result) => (result
            ? result.map(({ bidProfileId }) => ({ type: EMPLOYEE_BID_PROFILES_TAG, id: bidProfileId }))
            : [EMPLOYEE_BID_PROFILES_TAG]),
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, VBD.IArchivedEmployeeBidProfile[]>}
 */
const getArchived = (builder) =>
    builder.query({
        query: () => ({ url: appendToApiPath(ARCHIVED_PATH), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: (bids) => bids
            .map((bid) => {
                bid = applyUkgTimeZoneTransformationToProps([
                    'submissionPeriodEndDate',
                    'submissionPeriodStartDate',
                    'requestPeriodStartDate',
                    'requestPeriodEndDate',
                    'cancelledDateTime',
                    'publishedDateTime',
                    'lastUpdate',
                    'submittedDate',
                ])(bid);
                return removeBrowserTimeZoneConversionMapping(bid);
            }),
        providesTags: (result) => (result
            ? result.map(({ bidProfileId }) => ({ type: EMPLOYEE_BID_PROFILES_ARCHIVED_TAG, id: bidProfileId }))
            : [EMPLOYEE_BID_PROFILES_ARCHIVED_TAG]),
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *  .QueryDefinition<{profileId: string, employeeId:number}, any, any, VBD.IEmployeeBidProfile>}
 */
const get = (builder) =>
    builder.query({
        query: ({ profileId, employeeId }) => ({ url: appendToApiPath(profileId, employeeId), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: ((bid) => {
            bid = applyUserTimeZoneConversionMapping(bid);
            bid = removeBrowserTimeZoneConversionMapping(bid);
            bid.entriesExceptionRules = bid.entriesExceptionRules?.map((entry) => ({
                ...entry,
                startDate: getDateAsDateOnly(entry.startDate),
                endDate: getDateAsDateOnly(entry.endDate),
            })) ?? [];
            return bid;
        }),
        providesTags: (result) => [{ type: EMPLOYEE_BID_PROFILES_TAG, id: result?.bidProfileId }],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<number|string, any, any, Dto.IManagementInfo>}
 */
const getManagementInfos = (builder) =>
    builder.query({
        query: (id) => ({ url: appendToApiPath(id, 'managementInfos'), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: () => [MANAGER_BID_PROFILE_MANAGEMENT_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *      .MutationDefinition<number|Dto.IDeclineBidProfilePayload, any, any, VBD.IEmployeeBidProfile>}
 */
const decline = (builder) =>
    builder.mutation({
        query: (payload) => ({ url: appendToApiPath('decline'), method: 'POST', data: payload }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [
            EMPLOYEE_BID_PROFILES_TAG,
            ...MANAGER_BID_PROFILES_TAGS,
            MANAGER_BID_PROFILE_MANAGEMENT_TAG,
            SCHEDULE_TAG,
            EMPLOYEE_BID_PROFILES_ARCHIVED_TAG,
        ],
    });

const endpointsEmployeeBidProfiles = (builder) => ({
    getEmployeeBidProfiles: getAll(builder),
    getEmployeeArchivedBidProfiles: getArchived(builder),
    getEmployeeBidProfile: get(builder),
    declineEmployeeBidProfile: decline(builder),
    getEmployeeManagementInfos: getManagementInfos(builder),
});

export default endpointsEmployeeBidProfiles;
