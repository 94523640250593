import React from 'react';
import { Controller } from 'react-hook-form';
import SwitchWithInfo from '../SwitchWithInfo';

/**
 * @param {import('@material-ui/core').SwitchProps & {
 *   info: string;
 *   label: string;
 *   inputRef?: import('react').RefCallback<SwitchWithInfo>;
 *   onBlur?: any;
 *   onFocus?: any;
 *   color?: 'primary' | 'secondary' | 'default';
 *   checked?: boolean;
 *   name: string;
 *   control: any;
 *   rules?: any;
 * }} param0
 * @returns
 */
const FormSwitchWithInfo = ({
    name,
    control,
    rules,
    info,
    label,
    onChange: valueChanged,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onBlur, onChange, value, ref,
            },
        }) => (
            <SwitchWithInfo
                {...props}
                info={info}
                label={label}
                inputRef={ref}
                onBlur={onBlur}
                checked={value}
                onChange={(event, checked) => {
                    onChange(event);
                    if (valueChanged) {
                        valueChanged(event, checked);
                    }
                }}
            />
        )}
    />
);

export default FormSwitchWithInfo;
