import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSelectedBidsRemoved: false,
};

const employeeBidEditSlice = createSlice({
    name: 'employeeBidEdit',
    initialState,
    reducers: {
        reset: () => initialState,
        setIsSelectedBidsRemoved: (state, action) => { state.isSelectedBidsRemoved = action.payload; },
    },
});

export const isSelectedBidsRemovedSelector = (state) => state.employeeBidEdit.isSelectedBidsRemoved;
export default employeeBidEditSlice;
