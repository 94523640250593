import React from 'react';
import { Typography, Avatar, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Edit as EditIcon, Info as InfoIcon } from '@material-ui/icons';
import './SummarySection.scss';
import ChipCompletedStep from '../ChipCompletedStep';

/**
 * @typedef {{step: number; isStepValid: boolean;}} EditStep
 * @param {{
 *  index?: any;
 *  title?: any;
 *  editStep?: EditStep;
 *  onEditClick: *;
 *  displayInfoIconOnly?: boolean;
 *  children?: React.ReactNode;
 * }} param0
 */
const SummarySection = ({
    index,
    title,
    editStep,
    onEditClick,
    displayInfoIconOnly = false,
    children,
}) => {
    const { isStepValid } = editStep || {};

    return (
        <div className="core--summary-section-container">
            <div className="core--summary-section">
                <div className="header-container">
                    <div className="header-container-left">
                        <Avatar>{index}</Avatar>
                        <Typography variant="h6">{title}</Typography>
                    </div>
                    {editStep && (
                        <div className="header-container-right">
                            {isStepValid && <ChipCompletedStep />}
                            <IconButton
                                className="edit-icon"
                                onClick={onEditClick}
                            >
                                {
                                    displayInfoIconOnly
                                        ? <InfoIcon className="info-icon" />
                                        : <EditIcon fontSize="inherit" />
                                }
                            </IconButton>
                        </div>
                    )}
                </div>
                <div className="summary-content-container">
                    {children}
                </div>
            </div>
            <Divider />
        </div>
    );
};

export default SummarySection;
