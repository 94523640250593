import showSpinner from '@services/addSpinnerOnQueryStarted';
import { EMPLOYEE_BID_PROFILES_TAG } from '../bidProfiles';

const BASE_PATH = 'api/v1/employee/bidSelectionDrafts';

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IBidSelectionDraftRequestParam, any, any, Dto.IBidSelectionDraft>}
 */
const create = (builder) =>
    builder.mutation({
        query: (bid) => ({ url: `${BASE_PATH}`, method: 'POST', data: bid }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [EMPLOYEE_BID_PROFILES_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IBidSelectionSaveRequestParam, any, any, Dto.IBidSelectionDraft>}
 */
const save = (builder) =>
    builder.mutation({
        query: (bid) => ({ url: `${BASE_PATH}/${bid.selectionId}`, method: 'PUT', data: bid }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [EMPLOYEE_BID_PROFILES_TAG],
    });

const endpointsDraftBidSelections = (builder) => ({
    createDraftSelection: create(builder),
    saveDraftSelection: save(builder),
});

export default endpointsDraftBidSelections;
