import { dynamicSort } from '../../services/sort';

const orderMap = {
    status: 1,
    statusTranslationKey: 1,
    statusLabel: 2,
    id: 2,
    name: 3,
    description: 4,
};

const extractColumns = (data) => {
    if (!data?.length) {
        return [];
    }

    const [firstEntry] = data;
    return Object
        .keys(firstEntry)
        .map((key) => ({
            key,
            tKey: `columns.${key}`,
            uniqueKey: key,
            isTooltip: key === 'description',
            sortable: true,
            flexGrow: 1,
            index: orderMap[key] || 5,
        }))
        .sort(dynamicSort('index'));
};

export default extractColumns;
