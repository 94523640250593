const SUNDAY = 'Sunday';
const MONDAY = 'Monday';
const TUESDAY = 'Tuesday';
const WEDNESDAY = 'Wednesday';
const THURSDAY = 'Thursday';
const FRIDAY = 'Friday';
const SATURDAY = 'Saturday';

export default Object.freeze({
    [SUNDAY]: 0,
    [MONDAY]: 1,
    [TUESDAY]: 2,
    [WEDNESDAY]: 3,
    [THURSDAY]: 4,
    [FRIDAY]: 5,
    [SATURDAY]: 6,
});

export const DaysOfWeekNames = Object.freeze({
    0: SUNDAY,
    1: MONDAY,
    2: TUESDAY,
    3: WEDNESDAY,
    4: THURSDAY,
    5: FRIDAY,
    6: SATURDAY,
});
