import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    statusFilterSelected: [],
    dataviewFieldsSelected: [],
    employeeIdsSelected: [],
};

const managerBidDashboardSlice = createSlice({
    name: 'managerBidDashboard',
    initialState,
    reducers: {
        reset: () => initialState,
        setStatusFilterSelected: (state, action) => { state.statusFilterSelected = action.payload; },
        setDataviewFieldsSelected: (state, action) => { state.dataviewFieldsSelected = action.payload; },
        setEmployeeIdsSelected: (state, action) => { state.employeeIdsSelected = action.payload; },
    },
});

export const statusFilterSelector = (state) => state.managerBidDashboard.statusFilterSelected;
export const dataviewFieldsSelector = (state) => state.managerBidDashboard.dataviewFieldsSelected;
export const employeeIdsSelector = (state) => state.managerBidDashboard.employeeIdsSelected;
export default managerBidDashboardSlice;
