import logo from '../../assets/zaddons_logo.png';
import './NotFound.scss';

const notFound = () => (
    <div className="notFoundPage">
        <div className="notFoundContent">
            <img
                src={logo}
                alt=""
                width="200px"
                height="40px"
                className="block-center img-rounded"
                style={{ objectFit: 'cover' }}
            />
            <div className="text-lg mb-lg">404</div>
            <p className="lead m0">We couldn't find this page.</p>
            <p>The page you are looking for does not exists.</p>
        </div>
    </div>
);

export default notFound;
