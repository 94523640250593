import React from 'react';
import { cloneDeep } from 'lodash';

/**
 * @param {*} obj
 * @param {{ [Key: string]: boolean; }|undefined} fieldDictionary Specify which fields from object are included or not.
 * If undefined, all fields are included.
 * @returns {string}
 */
export const getSearchIndexFromObject = (obj, fieldDictionary = undefined) => {
    const getSearchIndexFromArray = (arr) => arr.map((arrObj) => getSearchIndexFromObject(arrObj, fieldDictionary));
    return Object
        .entries(obj)
        // remove null, undefined, empty
        .filter(([key, val]) => !!val && !React.isValidElement(val) && (!fieldDictionary || !!fieldDictionary[key]))
        .map(([, val]) => {
            // Number.isNan doesn't work as it should, the check for isNan is to detect invalid dates
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            if (val instanceof Date && !isNaN(val)) {
                return val.toISOString().replace('T', '__');
            }
            if (val instanceof Object) {
                return getSearchIndexFromObject(val, fieldDictionary);
            }
            if (val instanceof Array) {
                return val.map(getSearchIndexFromArray);
            }

            return val.toString();
        })
        .join('__')
        .toLocaleLowerCase();
};

/**
 * @template T
 * @param {T} obj
 * @param {{ [Key: string]: boolean; }|undefined} fieldDictionary Specify which fields from object are included or not.
 * If undefined, all fields are included.
 */
export const addSearchIndex = (obj, fieldDictionary = undefined) => (
    { ...obj, searchIndex: getSearchIndexFromObject(obj, fieldDictionary) }
);

/**
 * @template T
 * @param {T&{searchIndex?:string}} obj
 * @return {T}
 */
export const removeSearchIndex = (obj) => {
    const clone = cloneDeep(obj);
    delete clone.searchIndex;
    return clone;
};

/**
 * @template T
 * @param {T} obj
 * @param {{ [Key: string]: boolean; }|undefined} fieldDictionary Specify which fields from object are included or not.
 * If undefined, all fields are included.
 * @returns {T|T&{searchIndex:string}}
 */
export const addSearchIndexSafe = (obj, fieldDictionary = undefined) => {
    if (!obj || !(obj instanceof Object) || !Object.keys(obj).length) {
        return obj;
    }

    return addSearchIndex(obj, fieldDictionary);
};
