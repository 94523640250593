import { getAsShortDateTime } from '../../services/ukgDateTime';
import DateDisplay from '../DateDisplay';

/** @param {{ date: Date; prefix: string; dateFormatFunction?:(date: Date) => string}} param0 */
const SubmissionsDate = ({ date, prefix, dateFormatFunction = getAsShortDateTime }) => (
    <div className="submission-date-display">
        <DateDisplay
            date={date}
            prefix={prefix}
            formatFunction={dateFormatFunction}
        />
    </div>
);

export default SubmissionsDate;
