import { useState } from 'react';
import useInterval from '../useInterval';

/** @param {number} n */
const pad = (n) => (n < 10 ? `0${n}` : n);

/**
 * @see https://stackoverflow.com/questions/9933803/javascript-countdown-formatting-seconds-to-hhmmss
 * @param {number} ms
 */
const formatCountdown = (ms) => {
    const numericSeconds = +ms / 1000;
    const h = Math.floor(numericSeconds / 3600);
    const m = Math.floor(numericSeconds / 60) - (h * 60);
    const s = Math.floor(numericSeconds - h * 3600 - m * 60);
    return `${pad(m)}:${pad(s)}`;
};

/**
 * @param {number} start
 * @param {number} timeout
 */
const useCountdown = (start, timeout) => {
    const [counter, refreshCounter] = useState(0);
    useInterval(() => refreshCounter(counter + 1), 100);
    const countdownElapsesOn = start + timeout;
    return formatCountdown(countdownElapsesOn - Date.now() + 1000);
};

export default useCountdown;
