import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedIndex: 0,
};

const sliceName = 'managerBidEditTab';

const managerBidEditTabSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setSelectedTab: (state, action) => { state.selectedIndex = action.payload; },
    },
});

export const selectedTabIndexSelector = (state) => state[sliceName].selectedIndex;
export default managerBidEditTabSlice;
