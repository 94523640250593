/* eslint-disable no-unused-vars */
import { Controller } from 'react-hook-form';
import DateRangePicker from '../DateRangePicker';
import { getDateTimeFormats } from '../../services/ukgDateTime';

/**
 * @param {import('rsuite/DateRangePicker').DateRangePickerProps & {
 * name: string; control: *; rules?: *, value?: []; hasError?: boolean}
 * } param0
 * */
const FormDateRangePicker = ({
    name,
    control,
    rules,
    value: unused,
    onChange: valueChanged,
    hasError,
    ...props
}) => (
    <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
            field: {
                onChange, onBlur, value,
            },
        }) => (
            <DateRangePicker
                {...props}
                onClose={onBlur}
                value={value}
                onChange={(changedValue, event) => {
                    onChange(changedValue, event);
                    if (valueChanged) {
                        valueChanged(changedValue, event);
                    }
                }}
                onClean={onBlur}
                hasError={hasError}
                format={getDateTimeFormats().LongDateFormat}
            />
        )}
    />
);

export default FormDateRangePicker;
