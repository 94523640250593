import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    informationStatus: 'wait',
    sortingRules: 'wait',
    exceptionRules: 'wait',
};

const awardingRuleSetsStepsSlice = createSlice({
    name: 'awardingRuleSetsSteps',
    initialState,
    reducers: {
        createAwardingRuleSetSteps: () => ({ ...initialState }),
        editAwardingRuleSetSteps: (_, action) => ({ ...action.payload }),
        setInformationStep: (state, action) => { state.informationStatus = action.payload; },
        setSortingRulesStep: (state, action) => { state.sortingRules = action.payload; },
        setExceptionRulesStep: (state, action) => { state.exceptionRules = action.payload; },
    },
});

export const informationStepStatusSelector = (state) => state.awardingRuleSetsSteps.informationStatus;
export const sortingRulesStepStatusSelector = (state) => state.awardingRuleSetsSteps.sortingRules;
export const exceptionRulesStepStatusSelector = (state) => state.awardingRuleSetsSteps.exceptionRules;
export default awardingRuleSetsStepsSlice;
