import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import authService from '../../services/auth';

/**
 * @param {{
*  children: any;
*  forceRequireLogin?: boolean;
* }} param0
*/
const RequireAuth = ({ children, forceRequireLogin }) => {
    const { target } = useParams();

    return (
        authService.isLoggedIn() && !forceRequireLogin
            ? <Navigate to={`/${target}` || '/'} />
            : children
    );
};

export default RequireAuth;
