/* eslint-disable no-unused-vars */
const informationStatusOnOpen = (awardingRuleSet) => (awardingRuleSet.name ? 'finish' : 'wait');

const sortingRulesStatusOnOpen = ({ dataviewId }) => (dataviewId ? 'finish' : 'wait');

const exceptionRulesStatusOnOpen = (awardingRuleSet) => 'wait';

const getStepsCompletion = (awardingRuleSet) => ({
    informationStatus: informationStatusOnOpen(awardingRuleSet),
    parametersStatus: sortingRulesStatusOnOpen(awardingRuleSet),
    exceptionRulesStatus: exceptionRulesStatusOnOpen(awardingRuleSet),
});

export const recomputeInformationStatus = (errors, name) => {
    if (errors.name) {
        return 'error';
    }
    return name ? 'finish' : 'wait';
};

/**
 * @param {Partial<{ dataviewId: string, sortingRulesDataviewFields: * }>} errors
 * @param {number} selectedFieldsCount
 */
export const recomputeSortingRulesStatus = (errors, dataviewId, selectedFieldsCount) => {
    if (errors.dataviewId || errors.sortingRulesDataviewFields) {
        return 'error';
    }

    return dataviewId && selectedFieldsCount ? 'finish' : 'wait';
};

export const recomputeExceptionRulesStatus = () => 'wait';

export default getStepsCompletion;
