import IdleTimer from 'react-idle-timer';
import useLogoutPrompt from '../../hooks/useLogoutPrompt';
import './LogoutOnInactivity.scss';

const defaultTimeout = 1000 * 60 * 10;
const timeBeforeIdle = Number(process.env.REACT_APP_TIME_BEFORE_IDLE) || defaultTimeout;

/** @param {{ onLogOut: () => void }} param0 */
const LogoutOnInactivity = ({ onLogOut }) => {
    const onPromptLogout = useLogoutPrompt(onLogOut);
    return (
        <IdleTimer
            timeout={timeBeforeIdle}
            onIdle={onPromptLogout}
        />
    );
};

export default LogoutOnInactivity;
