import React from 'react';
import './TabsStyled.scss';

const TabsStyled = ({ children }) => (
    <div className="core--tabs-styled">
        {children}
    </div>
);

export default TabsStyled;
