import { GetHrSystemRedirectUrl } from '../../services/ssoPathBuilder';

/** @type {CommonClient2.IPublicConfig} */
const emptyConfig = {
    externalAuthUrl: '',
    externalClientId: '',
    hrSystemIntegrationType: -1,
};

/**
 * @param {'admin'|'manager'|'employee'} role
 * @param {CommonClient2.IPublicConfig=} configs
 */
const useRedirectUrl = (role, configs) => {
    configs = configs || emptyConfig;
    const employeeRedirectUrl = GetHrSystemRedirectUrl(configs, role);
    return employeeRedirectUrl;
};

export default useRedirectUrl;
