import { applyUkgUserTimeZoneTransformation, removeUkgTimeZoneTransformationToProps } from '@commonClient/services/ukgDateTime';
import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';

const BASE_PATH = 'api/v1/manager/Utilities';
const appendToApiPath = appendPath(BASE_PATH);

export const UTILITIES_TAG = 'utilities';
export const UTILITIES_TAGS = [UTILITIES_TAG];

const listOfDateParamsToConvertForUser = ['startBiddingTime', 'endBiddingTime', 'submissionPeriodStartDate'];
export const removeUserTimeZoneConversionMapping = removeUkgTimeZoneTransformationToProps(listOfDateParamsToConvertForUser);

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<any, any, any, Date>}
 */
const getSubmissionEndDate = (builder) => builder.query({
    query: (body) => (
        { url: appendToApiPath('GetsubmissionEndDateTime'), method: 'POST', data: removeUserTimeZoneConversionMapping(body) }),
    transformResponse: (bid) => applyUkgUserTimeZoneTransformation(bid),
    async onQueryStarted(id, api) { await showSpinner(api); },
    providesTags: UTILITIES_TAGS,
});

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<string, any, any, string>}
 */
const generateCsvfromJsonReport = (builder) => builder.query({
    query: (jsonReport) => ({ url: appendToApiPath('JsonReportToCsv'), method: 'POST', data: { jsonReport } }),
    async onQueryStarted(id, api) { await showSpinner(api); },
});

export const endpointsManagerUtilities = (builder) => ({
    getSubmissionEndDate: getSubmissionEndDate(builder),
    generateCsvfromJsonReport: generateCsvfromJsonReport(builder),
});
