import showSpinner from '@services/addSpinnerOnQueryStarted';

export const ACCRUAL_PROFILES_TAGS = ['AccrualProfiles'];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IAccrualProfileList>}
 */
const getAll = (builder) =>
    builder.query({
        query: () => ({ url: 'api/v1/admin/utilities/accrualProfiles', method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: ACCRUAL_PROFILES_TAGS,
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions')
 *      .EndpointBuilder<Dto.IGetEmployeeMissingPoliciesQuery, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query')
 *      .QueryDefinition<Dto.IGetEmployeeMissingPoliciesQuery, any, any, Dto.IEmployeeMissingPolicies[]>}
 */
const getEmployeeMissingPolicies = (builder) =>
    builder.query({
        query: (data) => ({ url: 'api/v1/manager/utilities/EmployeeMissingTimeOffPolicies', method: 'POST', data }),
        async onQueryStarted(id, api) { await showSpinner(api); },
    });

export const endpointsAccrualProfiles = (builder) => ({
    getAccrualProfiles: getAll(builder),
    getEmployeeMissingPolicies: getEmployeeMissingPolicies(builder),
});
