import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './CircularProgressWithLabel.scss';

export default function CircularProgressWithLabel(props) {
  return (
    <Box className="circular-progress-with-label--root">
      <CircularProgress variant="determinate" {...props} />
      <Box
        className="circular-progress-with-label--label-container"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          className="circular-progress-with-label--label"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
