import showSpinner from '@services/addSpinnerOnQueryStarted';

const BASE_PATH = 'api/v1/manager/Utilities/SymbolicPeriods';
export const SYMBOLIC_PERIOD_TAG = 'SymbolicPeriod';
export const SYMBOLIC_PERIOD_TAGS = [SYMBOLIC_PERIOD_TAG];

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.ISymbolicPeriod[]>}
 */
const getSymbolicPeriod = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [SYMBOLIC_PERIOD_TAG],
    });

export const endpointsSymbolicPeriods = (builder) => ({
    getSymbolicPeriod: getSymbolicPeriod(builder),
});
