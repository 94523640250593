import showSpinner from '@services/addSpinnerOnQueryStarted';
import appendPath from '@services/path';
import mapShiftsAndPaycode from './scheduleHelper';

export const SCHEDULE_TAG = 'Schedule';
export const SCHEDULE_TAGS = [SCHEDULE_TAG];
const BASE_PATH = 'api/v1/employee/Utilities';
const appendToApiPath = appendPath(BASE_PATH);
const getUrl = (start, end, profileId, employeeId) =>
    appendToApiPath(`employeeSchedule?startDate=${start}&endDate=${end}&bidProfileId=${profileId}&employeeId=${employeeId}`);

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<Dto.IScheduleFilter, any, any, VBD.IScheduleItemWithDate[]>}
 */
const getSchedule = (builder) =>
    builder.query({
        query: ({
            startDate, endDate, bidProfileId, employeeId,
        }) =>
            ({ url: getUrl(startDate, endDate, bidProfileId, employeeId), method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        transformResponse: mapShiftsAndPaycode,
        providesTags: [SCHEDULE_TAG],
    });

export const endpointsSchedule = (builder) => ({
    getSchedule: getSchedule(builder),
});
