import React, { useEffect, useState } from 'react';
import {
    Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { CircularProgressWithLabel } from '../';
import './SplitButton.scss';

/**
 * @param {{
 *  options: {label: string, value: number, disabled: boolean, isDisabledAndShowProgress?: boolean}[];
 *  color: (import('@material-ui/core').PropTypes.Color);
 *  handleClick: Function;
 *  disabled?: boolean;
 *  className?: string;
 *  defaultValue?: {label: string, value: number, disabled: boolean, isDisabledAndShowProgress?: boolean};
 *  showChevron?: boolean;
 *  progressLabel?: string;
 *  progressValue?: number;
 * }} props
 */
const SplitButton = ({
    options,
    color,
    className,
    handleClick = () => { },
    disabled = false,
    defaultValue = options[0],
    showChevron = true,
    progressLabel,
    progressValue = undefined,
}) => {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultValue);

    const handleOptionClick = (option) => {
        showChevron
            ? setSelectedOption(option)
            : handleClick(option);
        setOpen(false);
    };

    useEffect(() => {
        if (showChevron) {
            setSelectedOption(options?.find((x) => x.value === selectedOption.value) || defaultValue);
        }
    }, [JSON.stringify(options)]);

    let buttonGroupOnClick = showChevron
        ? handleClick
        : () => setOpen(true);

    return (
        <>
            <ButtonGroup
                className={`split-button-container ${className || ''}`}
                variant="contained"
                color={color}
                ref={anchorRef}
                size="large"
                aria-label="outlined primary button group"
                disabled={disabled}
                style={{ boxShadow: 'none' }}
            >
                <Button
                    onClick={() => buttonGroupOnClick(selectedOption)}
                    disabled={selectedOption.disabled}
                    className="split-button-stick-together-label"
                >
                    <span>{selectedOption.isDisabledAndShowProgress ? `${progressLabel}` : `${selectedOption.label}`}</span>
                    {selectedOption.isDisabledAndShowProgress && progressValue !== undefined && (
                        <div className="split-button-progress">
                            <CircularProgressWithLabel
                                size={30}
                                value={progressValue}
                            />
                        </div>
                    )}
                </Button>
                {showChevron &&
                    (<Button
                        onClick={() => setOpen(true)}
                        className="split-button-stick-together-arrow"
                        disabled={!!options.find(x => x.isDisabledAndShowProgress)}
                    >
                        <FontAwesomeIcon icon={faChevronDown} />
                    </Button>)
                }
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
                className="split-button-popper"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'right bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <MenuList>
                                    {options.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            selected={option.value === selectedOption.value}
                                            onClick={() => handleOptionClick(option)}
                                            disabled={option.disabled || option.disabled}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default SplitButton;
