import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enCA, fr } from 'date-fns/locale';
import { updateLocale } from '../services/ukgDateTime';

import commonFr from './frCommon.json';
import commonEn from './enCommon.json';
import tableFr from './frTable.json';
import tableEn from './enTable.json';
import bidFr from './frBid.json';
import bidEn from './enBid.json';
import statusFr from './frStatus.json';
import statusEn from './enStatus.json';
import menuFr from './frMenu.json';
import menuEn from './enMenu.json';

let locale = enCA;
export const getLocale = () => locale;

export const setLocale = (isEnglish) => {
    locale = isEnglish ? enCA : fr;
    const selectedLocale = isEnglish ? 'enCA' : 'fr';
    sessionStorage.setItem('selectedLocale', selectedLocale);
    updateLocale(selectedLocale);
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        interpolation: {
            escapeValue: false,
        }, // React already does escaping
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'language',
        },
        resources: {
            en: {
                common: commonEn,
                table: tableEn,
                bid: bidEn,
                status: statusEn,
                menu: menuEn,
            },
            fr: {
                common: commonFr,
                table: tableFr,
                bid: bidFr,
                status: statusFr,
                menu: menuFr,
            },
        },
        fallbackLng: 'en',
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'i', 'b', 'p'],
        },
    });

export default i18n;
