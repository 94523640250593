import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Card, CardContent,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AppPicker.scss';
import { faUserHardHat, faUserCog, faUserTie } from '@fortawesome/pro-light-svg-icons';

/**
 * @param {{ label: string, action: any, icon: any }} param0
 */
const PickerItem = ({ label, action, icon }) => (
    <div>
        <Card
            style={{ padding: '24px', paddingBottom: '0', cursor: 'pointer' }}
            onClick={action}
        >
            <FontAwesomeIcon
                icon={icon}
                size="8x"
                style={{ color: 'cornFlowerBlue' }}
            />
            <CardContent>
                {label}
            </CardContent>
        </Card>
    </div>
);

const AppPicker = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const jwtUserInfo = JSON.parse(sessionStorage.getItem('jwtUserInfo') || '{}');
    /** @type {string[]} */
    const licenses = jwtUserInfo?.Licenses || [];
    const [firstLicense] = licenses;

    if (licenses.length === 1) {
        navigate(firstLicense || 'employee');
        return <></>;
    }

    const noLicense = !licenses.length;
    const isEmployeeVisible = licenses.includes('employee') || noLicense;
    const isManagerVisible = licenses.includes('manager') || noLicense;
    const isAdminVisible = licenses.includes('admin') || noLicense;

    return (
        <div className="app-picker">
            {
                isEmployeeVisible && (
                    <PickerItem
                        label={t('sections.employee')}
                        action={() => navigate('employee')}
                        icon={faUserHardHat}
                    />
                )
            }
            {
                isManagerVisible && (
                    <PickerItem
                        label={t('sections.manager')}
                        action={() => navigate('manager')}
                        icon={faUserTie}
                    />
                )
            }
            {
                isAdminVisible && (
                    <PickerItem
                        label={t('sections.admin')}
                        action={() => navigate('admin')}
                        icon={faUserCog}
                    />
                )
            }
        </div>
    );
};

export default AppPicker;
