import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    arrow: {
        color: '#fff',
    },
    tooltip: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.12)',
        fontSize: 12,
        maxWidth: 400,
        whiteSpace: 'pre-wrap',
    },
}));

function LightTooltip(props) {
    const classes = useStyles();

    return (
        <Tooltip
            classes={classes}
            {...props}
        />
    );
}

/**
 * @param {{children: *} & import('@material-ui/core').TooltipProps} param0
 */
const CustomizedTooltip = ({ children, ...rest }) => (
    <LightTooltip {...rest}>
        {children}
    </LightTooltip>
);

export default CustomizedTooltip;
