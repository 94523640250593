import { Pagination } from 'rsuite';
import StatusMultiSelect from '../StatusMultiSelect';
import TableHeader from './TableHeader';
import TableContent from './TableContent';
import useSanitizeColumns from './useSanitizeColumns';
import './Table.scss';
import {
    DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGINATION_SIZE, DEFAULT_PAGINATION_PAGE, DEFAULT_LAYOUT,
} from './usePagination';

/**
 * @param {CommonClient2.PaginatedTableComponentProps & import('rsuite-table').TableProps} param0
 * @example <Table
 *               data={bidSetups}
 *               columns={tableColumns}
 *               id="admin-settings-table"
 *               rowClassName="table-row"
 *               autoHeight
 *           />
 */
const PaginatedTable = ({
    columns,
    createLabel,
    customActionIcon,
    customActionIconFixed,
    customColumnRenderers,
    data,
    dateColumnKeys,
    dateTimeColumnKeys,
    defaultSortColumnKey = '',
    defaultSortType,
    downloadCsvProps,
    dropdownPlaceholder = '',
    fillAvailableHeight = false,
    hiddenColumns = [],
    isRowsSelectable = false,
    isActionDisabled = false,
    layout = DEFAULT_LAYOUT,
    nameColumnChipKey,
    nameColumnKey,
    total = 0,
    pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    pageSize = DEFAULT_PAGINATION_SIZE,
    page = DEFAULT_PAGINATION_PAGE,
    onChangePage,
    onChangePageSize,
    onAddRound,
    onChange,
    onCreate,
    onDelete,
    onDuplicate,
    onEdit,
    onManage,
    onNameCellClick,
    onStatusChange,
    onSearch,
    rowFilterProps,
    statusFilterField,
    selectedItems = [],
    statuses,
    treeChildrenKey,
    useSimpleDropdown = false,
    ...others
}) => {
    data = data || [];
    const sanitizedColumns = useSanitizeColumns(data, hiddenColumns, columns, dateColumnKeys, dateTimeColumnKeys);

    return (
        <div className={`core--table ${isActionDisabled ? 'loading' : ''}`}>
            <TableHeader
                onSearch={onSearch}
                rowFilterProps={rowFilterProps}
                downloadCsvProps={downloadCsvProps}
                onCreate={onCreate}
                createLabel={createLabel}
                isActionDisabled={isActionDisabled}
            >
                {
                    !statusFilterField || !statuses || !statuses.length || !onStatusChange
                        ? <></>
                        : (
                            <StatusMultiSelect
                                allStatuses={statuses}
                                onStatusChange={onStatusChange}
                                useSimpleDropdown={useSimpleDropdown}
                                placeholder={dropdownPlaceholder}
                            />
                        )
                }
            </TableHeader>
            <TableContent
                columns={sanitizedColumns}
                customActionIconFixed={customActionIconFixed}
                customColumnRenderers={customColumnRenderers}
                data={data}
                defaultSortColumnKey={defaultSortColumnKey}
                defaultSortType={defaultSortType}
                fillAvailableHeight={fillAvailableHeight}
                nameColumnChipKey={nameColumnChipKey}
                nameColumnKey={nameColumnKey}
                isRowsSelectable={isRowsSelectable}
                onAddRound={onAddRound}
                onChange={onChange}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
                onEdit={onEdit}
                onManage={onManage}
                onNameCellClick={onNameCellClick}
                selectedItems={selectedItems}
                statusFilterField={statusFilterField}
                treeChildrenKey={treeChildrenKey}
                {...others}
            />
            <div>
                <Pagination
                    className="bids-pagination"
                    prev
                    next
                    first
                    last
                    ellipsis
                    boundaryLinks
                    maxButtons={5}
                    size="md"
                    layout={layout}
                    total={total}
                    limitOptions={pageSizeOptions}
                    limit={pageSize}
                    activePage={page}
                    onChangePage={onChangePage}
                    onChangeLimit={onChangePageSize}
                />
            </div>
        </div>
    );
};

export default PaginatedTable;
