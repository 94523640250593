import showSpinner from '@services/addSpinnerOnQueryStarted';

export const GLOBAL_SETUP_TAG = 'GlobalSetup';
export const GLOBAL_SETUP_TAGS = [GLOBAL_SETUP_TAG];
const BASE_PATH = 'api/v1/admin/GlobalSetup';

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').QueryDefinition<void, any, any, Dto.IGlobalSetup>}
 */
const getGlobalSetup = (builder) =>
    builder.query({
        query: () => ({ url: BASE_PATH, method: 'GET' }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        providesTags: [GLOBAL_SETUP_TAG],
    });

/**
 * @param {import('@reduxjs/toolkit/dist/query/endpointDefinitions').EndpointBuilder<any, string, string>} builder
 * @returns {import('@reduxjs/toolkit/dist/query').MutationDefinition<Dto.IGlobalSetup, any, any, Dto.IGlobalSetup>}
 */
const updateGlobalSetup = (builder) =>
    builder.mutation({
        query: (setup) => ({ url: BASE_PATH, method: 'PUT', data: setup }),
        async onQueryStarted(id, api) { await showSpinner(api); },
        invalidatesTags: [GLOBAL_SETUP_TAG],
    });

export const endpointsGlobalSetup = (builder) => ({
    getGlobalSetup: getGlobalSetup(builder),
    updateGlobalSetup: updateGlobalSetup(builder),
});
