import { getAsTime, removeBrowserTimezoneConversion } from '@commonClient/services/ukgDateTime';
import { QuotaType, EmployeeScheduleCalendarEventTypes } from '@enums/index';

const formatTime = (date) => getAsTime(date);
const formatRange = (start, end) => `${formatTime(start)} - ${formatTime(end)}`;
/**
 * @param {string} start
 * @param {string} end
 */
const formatShiftLabel = (start, end) => formatRange(start, end);
const formatSecondsToHourDisplay = (durationInTime) => {
    const hours = Math.floor(durationInTime / 3600).toString();
    const minutes = Math.floor((durationInTime % 3600) / 60).toString().padStart(2, '0');
    return `[${hours}:${minutes}]`;
};
const formatPayCodeLabel = (label, durationInTime) => `${label || ''} ${formatSecondsToHourDisplay(durationInTime)}`;

/**
 * @param {Dto.ISchedule} param0
 */
const mapShiftsAndPaycode = ({
    shifts, ukgPayCodes, awardedPayCodes, quotas, holidays,
}) => {
    const mappedHolidays = holidays.map(({
        id, startDateTime, displayName,
    }) => ({
        id,
        start: new Date(startDateTime),
        end: new Date(startDateTime),
        rawStartString: startDateTime,
        rawEndString: startDateTime,
        type: EmployeeScheduleCalendarEventTypes.HOLIDAY,
        title: displayName,
        orgJobRefQualifier: undefined,
        segments: undefined,
    }));
    const mappedShifts = shifts.map(({
        id, startDateTime, endDateTime, label, segments,
    }) => ({
        id,
        start: new Date(startDateTime),
        end: new Date(startDateTime),
        rawStartString: startDateTime,
        rawEndString: startDateTime,
        type: EmployeeScheduleCalendarEventTypes.SHIFT,
        title: formatShiftLabel(startDateTime, endDateTime),
        orgJobRefQualifier: segments?.[0]?.orgJobRef?.qualifier || label,
        segments: segments !== null ? segments.map((segment) => ({
            id: segment.id,
            label: segment.label,
            title: (segment.startTime && segment.endTime)
                ? formatShiftLabel(segment.startTime, segment.endTime)
                : segment.type,
            type: segment.type,
            typeId: segment.typeId,
            startTime: segment.startTime,
            endTime: segment.endTime,
            transferStrings: segment.transferString?.split(';') ?? [],
        })) : null,
    }));
    const mappedUkgPaycodes = ukgPayCodes.map(({
        startDate, payCodeRef: { id, qualifier }, durationInTime,
    }) => ({
        id: id?.toString(),
        start: new Date(startDate),
        end: new Date(startDate),
        rawStartString: startDate,
        rawEndString: startDate,
        type: EmployeeScheduleCalendarEventTypes.UKG_PAYCODE,
        title: formatPayCodeLabel(qualifier, durationInTime),
        orgJobRefQualifier: undefined,
        segments: undefined,
    }));
    const mappedAwardedPayCodes = awardedPayCodes.map(({
        startDate, payCodeRef: { id, qualifier }, durationInTime,
    }) => ({
        id: id?.toString(),
        start: new Date(startDate),
        end: new Date(startDate),
        rawStartString: startDate,
        rawEndString: startDate,
        type: EmployeeScheduleCalendarEventTypes.AWARDED_PAYCODE,
        title: formatPayCodeLabel(qualifier, durationInTime),
        orgJobRefQualifier: undefined,
        segments: undefined,
    }));
    const mappedDailyQuotas = quotas
        .filter(({ quotaType }) => quotaType === QuotaType.Daily)
        .map(({
            startDate, endDate, amount,
        }) => ({
            id: `${startDate.toString()}-${endDate.toString()}`,
            start: removeBrowserTimezoneConversion(startDate),
            end: removeBrowserTimezoneConversion(endDate),
            rawStartString: startDate,
            rawEndString: endDate,
            type: EmployeeScheduleCalendarEventTypes.DAILY_QUOTA,
            title: amount?.toString(),
            orgJobRefQualifier: undefined,
            segments: undefined,
        }));
    const mappedWeeklyQuotas = quotas
        .filter(({ quotaType }) => quotaType === QuotaType.Weekly)
        .map(({
            startDate, endDate, amount,
        }) => ({
            id: `${startDate.toString()}-${endDate.toString()}`,
            start: removeBrowserTimezoneConversion(startDate),
            end: removeBrowserTimezoneConversion(endDate),
            rawStartString: startDate,
            rawEndString: endDate,
            type: EmployeeScheduleCalendarEventTypes.WEEKLY_QUOTA,
            title: amount?.toString(),
            orgJobRefQualifier: undefined,
            segments: undefined,
        }));
    /** @type {VBD.IScheduleItemWithDate[]} */
    return [
        ...mappedDailyQuotas,
        ...mappedHolidays,
        ...mappedShifts,
        ...mappedUkgPaycodes,
        ...mappedAwardedPayCodes,
        ...mappedWeeklyQuotas];
};

export default mapShiftsAndPaycode;
