const ROUND_NEAREST = 'roundNearest';
const ROUND_UP = 'roundUp';
const ROUND_DOWN = 'roundDown';

export const RoundingOption = Object.freeze({
    [ROUND_NEAREST]: 0,
    [ROUND_UP]: 1,
    [ROUND_DOWN]: 2,
});

export const RoundingOptionNames = Object.freeze({
    0: ROUND_NEAREST,
    1: ROUND_UP,
    2: ROUND_DOWN,
});
