import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { getAsLongDate, getAsLongDateTime } from '../../services/ukgDateTime';
import './bootstrap-no-reboot.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './ObsoleteRangePicker.scss';

const noop = () => {};

/**
 * @param {(moment.Moment|undefined)[]} param0
 * @param {(moment.Moment|undefined)[]} param1
 */
const isRangeEqual = ([r1Start, r1End], [r2Start, r2End]) => {
    if (!r1Start?.isValid() || !r2Start?.isValid() || !r1End?.isValid() || !r2End?.isValid()) {
        return false;
    }
    const comparisonFormat = 'YYYY-MM-DD';
    return r1Start.format(comparisonFormat) === r2Start.format(comparisonFormat)
           && r1End.format(comparisonFormat) === r2End.format(comparisonFormat);
};
const DEFAULT_CUSTOM_RANGE_LABEL = 'Custom Range';

/**
 * @param {{ disabled?: boolean; value: string }} param0
 * @returns
 */
const PickerButton = ({ disabled, value }) => (
    <Button
        disabled={disabled}
        className={`${disabled ? 'disabled' : ''} picker-button`}
    >
        <div>
            <FontAwesomeIcon
                className="calendar-icon date-picker-icon"
                icon={faCalendarAlt}
            />
            <span>
                {value}
            </span>
        </div>
        <div>
            <FontAwesomeIcon
                className="chevron-icon date-picker-icon"
                icon={faChevronDown}
            />
        </div>
    </Button>
);

/**
 * @param {CommonClient2.IObsoleteRangePicker} param0
 * */
const ObsoleteDateRangePicker = ({
    minDate,
    maxDate,
    maxSpan,
    showDropdowns,
    minYear,
    maxYear,
    showWeekNumbers,
    timePicker,
    timePickerIncrement,
    timePicker24Hour,
    timePickerSeconds,
    ranges,
    showCustomRangeLabel,
    alwaysShowCalendars,
    opens,
    placement,
    buttonClasses,
    applyButtonClasses,
    cancelButtonClasses,
    locale,
    singleDatePicker,
    autoApply,
    linkedCalendars,
    disabledDate,
    isCustomDate,
    autoUpdateInput,
    parentEl,
    onChange,
    onBlur,
    onEnter,
    onExit,
    onCancel,
    disabled,
    value,
    hasError,
    hasForeverEndDateButton,
    foreverDate,
    foreverLabel = 'Forever',
}) => {
    const [ref, setRef] = useState(React.createRef());
    const convertedRange = ranges?.map((r) => ({
        ...r,
        value: r.value?.map((v) => moment(v)) || [moment(r.startDate), moment(r.endDate)],
    })) || [];

    const [startValue, endValue] = value || [];
    const momentStartValue = startValue ? moment(startValue) : undefined;
    const momentEndValue = endValue ? moment(endValue) : undefined;

    const [internalValue, setInternalValue] = useState([momentStartValue, momentEndValue]);

    const defaultSelectedPeriodLabel = convertedRange.find((r) => isRangeEqual(
        // @ts-ignore
        r.value || [],
        [momentStartValue, momentEndValue],
    ))?.label || '';

    const [selectedPeriodLabel, setSelectedPeriodLabel] = useState(defaultSelectedPeriodLabel);
    const [dummyKey, setDummyKey] = useState(0);

    useEffect(() => setInternalValue([momentStartValue, momentEndValue]), [JSON.stringify(value)]);

    // We need to create a new instance when params change
    useEffect(() => setDummyKey(dummyKey + 1), [
        JSON.stringify(ranges),
        minDate,
        maxDate,
        maxSpan,
        minDate,
        maxDate,
        maxSpan,
        showDropdowns,
        minYear,
        maxYear,
        showWeekNumbers,
        timePicker,
        timePickerIncrement,
        timePicker24Hour,
        timePickerSeconds,
        showCustomRangeLabel,
        alwaysShowCalendars,
        opens,
        placement,
        buttonClasses,
        applyButtonClasses,
        cancelButtonClasses,
        singleDatePicker,
        autoApply,
        linkedCalendars,
        isCustomDate,
        autoUpdateInput,
    ]);

    const FOREVER = moment(foreverDate);
    /**
     * @param {moment.Moment | null} d
     * @returns {boolean}
    */
    const isForever = (d) => !!hasForeverEndDateButton
        && d?.clone().endOf('day').valueOf() === FOREVER.endOf('day').valueOf();

    const [internalStartDate, internalEndDate] = internalValue || [];
    const dateFormatFunction = timePicker ? getAsLongDateTime : getAsLongDate;
    let displayValue = internalStartDate?.isValid() && internalEndDate?.isValid()
        // @ts-ignore
        ? `${dateFormatFunction(internalStartDate.toDate())} - ${isForever(internalEndDate) ? foreverLabel : dateFormatFunction(internalEndDate.toDate())}`
        : '';
    if (singleDatePicker) {
        displayValue = internalStartDate?.isValid() ? `${dateFormatFunction(internalStartDate.toDate())}` : '';
    }

    const onBlurOrNoop = onBlur || noop;
    const onCancelOrNoop = onCancel || noop;

    const onApply = (event, picker) => {
        /** @type {{ startDate: moment.Moment, endDate: moment.Moment, chosenLabel: string}}  */
        const { startDate: updatedStartDateMoment, endDate: updatedEndDateMoment, chosenLabel } = picker;
        // @ts-ignore
        const { customRangeLabel: currentCustomRangeLabel = DEFAULT_CUSTOM_RANGE_LABEL } = locale || {};
        const periodLabel = chosenLabel === currentCustomRangeLabel ? '' : chosenLabel;
        setSelectedPeriodLabel(periodLabel);
        setInternalValue([updatedStartDateMoment, updatedEndDateMoment]);

        if (!onChange) {
            onBlurOrNoop();
            return;
        }

        const updatedValue = !updatedStartDateMoment.isValid() || !updatedEndDateMoment.isValid()
            ? undefined
            : [updatedStartDateMoment?.toDate(), updatedEndDateMoment?.toDate()];
        onChange(updatedValue, event);
        onBlurOrNoop();
    };

    const rangesDictionary = convertedRange?.reduce((acc, val) => ({ ...acc, [val.label]: val.value }), {});
    const blurAndCancel = () => {
        onCancelOrNoop();
        onBlurOrNoop();
    };
    applyButtonClasses = applyButtonClasses || undefined;
    cancelButtonClasses = cancelButtonClasses || undefined;
    buttonClasses = buttonClasses || undefined;
    const isInvalidDate = !disabledDate ? undefined : (/** @type {moment.Moment} */ date) => disabledDate(date.toDate());
    const alwaysShowCalendarsDefault = typeof alwaysShowCalendars === 'undefined' ? true : alwaysShowCalendars;

    const getDatePicker = () => ref?.current.$picker.data('daterangepicker');

    const handleForeverEndDateButtonClick = () => {
        const datepicker = getDatePicker();
        if (datepicker) {
            // Set the end date to FOREVER
            datepicker.setEndDate(FOREVER);

            datepicker.updateView();

            // Set the inner label to FOREVER
            const { startDate, locale: datepickerLocale } = datepicker;
            datepicker.container
                .find('.drp-selected')
                .html(startDate.format(datepickerLocale.format) + datepickerLocale.separator + foreverLabel);
        }
    };

    const onShow = () => {
        getDatePicker()?.container
            .find('.drp-selected')
            .html(displayValue);

        if (onEnter) {
            onEnter();
        }
        if (hasForeverEndDateButton) {
            // Add forever label
            const datepicker = getDatePicker();
            if (datepicker) {
                const { startDate, endDate, locale: datepickerLocale } = datepicker;
                if (isForever(endDate)) {
                    datepicker.container
                        .find('.drp-selected')
                        .html(startDate.format(datepickerLocale.format) + datepickerLocale.separator + foreverLabel);
                }
            }

            // Add forever button
            const calendarButtons = document.createElement('div');
            calendarButtons.className = 'CUSTOM__calendarButtons';

            const container = document
                .getElementsByClassName('daterangepicker ltr show-calendar opensright drop-up')[0]
                .getElementsByClassName('drp-buttons')[0];
            const customButtons = container.getElementsByClassName('CUSTOM__calendarButtons');
            if (customButtons.length) {
                return;
            }
            container.insertBefore(calendarButtons, container.firstChild);
            const root = createRoot(calendarButtons);
            root.render((
                <>
                    <span style={{ marginRight: 10, color: '#1a518a', paddingTop: 9 }}>Maximum end date:</span>
                    <Button
                        variant="contained"
                        className="forever-btn"
                        onClick={handleForeverEndDateButtonClick}
                    >
                        FOREVER
                    </Button>
                </>));
        }
    };

    return (
        <div
            key={dummyKey}
            className={`core--obsolete-daterange-picker ${hasError ? 'has-error' : ''} ${disabled ? 'disabled' : ''}`}
        >
            {
                selectedPeriodLabel ? (
                    <div
                        className="core--placeholder"
                    >
                        <Typography className="core--placeholder-label">{selectedPeriodLabel}</Typography>
                    </div>
                ) : <></>
            }
            {
                disabled ? (
                    <PickerButton
                        disabled={disabled}
                        value={displayValue}
                    />
                ) : (
                    <DateRangePicker
                        initialSettings={{
                            startDate: internalStartDate,
                            endDate: internalEndDate,
                            minDate,
                            maxDate,
                            maxSpan,
                            showDropdowns,
                            minYear,
                            maxYear,
                            showWeekNumbers,
                            timePicker,
                            timePickerIncrement,
                            timePicker24Hour,
                            timePickerSeconds,
                            ranges: Object.keys(rangesDictionary).length ? rangesDictionary : undefined,
                            showCustomRangeLabel,
                            alwaysShowCalendars: alwaysShowCalendarsDefault,
                            opens,
                            drops: placement,
                            buttonClasses,
                            applyButtonClasses,
                            cancelButtonClasses,
                            locale,
                            singleDatePicker,
                            autoApply,
                            linkedCalendars,
                            isInvalidDate,
                            isCustomDate,
                            autoUpdateInput,
                            parentEl,
                        }}
                        onApply={onApply}
                        onCancel={blurAndCancel}
                        onHide={onExit}
                        onShow={onShow}
                        ref={ref}
                    >
                        <div>
                            <PickerButton value={displayValue} />
                        </div>
                    </DateRangePicker>
                )
            }
        </div>
    );
};
export default ObsoleteDateRangePicker;
