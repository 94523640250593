import { Box } from '@material-ui/core';
import { toastr } from 'react-redux-toastr';
import { useTranslation } from 'react-i18next';
import useCountdown from '../useCountdown/useCountdown';

const defaultTimeout = 1000 * 60 * 10;
const logoutNotificationTimer = Number(process.env.REACT_APP_LOGOUT_NOTIFICATION_TIMER) || defaultTimeout;

/** @param {{ start: number, logoutTimer: number }} param0 */
const LogoutPromptContent = ({ start, logoutTimer }) => {
    const { t } = useTranslation('common', { keyPrefix: 'inactivity' });
    const expiration = useCountdown(start, logoutTimer);
    const expirationWarningText = `${t('sessionExpiresIn', { expiration })}.`;
    return <Box className="inactivity-warning">{expirationWarningText}</Box>;
};

/** @param {() => void} onLogOut */
const useLogoutPrompt = (onLogOut) => {
    const { t } = useTranslation('common', { keyPrefix: 'inactivity' });

    const onPromptLogout = () => {
        const logoutTimeout = setTimeout(() => onLogOut(), logoutNotificationTimer);
        toastr.confirm(t('logoutTitle'), {
            // @ts-ignore
            id: 'inactivity-prompt',
            okText: t('stayLoggedIn'),
            onOk: () => clearTimeout(logoutTimeout),
            // @ts-ignore
            component: () => (
                <LogoutPromptContent
                    start={Date.now()}
                    logoutTimer={logoutNotificationTimer}
                />
            ),
            disableCancel: true,
            position: 'top-center',
        });
    };

    return onPromptLogout;
};

export default useLogoutPrompt;
