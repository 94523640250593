import extractColumns from './extractColumnsFromData';

/**
 * @param {CommonClient2.TableStaticColumn[]} columns : ;
 * @param {string} typeName
 * @param {string[]=} columnKeys
 */
const useSetDataValueType = (columns, typeName, columnKeys) => {
    if (!columnKeys?.length) {
        return;
    }

    columnKeys.forEach((ck) => {
        const dateColumn = columns?.find((c) => c.key === ck) || { dataValueType: '' };
        // @ts-ignore
        dateColumn.dataValueType = typeName;
    });
};

/**
 * - Extract columns from data if columns aren't provided
 * - Adds DATE and DATETIME type to columns when needed
 *
 * @param {any[]} data
 * @param {string[]} hiddenColumns
 * @param {CommonClient2.TableStaticColumn[]=} columns
 * @param {string[]=} dateColumnKeys
 * @param {string[]=} dateTimeColumnKeys
 */
const useSanitizeColumns = (data, hiddenColumns, columns, dateColumnKeys, dateTimeColumnKeys) => {
    columns = !columns?.length ? extractColumns(data) : columns;
    if (hiddenColumns?.length) {
        columns = columns.filter((c) => !hiddenColumns.includes(c.key));
    }
    useSetDataValueType(columns, 'DATE', dateColumnKeys);
    useSetDataValueType(columns, 'DATETIME', dateTimeColumnKeys);
    return columns;
};

export default useSanitizeColumns;
